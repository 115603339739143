import React, { useEffect } from 'react';
import { TariffProvider, tariffProviderSchema } from './TariffProvider';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSection, makeRequired, RhfForm } from '../shared/components/forms/Forms';
import { CustomSaveButton } from '../shared/components/buttons/SaveButton';
import { CustomTextField } from '../shared/components/forms/CustomTextField';
import { Grid } from '@material-ui/core';

export default function TariffProviderForm(props: {
  tariffProvider: TariffProvider,
  onSave: (tariffProvider: TariffProvider) => void;
}) {
  const {t} = useTranslation();

  const form = useForm<TariffProvider>({
    resolver: yupResolver(tariffProviderSchema()),
    defaultValues: props.tariffProvider,
    mode: 'onBlur'
  });
  const {register} = form;

  useEffect(() => {
    register('id');
    register('version');
  }, [register]);

  const required = makeRequired<TariffProvider>(tariffProviderSchema());

  return (
    <FormProvider {...form}>
      <RhfForm handleSave={props.onSave} form={form}>
        <Grid xs={12} container={true}>
          <FormSection>
            <CustomTextField
              required={required.name}
              name={'name'}
              label={t('TariffProvider.name')}
              fullWidth={true}
            />
          </FormSection>
        </Grid>
        <CustomSaveButton/>
      </RhfForm>
    </FormProvider>
  );
}
