import * as React from 'react';
import { useState } from 'react';
import { Vehicle } from './Vehicle';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, createStyles, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { Magnitude, UnitCodes } from '../units/Unit';
import { applyMagnitude, translateMagnitude, undoMagnitude } from '../units/magnitudeUtils';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { log } from '../logConfig';
import Button from '@material-ui/core/Button';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { updateVehicle } from './VehiclesApi';

export const useStyle = makeStyles((theme: Theme) => createStyles({}));

export function VehiclesList(props: {
  vehicles: Vehicle[],
  onClick?: (vehicle: Vehicle) => void,
  onDelete?: (vehicleId: number) => void,
}) {
  const {t} = useTranslation();
  const classes = useStyle();
  //
  // function deleteIcon(id: number) {
  //   return <IconButton onClick={(event) => {
  //     event.preventDefault();
  //     if (!window.confirm(t('actions.reallyDelete', {name: t('Vehicle.title') + ' ' + id}))) {
  //       return;
  //     }
  //     props.onDelete && props.onDelete(id);
  //   }}>
  //     <DeleteIcon/>
  //   </IconButton>;
  // }

  return (<>
    {props.vehicles.map(vehicle => (
        <Grid item={true} key={vehicle.id} xs={12} md={6} lg={4} xl={3}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="vehicle-content">
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{marginRight: 16}}>
                  <Typography variant={'h6'} paragraph={true}>{vehicle.brand + ' ' + vehicle.model}</Typography>
                </div>
                <div>
                  <Typography variant={'body1'} align={'center'} paragraph={true}>{vehicle.vin}</Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <BatteryCapacityField vehicle={vehicle}/>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )
    )}
  </>);
}

function BatteryCapacityField(props: {
  vehicle: Vehicle,
}) {
  const {t} = useTranslation();
  const axios = useAxios();
  const [capacityEditable, setCapacityEditable] = useState(false);
  const [value, setValue] = useState(getBatteryCapacity(props.vehicle));

  function handleSave() {
    updateVehicle(axios, {...props.vehicle, batteryCapacity: undoMagnitude(Magnitude.KILO, value || 0)}).then(result => {
      if (result.hasOwnProperty('errors'))
        log.error('Could not update vehicle: ' + JSON.stringify(result), null);
      else
        setCapacityEditable(false);
    });
  }

  function getBatteryCapacity(vehicle: Vehicle): number | undefined {
    if (!vehicle.batteryCapacity) {
      return undefined;
    }
    return applyMagnitude(Magnitude.KILO, vehicle.batteryCapacity);
  }

  return (
    <>
      <TextField
        type={'number'}
        disabled={!capacityEditable}
        label={t('Vehicle.batteryCapacity')}
        value={value || '-'}
        onChange={event => {
          log.info('New capacity: ' + event.target.value);
          setValue(Number.parseFloat(event.target.value));
        }}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            {translateMagnitude(Magnitude.KILO) + UnitCodes.Wh}
            <IconButton
              size={'small'}
              aria-label="edit"
              onClick={() => {
                setCapacityEditable(curr => !curr);
              }}
              edge="end"
              style={{marginLeft: 16}}
            >
              <EditIcon/>
            </IconButton>
          </InputAdornment>
        }}/>
      {capacityEditable &&
      <Button size={'small'} variant={'contained'} disableElevation={true} onClick={handleSave}>{t('actions.save')}</Button>
      }
    </>
  );
}
