import { EntityRef } from '../shared/technical/rest/pageable';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { ValidityInfo, validityInfoSchema } from '../shared/technical/validity/ValidityInfo';
import { MeterCalculation, meterCalculationSchema } from './MeterCalculation';

export interface Meter extends EntityRef {
  version: number;
  meteringPoint?: EntityRef;
  serialNumber?: string;
  meterReadingType: MeterReadingType;
  dataSource: MeterDataSource,
  meterCalculation?: MeterCalculation;
  validityInfo?: ValidityInfo;
}

export interface MeterEntry extends EntityRef {
  version: number;
  serialNumber?: string;
  meterReadingType: MeterReadingType;
  dataSource: MeterDataSource,
  meterCalculation?: MeterCalculation;
}

export function meterSchema(): yup.SchemaOf<Meter> {
  return yup.object({
    id: yup.string(),
    version: yup.number(),
    serialNumber: yup.string(),
    meterCalculation: meterCalculationSchema().notRequired().default(undefined),
    meterReadingType: yup.mixed(),
    dataSource: yup.mixed(),
    validityInfo: validityInfoSchema()
  }).defined() as unknown as yup.SchemaOf<Meter>;
}

export enum MeterReadingType {
  COUNTER_VALUES = 'COUNTER_VALUES',
  DELTA_VALUES = 'DELTA_VALUES',
  CONCRETE_VALUES = 'CONCRETE_VALUES',
  CALCULATED_VALUES = 'CALCULATED_VALUES'
}

export enum MeterDataSource {
  MANUAL = 'MANUAL',
  MQTT = 'MQTT',
  RENAULT_ADAPTER = 'RENAULT_ADAPTER'
}

export enum DeltaValueOperator {
  PLUS = 'PLUS',
  MINUS = 'MINUS',
  SPECIAL = 'SPECIAL'
}

export const METER_READING_TYPES: MeterReadingType[] = Object.keys(MeterReadingType).map(k => MeterReadingType[k]);
export const METER_DATA_SOURCES: MeterDataSource[] = Object.keys(MeterDataSource).map(k => MeterDataSource[k]);
export const DELTA_VALUES_OPERATORS: DeltaValueOperator[] = Object.keys(DeltaValueOperator).map(k => DeltaValueOperator[k]);

export function emptyMeter() {
  return {
    meterReadingType: MeterReadingType.COUNTER_VALUES,
    dataSource: MeterDataSource.MANUAL,
    validityInfo: {validFrom: DateTime.local().toISO()}
  } as Meter;
}
