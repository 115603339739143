import * as React from 'react';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { UserManager, UserManagerSettings } from 'oidc-client-ts';
import { getOidcConfiguration } from './securityConfig';
import { log } from '../../../logConfig';

export const useUserManager = () => {
  return useContext<UserManagerContextProps>(UserManagerContext);
};

type UserManagerContextProps = {
  userManager?: UserManager;
  signIn?: (args?: unknown) => Promise<void>;
  signOut?: () => Promise<void>;
}

const UserManagerContext = React.createContext<UserManagerContextProps>({});

export function UserManagerProvider(props: {
  children: ReactNode
}) {
  const [oidcConfiguration, setOidcConfiguration] = useState<UserManagerSettings>();
  const [userManager, setUserManager] = useState<UserManager>();

  log.debug('Inside UserManagerProvider');

  useEffect(() => {
    getOidcConfiguration().then(setOidcConfiguration);
  }, []);

  useEffect(() => {
    if (!oidcConfiguration) {
      return;
    }
    log.debug('UserManagerProvider: Got oidcConfig => creating userManager');

    setUserManager(new UserManager(oidcConfiguration));
  }, [oidcConfiguration]);

  async function signOut() {
    await userManager?.signoutRedirect();
  }

  async function signIn() {
    await userManager?.signinSilent().catch(() => userManager?.signinRedirect());
  }

  if (!userManager) {
    return <></>;
  }
  log.debug('UserManagerProvider: Got UserManager');

  return (
    <UserManagerContext.Provider value={{userManager, signIn, signOut}}>
      {userManager && props.children}
    </UserManagerContext.Provider>
  );
}
