import { MeterDataSource } from './Meter';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { PagedResult } from '../shared/technical/rest/pageable';
import { FormItem, FormSection, makeRequired, RhfForm } from '../shared/components/forms/Forms';
import { MeterType } from '../meterTypes/MeterType';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import { useTranslation } from 'react-i18next';
import { Loading } from '../shared/components/Loading';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomSelect } from '../shared/components/forms/CustomSelect';
import { CustomTextField } from '../shared/components/forms/CustomTextField';
import { CustomSaveButton } from '../shared/components/buttons/SaveButton';
import { FormHelperText, Grid } from '@material-ui/core';
import { MqttForm } from './mqtt/MqttForm';
import { TariffSubscriptionForm } from '../provider/TariffSubscriptionForm';
import { ValidationError } from '../shared/technical/rest/validation';
import { MeteringPoint, meteringPointSchema } from './MeteringPoint';
import { MeterForm } from './MeterForm';

export interface MeteringPointFormProps {
  meteringPoint: MeteringPoint;
  meterTypes: PagedResult<MeterType>;
  onSave: (MeteringPoint) => Promise<ValidationError[] | undefined>;
}

export function MeteringPointForm(props: MeteringPointFormProps) {
  const {t} = useTranslation();

  const [expertMode, setExpertMode] = useState<boolean>(false);
  const [backendErrors, setBackendErrors] = useState<string>();

  const form = useForm<MeteringPoint>({
    resolver: yupResolver(meteringPointSchema()),
    defaultValues: props.meteringPoint,
    mode: 'onBlur'
  });

  const {control, setValue, register, trigger, setError} = form;

  useEffect(() => {
    register('id');
    register('unit');
    register('name');
    register('magnitude');
    register('location');
    register('activeMeter.meterReadingType');
    register('activeMeter.version');
  }, [register]);

  useEffect(() => {
    if (expertMode) {
      trigger().then();
    }
  }, [expertMode, trigger]);

  const meterDataSource: MeterDataSource | undefined = useWatch({control: control, name: 'activeMeter.dataSource'});

  const meteringPointTypeName: string | undefined = useWatch({control: control, name: 'type.name'});
  useEffect(() => {
    if (meteringPointTypeName === 'ELECTRICITY_CUSTOM') {
      setExpertMode(true);
    }
  }, [meteringPointTypeName]);

  const meterType: MeterType | undefined = useWatch({control: control, name: 'type'});
  useEffect(() => {
    console.info('checking for new type selected');
    if (meterType && (props.meteringPoint.type?.id !== meterType.id)) {
      console.info('type changed, setting name to ' + meterType.displayName);
      setValue('name', meterType.displayName);
      setValue('activeMeter.meterReadingType', meterType.defaultMeterReadingType);
    }
  }, [meterType, props.meteringPoint, props.meteringPoint.type, setValue]);

  const activeMeterId: number | undefined = useWatch({control: control, name: 'activeMeter.id'});

  if (!props.meteringPoint) {
    return <Loading/>;
  }

  function handleSave(meteringPoint: MeteringPoint) {
    props.onSave(meteringPoint).then((result) => {
      if (result) {
        result.forEach((error) => {
          setError(error.field, {message: error.defaultMessage});
          let errorMessage = error.defaultMessage;
          (props.meterTypes ? props.meterTypes.content : []).forEach((type) => {
            if (errorMessage.includes(type.name)) {
              errorMessage = errorMessage.replace(type.name, '"' + type.displayName + '"');
            }
          });
          setBackendErrors(errorMessage);
        });
      }
    });
  }

  return (
    <FormProvider {...form}>
      <RhfForm id={'MeteringPointForm'} form={form} handleSave={handleSave}>
        <MeteringPointFormSection expertMode={expertMode} meterTypes={props.meterTypes}/>
        <FormSection>
          <FormControlLabel
            control={
              <Switch
                id={'expert_mode'}
                checked={expertMode}
                onChange={() => setExpertMode(!expertMode)}
                value="expert_mode"
              />
            }
            label={t('MeteringPointForm.expertModeLabel')}
          />
        </FormSection>
        <MeterForm expertMode={expertMode}/>
        <TariffSubscriptionForm name={'activeTariffSubscription'}/>
        {meterDataSource === MeterDataSource.MQTT && activeMeterId &&
          <MqttForm meterId={activeMeterId}/>
        }
        <Grid item={true} xs={12}>
          <FormHelperText error={true}>{backendErrors}</FormHelperText>
          <CustomSaveButton/>
        </Grid>
      </RhfForm>
    </FormProvider>
  );
}

export function MeteringPointFormSection(props: {
  expertMode: boolean,
  meterTypes: PagedResult<MeterType>
}) {
  const {t} = useTranslation();
  const required = makeRequired<MeteringPoint>(meteringPointSchema());

  return (
    <FormSection title={t('MeteringPoint.titleSection')}>
      <FormItem>
        <CustomSelect
          name={'type'}
          label={t('MeteringPoint.type')}
          required={required.type}
          list={props.meterTypes ? props.meterTypes.content : []}
          getListLabel={(entry: MeterType) => entry.displayName}
          getListKey={(entry: MeterType) => entry.id}
          getListValue={(entry: MeterType) => entry}
        />
      </FormItem>
      <FormItem key={'name'} hidden={!props.expertMode}>
        <CustomTextField
          required={required.name}
          name={'name'}
          label={t('MeteringPoint.name')}
          fullWidth={true}
        />
      </FormItem>
      <FormItem key={'location'}>
        <CustomTextField
          required={required.location}
          name={'location'}
          label={t('MeteringPoint.location')}
          fullWidth={true}
        />
      </FormItem>
    </FormSection>
  );
}
