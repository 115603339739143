import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TariffProviderForm from './TariffProviderForm';
import { getTariffProvider, updateTariffProvider } from './tariffProviderApi';
import { useHistory } from 'react-router';
import { TARIFF_PROVIDER_MAPPING } from '../shared/components/router/AppRouter';
import { TariffProvider } from './TariffProvider';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { Loading } from '../shared/components/Loading';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function EditTariffProviderPage(props: {
  tariffProviderId: string
}) {
  const history = useHistory();
  const customAxios = useAxios();
  const {t} = useTranslation();
  const {} = useAppBarSetting(t('TariffProvider.title'));
  const [tariffProvider, setTariffProvider] = useState<TariffProvider>();

  useEffect(() => {
    props.tariffProviderId && getTariffProvider(customAxios, props.tariffProviderId).then(setTariffProvider);
  }, [props.tariffProviderId, customAxios]);

  if (!tariffProvider) {
    return <Loading/>;
  }

  return (
    <>
      <TariffProviderForm
        tariffProvider={tariffProvider}
        onSave={(tariffProvider => {
          updateTariffProvider(customAxios, tariffProvider).then(() => history.push(TARIFF_PROVIDER_MAPPING));
        })}
      />
    </>
  );
}
