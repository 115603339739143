import { RouteComponentProps, useHistory } from 'react-router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { useAppStyle } from '../styles/appStyle';
import { HOME_MAPPING, meteringPointNewMapping } from '../shared/components/router/AppRouter';
import { EnergyCategory } from '../categories/EnergyCategory';
import Fab from '@material-ui/core/Fab';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getMeteringPointOverviewForCategory } from './MeteringPointApi';
import { getEnergyCategory } from '../categories/CategoryApi';
import { Loading } from '../shared/components/Loading';
import { MeterCard } from './MeterCard';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LongText } from '../shared/components/text/LongText';
import { MeteringPointOverview } from './MeteringPoint';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

interface Props extends RouteComponentProps<{}> {
  categoryId: number;
}

export function CategoryDashboardPage(props: Props) {
  const [meteringPointOverview, setMeteringPointOverview] = useState<MeteringPointOverview[]>();
  const [category, setCategory] = useState<EnergyCategory>();
  const customAxios = useAxios();
  const history = useHistory();
  const classes = useAppStyle();
  const {t} = useTranslation();
  const {setTitle} = useAppBarSetting();

  useEffect(() => {
    getMeteringPointOverviewForCategory(customAxios, props.categoryId)
      .then(overview => {
        overview.sort((a, b) => a.position - b.position);
        setMeteringPointOverview(overview);
        getEnergyCategory(customAxios, props.categoryId).then(setCategory);
      }, () => {
        history.push(HOME_MAPPING);
      });
  }, [props.categoryId, customAxios, history]);

  useEffect(() => {
    category && setTitle(t('CategoryDashboardPage.title', {categoryName: category.displayName}));
  }, [category, setTitle, t]);

  if (!meteringPointOverview || !category) {
    return (
      <>
        <Loading/>
      </>
    );
  }
  let title = category.displayName + ' Zähler';
  return (
    <>
      <Grid id={'cards'} container={true} spacing={2}>
        {meteringPointOverview.length === 0 &&
          <strong>
            <LongText i18nKey={'CategoryDashboardPage.noMeterMessage'}/>
          </strong>
        }
        {meteringPointOverview.map((meteringPointOverview) => {
          return (
            <MeterCard
              key={meteringPointOverview.id}
              category={category}
              meteringPointOverview={meteringPointOverview}
              setMeteringPointOverviews={(meteringPointOverviews: MeteringPointOverview[]) => setMeteringPointOverview(meteringPointOverviews)}
            />
          );
        })
        }
        <Tooltip title={t('CategoryDashboardPage.actions.new.tooltip') as string}>
          <Fab
            key={'add_button'}
            id={'add_button'}
            color="primary"
            size="medium"
            aria-label={t('actions.new')}
            className={classes.fabButton}
            onClick={() => props.history.push(meteringPointNewMapping(category.id.toString()))}
          >
            <AddIcon/>
          </Fab>
        </Tooltip>
      </Grid>
      <div style={{width: '100%', height: '10%'}}/>
    </>
  );
}

