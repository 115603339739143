import * as yup from 'yup';

export class ValidityInfo {
  validFrom?: string;
  validTo?: string;
  active?: boolean;
}

export function validityInfoSchema(): yup.SchemaOf<ValidityInfo> {
  return yup.object({
    validFrom: yup.string().transform(date => {
      if (!date) return undefined;
      else return date;
    }),
    validTo: yup.string().transform(date => {
      if (!date) return undefined;
      else return date;
    }),
    active: yup.boolean()
  }).defined();
}

/**
 * Valid From / To as date only without time (or as 00:00)
 */
export function validityInfoDateOnlySchema(): yup.SchemaOf<ValidityInfo> {
  return yup.object({
    validFrom: yup.string().transform(date => {
      if (!date) return undefined;
      else return date;
    }),
    validTo: yup.string().transform(date => {
      if (!date) return undefined;
      else return date;
    }),
    active: yup.boolean()
  }).defined();
}
