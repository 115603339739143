import { AxiosError, AxiosResponse } from 'axios';
import { ValidationErrorResponse } from './validation';

// tslint:disable-next-line: no-any
export function handleSuccessResponse(response: AxiosResponse): Promise<any> {
  return Promise.resolve(response.data);
}

export function handleErrorResponse(error: AxiosError): Promise<ValidationErrorResponse> {
  if (error.response && error.response.status === 400) {
    if (error.response.data && error.response.data.errors) {
      return Promise.resolve(error.response.data as ValidationErrorResponse);
    } else {
      throw new Error('Unbekannter Fehler aufgetreten: ' + JSON.stringify(error.response.data));
    }
  } else {
    throw new Error(error.message);
  }
}
