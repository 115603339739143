import { Controller, useFormContext } from 'react-hook-form';
import * as React from 'react';
import { CheckboxProps, FormHelperText, InputLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { retrieveError } from './Forms';

export function CustomCheckbox(props: {
  name: string,
  label: string
} & Partial<Omit<CheckboxProps, 'onChange' | 'onBlur' | 'value' | 'name'>>) {
  const {name, label, ...rest} = props;
  const {control, formState: {errors}} = useFormContext<any>();
  const errorMessage = retrieveError(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      render={renderProps =>
        <div style={{width: '100%'}}>
          <InputLabel htmlFor={'select_' + name} shrink={true}>{label}</InputLabel>
          <Checkbox
            name={renderProps.field.name}
            onChange={(event) => renderProps.field.onChange(event.target.checked)}
            onBlur={renderProps.field.onBlur}
            checked={renderProps.field.value || false}
            {...rest}
          />
          <FormHelperText>{errorMessage}</FormHelperText>
        </div>
      }
    />
  );
}
