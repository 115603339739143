import { apiHost, deleteOne, queryOne } from '../../../shared/technical/rest/restApi';
import { AxiosInstance } from 'axios';
import { RenaultConnectionStatus, RenaultLogin } from './RenaultAdapter';
import { handleErrorResponse, handleSuccessResponse } from '../../../shared/technical/rest/axiosUtils';
import { ValidationErrorResponse } from '../../../shared/technical/rest/validation';
import { VehicleWithStatus } from '../../Vehicle';

export function getRenaultConnectionStatus(customAxios: AxiosInstance): Promise<RenaultConnectionStatus> {
  return queryOne(customAxios, 'adapter/renault/status');
}

export function connectMyRenault(customAxios: AxiosInstance, login: RenaultLogin): Promise<VehicleWithStatus[] | ValidationErrorResponse> {
  return customAxios.post(apiHost + '/' + 'adapter/renault', login)
    .then(handleSuccessResponse, handleErrorResponse);
}

export function refreshMyRenault(customAxios: AxiosInstance): Promise<VehicleWithStatus[] | ValidationErrorResponse> {
  return customAxios.post(apiHost + '/adapter/renault/refresh')
    .then(handleSuccessResponse, handleErrorResponse);
}

export function unConnectMyRenault(customAxios: AxiosInstance): Promise<void> {
  return deleteOne(customAxios, 'adapter/renault');
}
