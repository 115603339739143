import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TariffForm from './TariffForm';
import { useHistory } from 'react-router';
import { tariffsMapping } from '../shared/components/router/AppRouter';
import { Tariff } from './TariffProvider';
import { getTariff, saveTariff } from './tariffProviderApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { Loading } from '../shared/components/Loading';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function EditTariffPage(props: {
  tariffProviderId: string,
  tariffId: string
}) {
  const {tariffProviderId, tariffId} = {...props};
  const history = useHistory();
  const {t} = useTranslation();
  const [tariff, setTariff] = useState<Tariff>();
  const customAxios = useAxios();
  const {setTitle} = useAppBarSetting();

  useEffect(() => {
    tariffId && getTariff(customAxios, tariffId).then((tariff) => {
      setTariff(tariff);
      setTitle(t('Tariff.titleEdit') + (tariff ? ' (' + tariff.tariffProvider.name + ')' : ''));
    });
  }, [tariffId, tariffProviderId, customAxios, setTitle, t]);

  if (!tariff) {
    return <Loading/>;
  }

  return (
    <>
      <TariffForm
        tariffProviderId={props.tariffProviderId}
        tariff={tariff}
        onSave={(tariff => {
          saveTariff(customAxios, tariff).then(() => history.push(tariffsMapping(props.tariffProviderId)));
        })}
      />
    </>
  );
}
