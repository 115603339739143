import { TFunction } from 'i18next';

export function muiDataTablesLabels(t: TFunction) {
  return (
    {
      body: {
        noMatch: 'Keine Daten vorhanden',
        toolTip: 'Sortierung',
        columnHeaderTooltip: column => `Sortierung für ${column.label}`
      },
      pagination: {
        next: 'Weiter',
        previous: 'Zurück',
        rowsPerPage: 'Zeilen pro Seite:',
        displayRows: 'von',
      },
      toolbar: {
        search: 'Suchen',
        downloadCsv: 'CSV Download',
        print: 'Drucken',
        viewColumns: 'Spalten anzeigen',
        filterTable: 'Filtern',
      },
      filter: {
        all: 'Alle',
        title: 'FILTER',
        reset: 'ZURÜCKSETZEN',
      },
      viewColumns: {
        title: 'Zeige Spalten',
        titleAria: 'Zeige/Verberge Spalten',
      },
      selectedRows: {
        text: 'Zeile(n) ausgewählt',
        delete: 'Löschen',
        deleteAria: 'Lösche ausgewählte Zeile',
      }
    });
}
