import { useTranslation } from 'react-i18next';
import * as React from 'react';
import Typography from '@material-ui/core/Typography';

export function LongText(props: {
  i18nKey: string
}) {
  const {t} = useTranslation();

  const text = t(props.i18nKey);
  const lines = text.split('\n');

  let x = 0;

  return (
    <>
      {lines.map(line => <Typography key={x++}>{line}</Typography>)}
    </>
  );
}
