import * as yup from 'yup';
import { VehicleWithStatus } from '../../Vehicle';

export interface RenaultLogin {
  userName: string,
  password: string
}

export interface RenaultConnectionStatus {
  connected: boolean,
  vehicles: VehicleWithStatus[]
}

export const NOT_CONNECTED_STATUS = {connected: false} as RenaultConnectionStatus;

export function renaultLoginSchema(): yup.SchemaOf<RenaultLogin> {
  return yup.object({
    userName: yup.string().required(),
    password: yup.string().required()
  }).defined();
}
