import * as React from 'react';
import { createContext, ReactNode, useContext, useState } from 'react';

export interface DrawerState {
  drawerOpen: boolean,
  toggleDrawer: () => void
}

const DrawerContext = createContext<DrawerState | undefined>(undefined);

export function useDrawer(): DrawerState {
  const drawerContext = useContext(DrawerContext);
  if (!drawerContext) {
    throw new Error('useDrawer not inside Provider');
  }
  return drawerContext;
}

export function DrawerProvider(props: {
  children?: ReactNode;
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(drawerOpen => !drawerOpen);
  };

  return (
    <DrawerContext.Provider value={{drawerOpen: drawerOpen, toggleDrawer: toggleDrawer}}>
      {props.children}
    </DrawerContext.Provider>
  )
}
