import { Meter } from '../meters/Meter';
import { applyMagnitude, formatMagnitizedValue } from '../units/magnitudeUtils';
import { Magnitude } from '../units/Unit';
import { DateTimeRange, dateTimeRangeSchema } from '../shared/technical/dateTime/DateTimeRange';
import { EntityRef, entityRefSchema } from '../shared/technical/rest/pageable';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { MeteringPoint } from '../meters/MeteringPoint';

export function formatReading(reading: Reading): string {
  let value = applyMagnitude(reading.meteringPoint.magnitude, reading.value);
  return formatMagnitizedValue(reading.meteringPoint.unit.code, reading.meteringPoint.magnitude, value);
}

export function formatNumber(value: number, magnitude: Magnitude, unitCode: string): string {
  let magnitizedValue = applyMagnitude(magnitude, value);
  return formatMagnitizedValue(unitCode, magnitude, magnitizedValue);
}

export interface ReadingShort {
  id: number;
  meteringPoint: EntityRef;
  meter: EntityRef;
  value: number;
}

export interface Reading extends ReadingShort {
  meteringPoint: MeteringPoint;
  meter: Meter;
}

export interface ConcreteValueReadingShort extends ReadingShort {
  readDate: string;
}

export interface ConcreteValueReading extends ConcreteValueReadingShort, Reading {
  meteringPoint: MeteringPoint;
  meter: Meter;
}

export function newConcreteValueReading() {
  return {
    value: 0,
    readDate: DateTime.local().toISO()
  } as ConcreteValueReading;
}

export function concreteValueReadingShortSchema(): yup.SchemaOf<ConcreteValueReadingShort> {
  return yup.object({
    id: yup.number(),
    readDate: yup.string(),
    meteringPoint: entityRefSchema(),
    value: yup.number()
  }) as unknown as yup.SchemaOf<ConcreteValueReadingShort>;
}

export interface DeltaValueReadingShort extends ReadingShort {
  readDate: string;
  dateRange: DateTimeRange;
}

export interface DeltaValueReading extends DeltaValueReadingShort, Reading {
  meteringPoint: MeteringPoint;
  meter: Meter;
}

export function newDeltaValueReading() {
  return {
    value: 0,
    readDate: DateTime.local().toISO()
  } as DeltaValueReading;
}

export function deltaValueReadingShortSchema(): yup.SchemaOf<DeltaValueReadingShort> {
  return yup.object({
    id: yup.number(),
    meteringPoint: entityRefSchema(),
    meter: entityRefSchema(),
    value: yup.number(),
    readDate: yup.string(),
    dateRange: dateTimeRangeSchema(),
  }) as unknown as yup.SchemaOf<DeltaValueReadingShort>;
}
