import { DateTime, Interval } from 'luxon';
import { TimeUnit } from './TimeUnit';
import { parseIsoFormat } from './DateTime';
import * as yup from 'yup';

export class TimeRange {
  timeUnit: TimeUnit;
  second?: number;
  minute?: number;
  hour?: number;
  day?: number;
  week?: number;
  month?: number;
  quarter?: number;
  year?: number;
}

export function formatTimeRange(format, timeRange: TimeRange): string {
  let result = replace(format, 'yyyy', timeRange.year);
  result = replace(result, 'q', timeRange.quarter);
  result = replace(result, 'MM', timeRange.month);
  result = replace(result, 'w', timeRange.week);
  result = replace(result, 'dd', timeRange.day);
  result = replace(result, 'hh', timeRange.hour || 0);
  result = replace(result, 'mm', timeRange.minute || 0);
  result = replace(result, 'ss', timeRange.second || 0);
  return result;
}

function replace(template: string, pattern: string, value?: number): string {
  if (value === undefined) {
    return template.replace(pattern, '');
  }
  let valueStr = value.toString();
  if (pattern.length > 1) {
    while (pattern.length - valueStr.length > 0) {
      valueStr = '0' + valueStr;
    }
  }
  return template.replace(pattern, valueStr);
}

export function dateRangeBegin(timeRange: TimeRange): DateTime {
  switch (timeRange.timeUnit) {
    case TimeUnit.CENTURY:
    case TimeUnit.DECADE:
    case TimeUnit.YEAR:
      return DateTime.local(timeRange.year, 1, 1);
    case TimeUnit.QUARTER:
      return DateTime.local(timeRange.year, (timeRange.quarter!! - 1) * 3 + 1, 1);
    case TimeUnit.MONTH:
      return DateTime.local(timeRange.year, timeRange.month, 1);
    case TimeUnit.WEEK:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day || 1).startOf('week');
    case TimeUnit.DAY:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day);
    case TimeUnit.HOUR:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day, timeRange.hour);
    case TimeUnit.MINUTE:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day, timeRange.hour, timeRange.minute);
    case TimeUnit.SECOND:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day, timeRange.hour, timeRange.minute, timeRange.second);
    default:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day);
  }
}

export function dateRangeEnd(timeRange: TimeRange): DateTime {
  switch (timeRange.timeUnit) {
    case TimeUnit.CENTURY:
    case TimeUnit.DECADE:
    case TimeUnit.YEAR:
      return DateTime.local(timeRange.year, 12, 31);
    case TimeUnit.QUARTER:
      return DateTime.local(timeRange.year, (timeRange.quarter!!) * 3, 1).endOf('month');
    case TimeUnit.MONTH:
      return DateTime.local(timeRange.year, timeRange.month, 1).endOf('month');
    case TimeUnit.WEEK:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day || 1).endOf('week');
    case TimeUnit.DAY:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day).plus({days: 1});
    case TimeUnit.HOUR:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day, timeRange.hour).plus({hours: 1});
    case TimeUnit.MINUTE:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day, timeRange.hour, timeRange.minute).plus({minute: 1});
    case TimeUnit.SECOND:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day, timeRange.hour, timeRange.minute, timeRange.second).plus({second: 1});
    default:
      return DateTime.local(timeRange.year, timeRange.month, timeRange.day);
  }
}

export function timeRangeFractions(timeRange: DateTimeRange, sliceCount: number) {
  let begin = parseIsoFormat(timeRange.firstDateTime);
  let end = parseIsoFormat(timeRange.lastDateTime);
  let interval = Interval.fromDateTimes(begin, end);
  let intervals = interval.divideEqually(sliceCount);
  return intervals;
}

export class DateTimeRange {
  firstDateTime: string;
  lastDateTime: string;

  constructor(firstDate: string, lastDate: string) {
    this.firstDateTime = firstDate;
    this.lastDateTime = lastDate;
  }
}

export function dateTimeRangeSchema() {
  return yup.object<DateTimeRange>({
    firstDateTime: yup.string(),
    lastDateTime: yup.string()
  }).defined()
}
