import React, { Suspense } from 'react';
import { appTheme } from './styles/appTheme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { AppRouter } from './shared/components/router/AppRouter';
import { MyErrorBoundary } from './shared/components/errors/MyErrorBoundary';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { log } from './logConfig';
import { ServiceWorkerWrapper } from './shared/technical/pwa/ServiceWorkerWrapper';
import { translation } from './i18n';
import { I18nextProvider } from 'react-i18next';
import { Loading } from './shared/components/Loading';

log.info('Starting Homemeter Web UI version ' + process.env.REACT_APP_VERSION);

export function App() {

  return (
    <React.Fragment>
      <CssBaseline/>
      <Suspense fallback={<Loading/>}>
        <MyErrorBoundary>
          <I18nextProvider i18n={translation}>
            <MuiThemeProvider theme={appTheme}>
              <MuiPickersUtilsProvider utils={LuxonUtils} locale={translation.language}>
                <AppRouter/>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
            <ServiceWorkerWrapper/>
          </I18nextProvider>
        </MyErrorBoundary>
      </Suspense>
    </React.Fragment>
  );
}

export const NotFound = () => <div>Page not found</div>;

export default App;
