import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { submitForm } from '../forms/Forms';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      marginTop: theme.spacing(2)
    }
  };
});

export function CustomSaveButton<T>(props: {
  disabled?: boolean,
  tooltipText?: string
}) {
  const {t} = useTranslation();
  const classes = useStyles(props);
  if (props.tooltipText) {
    return (
      <Tooltip title={props.tooltipText}>
        <div>
          <Button type={'submit'} variant={'contained'} color={'primary'} className={classes.button} disabled={props.disabled}>
            {t('actions.save')}
          </Button>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Button type={'submit'} variant={'contained'} color={'primary'} className={classes.button} disabled={props.disabled}>
        {t('actions.save')}
      </Button>
    );
  }
}

export function FormSaveButton(props: {
  formName: string
}) {
  const {t} = useTranslation();
  return (
    <Button id="save_button" onClick={() => {
      submitForm(props.formName);
    }} color="primary" variant={'contained'}>
      {t('actions.save')}
    </Button>
  );
}
