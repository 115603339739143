import React, { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type IconType = 'PRIMARY' | 'BACK';

export interface AppBarSetting {
  title: string,
  setTitle: (title: string) => void,
  icon: IconType,
  setIcon: (icon: IconType) => void
}

export const AppBarSettingContext = React.createContext<AppBarSetting | undefined>(undefined);

export function useAppBarSetting(title?: string, icon?: IconType): AppBarSetting {
  const context = useContext(AppBarSettingContext);
  if (!context) {
    throw new Error('useAppBarSetting must be used within the AppBarSettingContext.Provider');
  }
  title && context.setTitle(title);
  icon && context.setIcon(icon);
  return context;
}

export function AppBarSettingsProvider(props: {
  children?: ReactNode;
}) {
  const {t} = useTranslation();
  const [title, setTitle] = useState<string>(t('title'));
  const [icon, setIcon] = useState<IconType>('PRIMARY');

  // console.info('title: ' + title);

  return (
    <AppBarSettingContext.Provider value={{
      title: title,
      setTitle: (title: string) => {
        setTitle(title);
      },
      icon: icon,
      setIcon: (icon: IconType) => {
        setIcon(icon);
      }
    }}>
      {props.children}
    </AppBarSettingContext.Provider>
  );
}
