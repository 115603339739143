import React, { useEffect, useState } from 'react';
import { Tariff, tariffSchema } from './TariffProvider';
import { useTranslation } from 'react-i18next';
import { getTariffProvider } from './tariffProviderApi';
import { InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { TIME_UNITS, TimeUnit } from '../shared/technical/dateTime/TimeUnit';
import { getUnitForSubstance, Substance, SUBSTANCES } from '../units/Substance';
import { Magnitude, MAGNITUDES, Unit } from '../units/Unit';
import { CustomSaveButton } from '../shared/components/buttons/SaveButton';
import { dateFormatSmall } from '../shared/technical/dateTime/DateTime';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getUnits } from '../units/UnitApi';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeRequired, RhfForm } from '../shared/components/forms/Forms';
import { CustomSelect } from '../shared/components/forms/CustomSelect';
import { CustomTextField } from '../shared/components/forms/CustomTextField';
import { CustomDatePicker } from '../shared/components/forms/CustomDatePicker';
import { CustomCheckbox } from '../shared/components/forms/CustomCheckbox';

export default function TariffForm(props: {
  tariff: Tariff,
  tariffProviderId: string,
  onSave: (tariff: Tariff) => void;
}) {
  const {t} = useTranslation();
  const customAxios = useAxios();
  const [units, setUnits] = useState<Unit[]>([]);

  const form = useForm<Tariff>({
    resolver: yupResolver(tariffSchema()),
    defaultValues: props.tariff,
    mode: 'onBlur'
  });
  const {control, formState: {errors, isValid}, setValue, register} = form;

  useEffect(() => {
    register('id');
    register('version');
    register('tariffProvider');
  }, [register]);

  useEffect(() => {
    getTariffProvider(customAxios, props.tariffProviderId).then(tariffProvider => {
      setValue('tariffProvider', tariffProvider);
    });
  }, [props.tariffProviderId, customAxios, setValue]);

  const substance: string | undefined = useWatch({control: control, name: 'substance'});
  useEffect(() => {
    if (!units || !substance) {
      return;
    }
    if (substance && !props.tariff?.rateUnit) {
      const unitCode = getUnitForSubstance(substance as Substance);
      if (unitCode) {
        let unit = units.find((unit) => unit.code === unitCode.toString());
        setValue('rateUnit', unit, {shouldValidate: true});
      } else {
        setValue('rateUnit', undefined, {shouldValidate: true});
      }
    }
  }, [substance, units, props.tariff?.rateUnit, setValue]);

  useEffect(() => {
    getUnits(customAxios).then(units => {
      setUnits(units.content);
    });
  }, [customAxios, substance]);

  const required = makeRequired<Tariff>(tariffSchema());

  return (
    <FormProvider {...form}>
      <RhfForm handleSave={props.onSave} form={form}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={3}>
            <CustomSelect
              required={required.substance}
              name={'substance'}
              label={t('Tariff.substance')}
              list={SUBSTANCES}
              getListLabel={(substance: Substance) => t('Substance.' + substance)}
              getListValue={(substance: Substance) => substance}
              getListKey={(substance: Substance) => substance}
            />
          </Grid>
          <Grid item={true} xs={12} md={9}>
            <CustomTextField
              required={required.name}
              name={'name'}
              label={t('Tariff.name')}/>
          </Grid>
        </Grid>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={6}>
            <Grid container={true}>
              <Grid item={true} xs={6}>
                <CustomTextField
                  type={'number'}
                  required={required.basicRate.amount}
                  name={'basicRate.amount'}
                  label={t('Tariff.basicRate.title')}
                  unit={props.tariff?.basicRate?.currencyCode || '€'}
                />
              </Grid>
              <Grid item={true} xs={6}>
                <CustomSelect
                  name={'basicRatePeriod'}
                  required={required.basicRatePeriod}
                  label={t('Tariff.basicRatePeriod')}
                  list={TIME_UNITS}
                  getListLabel={(timeUnit: TimeUnit) => t('TimeUnit.' + timeUnit)}
                  getListValue={(timeUnit: TimeUnit) => timeUnit}
                  getListKey={(timeUnit: TimeUnit) => timeUnit}
                  fullWidth={false}
                  autoWidth={true}
                  // formControlProps={{
                  //   fullWidth: false,
                  //   size: 'medium'
                  // }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Grid container={true}>
              <Grid item={true} xs={8}>
                <CustomTextField
                  type={'number'}
                  required={required.rate.amount}
                  fullWidth={false}
                  name={'rate.amount'}
                  label={t('Tariff.rate.title')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{props.tariff?.rate?.currencyCode || ''}</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item={true} xs={2}>
                <CustomSelect
                  name={'rateMagnitude'}
                  label={t('Tariff.rate.magnitude')}
                  required={required.rateMagnitude}
                  list={MAGNITUDES}
                  getListLabel={(magnitude: Magnitude) => t('Magnitude.' + magnitude)}
                  getListValue={(magnitude: Magnitude) => magnitude}
                  getListKey={(magnitude: Magnitude) => magnitude}
                  fullWidth={false}
                  autoWidth={true}
                />
              </Grid>
              <Grid item={true} xs={2}>
                <CustomSelect
                  required={required.rateUnit}
                  name={'rateUnit'}
                  label={t('Tariff.rate.unitCode')}
                  list={units}
                  getListLabel={(unit: Unit) => unit.code}
                  getListValue={(unit: Unit) => unit.code}
                  getListKey={(unit: Unit) => unit.code}
                  fullWidth={false}
                  autoWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={4}>
            <CustomDatePicker
              required={required.validityInfo.validFrom}
              label={t('ValidityInfo.validFrom')}
              name={'validityInfo.validFrom'}
              format={dateFormatSmall(t)}
              clearable/>
          </Grid>
          <Grid item={true} xs={12} md={4}>
            <CustomDatePicker
              required={required.validityInfo.validTo}
              label={t('ValidityInfo.validTo')}
              name={'validityInfo.validTo'}
              format={dateFormatSmall(t)}
              clearable/>
          </Grid>
          <Grid item={true} xs={12} md={4} xl={2}>
            <CustomCheckbox label={t('Tariff.private')} name={'private'}/>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <CustomSaveButton tooltipText={isValid ? undefined : JSON.stringify(errors) + JSON.stringify(errors)}/>
        </Grid>
      </RhfForm>
    </FormProvider>
  );
}
