import { EnergyCategory } from '../categories/EnergyCategory';
import { MeterDataSource, MeterReadingType } from './Meter';
import { useHistory } from 'react-router';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { CardActions, CardContent, CardHeader } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { formatValue } from '../units/magnitudeUtils';
import { toIsoUriFormat, toLocalDate } from '../shared/technical/dateTime/DateTime';
import { ReadingsBarChart } from '../charts/ReadingsBarChart';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { deleteMeteringPoint, getMeteringPointOverviewForCategory } from './MeteringPointApi';
import {
  meteringPointBillsMapping,
  meteringPointEditMapping,
  meteringPointReadingsMapping,
  readingAddMapping,
  readingChartMapping
} from '../shared/components/router/AppRouter';
import EditIcon from '@material-ui/icons/Edit';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import PaymentIcon from '@material-ui/icons/Payment';
import * as React from 'react';
import { History } from 'history';
import { dateRangeBegin, dateRangeEnd } from '../shared/technical/dateTime/DateTimeRange';
import { prevTimeUnit } from '../shared/technical/dateTime/TimeUnit';
import { DeleteIconButton } from '../shared/components/buttons/DeleteIconButton';
import { MeteringPointOverview } from './MeteringPoint';

export function MeterCard(props: {
  category: EnergyCategory,
  meteringPointOverview: MeteringPointOverview,
  setMeteringPointOverviews: (overviews: MeteringPointOverview[]) => void
}) {
  const history = useHistory();
  const customAxios = useAxios();
  const {t} = useTranslation();

  const {meteringPointOverview, category, setMeteringPointOverviews} = props;
  let meterInformation = (!meteringPointOverview.location && !meteringPointOverview.activeMeter.serialNumber) ? '-' :
    (meteringPointOverview.location || '') + (meteringPointOverview.activeMeter.serialNumber || '');
  return (
    <Grid item={true} key={meteringPointOverview.id} xs={12} md={6} lg={4} xl={3}>
      <Card id={'card_' + meteringPointOverview.id}>
        <CardHeader title={meteringPointOverview.name} subheader={meterInformation}/>
        <CardContent>
          <Typography variant={'caption'}>Letzte Messung:</Typography>
          <Typography variant={'body1'}>
            {meteringPointOverview.lastReading ?
              (formatLastReadingValue(meteringPointOverview) + ' am ' + toLocalDate(meteringPointOverview.lastReading.readDate)) :
              (meteringPointOverview.activeMeter.meterReadingType === MeterReadingType.CALCULATED_VALUES) ? 'Berechnete Werte' :
                (meteringPointOverview.activeMeter.dataSource === MeterDataSource.MQTT) ? 'Nicht verfügbar' :
                  (meteringPointOverview.activeMeter.meterReadingType === MeterReadingType.DELTA_VALUES) ? 'Nicht ermittelt' : 'Noch keine Messung erfolgt'}
          </Typography>
          <ReadingsBarChart mainValues={meteringPointOverview.deltaValues} meteringPoint={meteringPointOverview}
                            handleChartClick={(data, index) => handleChartClick(history, category, meteringPointOverview, index)}/>
        </CardContent>
        <CardActions>
          <DeleteIconButton id={meteringPointOverview.id}
                            name={meteringPointOverview.name}
                            tooltipTitle={t('MeteringPoint.titleDelete', {name: meteringPointOverview.name})}
                            onDelete={() => deleteMeteringPoint(customAxios, meteringPointOverview.id)
                              .then(() => getMeteringPointOverviewForCategory(customAxios, category.id)
                                .then(setMeteringPointOverviews))
                            }
          />
          <Tooltip title={t('actions.edit') as string}>
            <IconButton
              id={'edit_' + meteringPointOverview.id}
              aria-label={t('actions.edit')}
              onClick={() => history.push(meteringPointEditMapping(category.id.toString(), meteringPointOverview.id.toString()))}
            >
              <EditIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title={t('actions.diagram')!!}>
            <IconButton
              id={'diagram_' + meteringPointOverview.id}
              aria-label={t('actions.diagram')}
              onClick={() => history.push(readingChartMapping(category.id.toString(), meteringPointOverview.id.toString()))}
            >
              <ShowChartIcon/>
            </IconButton>
          </Tooltip>
          <Tooltip title={'Messungen verwalten'}>
            <IconButton
              id={'readings_' + meteringPointOverview.id}
              aria-label="Messungen"
              onClick={() => history.push(meteringPointReadingsMapping(meteringPointOverview.id.toString()))}
            >
              <ListIcon/>
            </IconButton>
          </Tooltip>

          {meteringPointOverview.activeMeter.meterReadingType !== MeterReadingType.CALCULATED_VALUES &&
          <Tooltip title={'Messung hinzufügen'}>
            <IconButton
              id={'add_reading_' + meteringPointOverview.id}
              aria-label="Messung hinzufügen"
              onClick={() => history.push(readingAddMapping(meteringPointOverview.id.toString()))}
            >
              <AddIcon/>
            </IconButton>
          </Tooltip>
          }
          <Tooltip title={t('CategoryDashboardPage.actions.bills') + ''}>
            <IconButton
              id={'add_bill_' + meteringPointOverview.id}
              aria-label={t('CategoryDashboardPage.actions.bills')}
              onClick={() => history.push(meteringPointBillsMapping(meteringPointOverview.id.toString()))}
            >
              <PaymentIcon/>
            </IconButton>
          </Tooltip>

        </CardActions>
      </Card>
    </Grid>
  );
}

function formatLastReadingValue(meteringPoint: MeteringPointOverview): string {
  let lastReading = meteringPoint.lastReading;
  if (!lastReading) {
    return '';
  }
  return formatValue(lastReading.value, meteringPoint.unit.code, lastReading.meteringPoint?.type?.magnitudes);
}

function handleChartClick(history: History, category: EnergyCategory, meteringPoint: MeteringPointOverview, chartIndex: number) {
  let deltaValue = meteringPoint.deltaValues.values[chartIndex];
  let start = dateRangeBegin(deltaValue.range);
  let end = dateRangeEnd(deltaValue.range);
  let resolution = prevTimeUnit(deltaValue.range.timeUnit);
  const from = toIsoUriFormat(start);
  const to = toIsoUriFormat(end);
  history.push(readingChartMapping(category.id.toString(), meteringPoint.id.toString()) + '?from=' + from + '&to=' + to + '&resolution=' + resolution);
}
