import * as React from 'react';
import { ReactNode } from 'react';
import { CustomDrawer } from '../drawer/CustomDrawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core';
import { DrawerContent } from '../drawer/DrawerContent';
import { CustomAppBar } from '../appbar/CustomAppBar';

const useStyles = makeStyles((theme: Theme) => createStyles({
  page: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  pageWithoutDrawer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  pageContent: {
    height: '100%',
    margin: theme.spacing(1),
    overflow: 'auto',
  }
}));

export function PageFrame(props: {
  /**
   * The icon on the left side of the AppBar. Normally this is the menu icon for the drawer to open.
   * It can be overwritten here to show something like a back arrow etc.
   */
  icon?: 'PRIMARY' | 'BACK';
  children?: ReactNode;
}) {
  const classes = useStyles();

  return (
    <div className={classes.page} id={'page_frame'}>
      <CustomDrawer>
        <DrawerContent/>
      </CustomDrawer>
      <div className={classes.pageWithoutDrawer}>
        <CustomAppBar/>
        <div className={classes.pageContent}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
