import { apiHost, createOne, deleteOne, queryOne, queryPage, updateOne } from '../shared/technical/rest/restApi';
import { Tariff, TariffProvider } from './TariffProvider';
import { handleErrorResponse, handleSuccessResponse } from '../shared/technical/rest/axiosUtils';
import { AxiosInstance } from 'axios';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';
import { fullPageable, PagedResult } from '../shared/technical/rest/pageable';

export function getTariffProvider(customAxios: AxiosInstance, tariffProviderId: string | number): Promise<TariffProvider> {
  return queryOne<TariffProvider>(customAxios, 'tariff-provider', tariffProviderId);
}

export function getTariffProviders(customAxios: AxiosInstance): Promise<PagedResult<TariffProvider>> {
  return queryPage<TariffProvider>(customAxios, 'tariff-provider', fullPageable());
}

export function createTariffProvider(customAxios: AxiosInstance, tariffProvider: TariffProvider): Promise<TariffProvider | ValidationErrorResponse> {
  return createOne<TariffProvider>(customAxios, 'tariff-provider', tariffProvider);
}

export function updateTariffProvider(customAxios: AxiosInstance, tariffProvider: TariffProvider): Promise<TariffProvider | ValidationErrorResponse> {
  return updateOne<TariffProvider>(customAxios, 'tariff-provider', tariffProvider);
}

export function deleteTariffProvider(customAxios: AxiosInstance, tariffProviderId: string | number): Promise<void> {
  return deleteOne<TariffProvider>(customAxios, 'tariff-provider', tariffProviderId);
}

export function getTariffsByTariffProvider(customAxios: AxiosInstance, tariffProviderId: string | number): Promise<Tariff[]> {
  return customAxios.get(`${apiHost}/tariff-provider/${tariffProviderId}/tariffs` )
      .then(handleSuccessResponse, handleErrorResponse);
}

export function getTariff(customAxios: AxiosInstance, tariffId: string | number): Promise<Tariff> {
  return queryOne<Tariff>(customAxios, 'tariff', tariffId);
}

export function saveTariff(customAxios: AxiosInstance, tariff: Tariff): Promise<Tariff | ValidationErrorResponse> {
  if (tariff.id) {
    return updateOne<Tariff>(customAxios, 'tariff', tariff);
  } else {
    return createOne<Tariff>(customAxios, 'tariff', tariff);
  }
}

export function deleteTariff(customAxios: AxiosInstance, tariffId: string | number): Promise<void> {
  return deleteOne<Tariff>(customAxios, 'tariff', tariffId);
}
