import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TariffTable } from './TariffTable';
import { tariffNewMapping } from '../shared/components/router/AppRouter';
import { useHistory } from 'react-router';
import { TariffProvider } from './TariffProvider';
import { getTariffProvider } from './tariffProviderApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { AddButton } from '../shared/components/buttons/AddButton';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function TariffsPage(props: {
  tariffProviderId: string
}) {
  const {t} = useTranslation();
  const history = useHistory();
  const customAxios = useAxios();
  const {setTitle} = useAppBarSetting();
  const {tariffProviderId} = {...props};
  const [tariffProvider, setTariffProvider] = useState<TariffProvider>();

  useEffect(() => {
    getTariffProvider(customAxios, props.tariffProviderId).then((tariffProvider) => {
      setTariffProvider(tariffProvider);
      setTitle(t('Tariff.title') + (tariffProvider ? ' (' + tariffProvider.name + ')' : ''));
    });
  }, [props.tariffProviderId, customAxios, setTitle, t]);

  return (
    <>
      <TariffTable tariffProviderId={tariffProviderId}/>
      <AddButton
        onClick={() => history.push(tariffNewMapping(tariffProviderId))}
      />
    </>
  );
}
