import { createMuiTheme } from '@material-ui/core/styles';
import gray from '@material-ui/core/colors/grey';
import common from '@material-ui/core/colors/common';
import 'fontsource-roboto';

export const PRIMARY_COLOR_LIGHT = '#584dcb';
export const PRIMARY_COLOR = '#162499';
export const PRIMARY_COLOR_DARK = '#00006a';

export const ACCENT_COLOR_LIGHT = '#ffb544';
export const ACCENT_COLOR = '#ff8401';
export const ACCENT_COLOR_DARK = '#c55500';

const robotoBase = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
};

export const appTheme = createMuiTheme({
  palette: {
    primary: {
      light: PRIMARY_COLOR_LIGHT,
      main: PRIMARY_COLOR,
      dark: PRIMARY_COLOR_DARK,
    },
    secondary: {
      light: ACCENT_COLOR_LIGHT,
      main: ACCENT_COLOR,
      dark: ACCENT_COLOR_DARK,
    },
    grey: {...gray},
    background: {
      'default': common.white,
      paper: common.white,
    },
    text: {
      primary: common.black,
      secondary: gray['500'],
      disabled: gray['300'],
      hint: gray['500'],
    }
  },
  typography: {
    allVariants: {
      ...robotoBase
    }
  },
  overrides: {
    MuiFab: {
      root: {
        position: 'fixed',
        bottom: '24px',
        right: '24px'
      }
    }
  }
});
