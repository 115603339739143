import { DateTime } from 'luxon';
import { TFunction } from 'i18next';
import { ValidityInfo } from '../validity/ValidityInfo';

export function dateFormatSmall(t: TFunction) {
  return t('dateTime.smallDate')
}

export function formatStringDate(date?: string): string {
  if (!date) {
    return '';
  }
  if(date.startsWith('1900-01-01')) {
    // Infinite begin
    return "-";
  }
  if(date.startsWith('2900-12-31')) {
    // Infinite end
    return "-";
  }
  return formatDate(DateTime.fromISO(date));
}

export function formatStringDateTime(date?: string): string {
  // console.info('Formatting ' + JSON.stringify(date))
  if (!date) {
    return '';
  }
  if(date.startsWith('1900-01-01')) {
    // Infinite begin
    return "-";
  }
  if(date.startsWith('2900-12-31')) {
    // Infinite end
    return "-";
  }
  return formatDateTime(DateTime.fromISO(date));
}

export function formatDate(date?: DateTime): string{
  if(!date) {
    return '';
  }
  if (date.get('year') === 1900) {
    return '-';
  }
  if (date.get('year') === 2900) {
    return '-';
  }
  return date.toLocaleString();
}

export function formatDateTime(date?: DateTime): string{
  if(!date) {
    return '';
  }
  if (date.get('year') === 1900) {
    return '-';
  }
  if (date.get('year') === 2900) {
    return '-';
  }
  return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}

export function toIsoUriFormat(date: DateTime): string {
  return encodeURIComponent(date.toISO({suppressMilliseconds: true}));
}

export function toIsoFormat(date: DateTime): string {
  if(!date) {
    return ""
  }
  return date.toISO({suppressMilliseconds: true});
}

export function parseIsoFormat(dateTime: string): DateTime {
  return DateTime.fromISO(dateTime);
}

export function toLocalDate(dateIso: string): string {
  return DateTime.fromISO(dateIso).toLocaleString();
}

export function getValidityInfoFrom(validityInfo?: ValidityInfo): DateTime | undefined {
  if(!validityInfo || !validityInfo.validFrom) {
    return undefined;
  }
  return DateTime.fromISO(validityInfo.validFrom)
}
