import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RenaultLogin, renaultLoginSchema } from './RenaultAdapter';
import { FormItem, FormSection, makeRequired, RhfForm } from '../../../shared/components/forms/Forms';
import { CustomTextField } from '../../../shared/components/forms/CustomTextField';

export const MY_RENAULT_FORM_ID = 'MyRenaultForm';

export function MyRenaultLoginForm(props: {
  renaultLogin?: RenaultLogin,
  onSave: (renaultLogin: RenaultLogin) => void;
}) {
  const {t} = useTranslation();
  const form = useForm<RenaultLogin>({
    resolver: yupResolver(renaultLoginSchema()),
    defaultValues: props.renaultLogin || {userName: '', password: ''}
  });
  const required = makeRequired<RenaultLogin>(renaultLoginSchema());

  return (
    <FormProvider {...form}>
      <RhfForm id={MY_RENAULT_FORM_ID} handleSave={props.onSave} form={form}>
        <FormSection>
          <FormItem>
            <FormItem key={'userName'}>
              <CustomTextField
                required={required.userName}
                name={'userName'}
                label={t('RenaultAdapterDialog.userName')}/>
            </FormItem>
          </FormItem>
          <FormItem>
            <FormItem key={'password'}>
              <CustomTextField
                type="password"
                required={required.password}
                name={'password'}
                label={t('RenaultAdapterDialog.password')}
              />
            </FormItem>
          </FormItem>

        </FormSection>
      </RhfForm>
    </FormProvider>
  );
}
