import { AxiosInstance } from 'axios';
import { apiHost } from '../shared/technical/rest/restApi';
import * as yup from 'yup';

export enum FileUsageType {
  BILL = 'BILL'
}

export interface UploadedFile {
  id: number,
  fileName: string,
  type: FileUsageType,
  mediaType: string
}

export function uploadedFileSchema() {
  return yup.object<UploadedFile>({
    id: yup.number(),
    fileName: yup.string(),
    type: yup.string(),
    mediaType: yup.string()
  }).defined()
}

export function uploadFiles(axiosInstance: AxiosInstance, files: File[], fileType: FileUsageType): Promise<UploadedFile[]> {
  const baseUri = apiHost + '/' + 'uploaded-files';
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });
  return axiosInstance.request<UploadedFile[]>({
    url: baseUri + '/' + fileType,
    method: 'POST',
    data: formData,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  }).then(axiosResponse => axiosResponse.data);
}
