import * as React from 'react';
import { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

interface TextFormInputProps<T> {
  id?: string;
  label: string;
  field: string;
  getValue?: () => string | undefined;
  value?: string;
}

export function StaticTextForm<T>(props: TextFormInputProps<T>) {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    let valueFromProps = getValue(props);
    setValue(valueFromProps);
  }, [props]);

  const {id, label, field} = props;
  return (
    <TextField
      id={id ? id : 'input_' + field}
      label={label}
      value={value}
      margin="normal"
      fullWidth={true}
      InputProps={{
        readOnly: true,
      }}
    />
  );
}

function getValue<T>(props: TextFormInputProps<T>): string {
  let value = '';
  if (props.getValue) {
    value = props.getValue() || '';
  } else if (props.value) {
    value = props.value;
  }
  return value;
}
