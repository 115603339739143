import { FormItem, FormSection } from '../shared/components/forms/Forms';
import { CustomSelect } from '../shared/components/forms/CustomSelect';
import { Tariff, TariffProvider } from './TariffProvider';
import { CustomDatePicker } from '../shared/components/forms/CustomDatePicker';
import { dateFormatSmall } from '../shared/technical/dateTime/DateTime';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { useTranslation } from 'react-i18next';
import { getTariffProviders, getTariffsByTariffProvider } from './tariffProviderApi';


export function TariffSubscriptionForm(props: {
  name: string
}) {
  const customAxios = useAxios();
  const {t} = useTranslation();
  const {control} = useFormContext();
  const [tariffProviders, setTariffProviders] = useState<TariffProvider[]>();
  const [tariffs, setTariffs] = useState<Tariff[]>();

  useEffect(() => {
    getTariffProviders(customAxios).then((page) => setTariffProviders(page.content));
  }, [customAxios]);

  const tariffProvider: TariffProvider | undefined = useWatch({control: control, name: props.name + '.tariff.tariffProvider'});
  useEffect(() => {
    if (tariffProvider) {
      getTariffsByTariffProvider(customAxios, tariffProvider!!.id).then(setTariffs);
    }
  }, [customAxios, tariffProvider]);

  if (!tariffProviders) {
    return <></>;
  }

  return (
    <FormSection title={t('TariffSubscription.titleSection')}>
      <FormItem key={'tariffProvider'}>
        <CustomSelect
          name={'activeTariffSubscription.tariff.tariffProvider'}
          label={t('Tariff.tariffProvider')}
          required={false}
          list={tariffProviders}
          getListKey={(entry: TariffProvider) => entry.id}
          getListLabel={(entry: TariffProvider) => entry.name}
          getListValue={(entry: TariffProvider) => entry}
        />
      </FormItem>
      {tariffs &&
        <FormItem key={'tariff'}>
          <CustomSelect
            name={'activeTariffSubscription.tariff'}
            label={t('TariffSubscription.tariff')}
            required={false}
            disabled={tariffs.length === 0}
            list={tariffs}
            getListKey={(entry: Tariff) => entry.id}
            getListLabel={(entry: Tariff) => entry.name}
            getListValue={(entry: Tariff) => entry}
          />
        </FormItem>
      }
      <FormItem key={'validity'}>
        <CustomDatePicker
          label={t('ValidityInfo.validFrom')}
          name={'activeTariffSubscription.validityInfo.validFrom'}
          format={dateFormatSmall(t)}/>
        <CustomDatePicker
          label={t('ValidityInfo.validTo')}
          name={'activeTariffSubscription.validityInfo.validTo'}
          format={dateFormatSmall(t)}/>
      </FormItem>
    </FormSection>
  );
}
