import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { PowerGridPage } from '../../../power/PowerGridPage';
import { Dashboard } from '../../../categories/Dashboard';
import { NewMeteringPointPage } from '../../../meters/NewMeteringPointPage';
import { CategoryDashboardPage } from '../../../meters/CategoryDashboardPage';
import { PvPage } from '../../../pv/PvPage';
import { HeatPumpPage } from '../../../heatPump/HeatPumpPage';
import { EditMeteringPointPage } from '../../../meters/EditMeteringPointPage';
import { ReadingsPage } from '../../../readings/ReadingsPage';
import { NewReadingPage } from '../../../readings/NewReadingPage';
import { EditReadingPage } from '../../../readings/EditReadingPage';
import { ReadingChartPage } from '../../../charts/ReadingChartPage';
import { BillsPage } from '../../../bills/BillsPage';
import { AccountPage } from '../../../user/AccountPage';
import { NewTariffProviderPage } from '../../../provider/NewTariffProviderPage';
import { EditTariffProviderPage } from '../../../provider/EditTariffProviderPage';
import { TariffsPage } from '../../../provider/TariffsPage';
import { EditTariffPage } from '../../../provider/EditTariffPage';
import { NewTariffPage } from '../../../provider/NewTariffPage';
import { TariffProvidersPage } from '../../../provider/TariffProvidersPage';
import { MobilityDashboardPage } from '../../../mobility/MobilityDashboardPage';
import { AccountDeletionPage } from '../../../user/AccountDeletionPage';
import { AxiosProvider } from '../../technical/rest/AxiosProvider';
import { AccountProvider } from '../../../user/AccountProvider';
import { Loading } from '../Loading';
import { UserManagerProvider } from '../../technical/security/UserManagerProvider';
import { UserProvider } from '../../technical/security/UserProvider';
import { RedirectCallback } from '../../technical/security/RedirectCallback';
import { SilentRedirectCallback } from '../../technical/security/SilentRedirectCallback';
import { DrawerProvider } from '../drawer/DrawerProvider';
import { PageFrame } from '../page/Page';
import { AppBarSettingsProvider } from '../appbar/AppBarSettingsProvider';

export const HOME_MAPPING = '/';
export const ACCOUNT_MAPPING = '/account';
export const ACCOUNT_DELETION_MAPPING = '/account/deletion/:token';
export const PV_MAPPING = '/pv';
export const HEAT_PUMP_MAPPING = '/heat-pump';
export const CATEGORIES_MAPPING = '/categories';
export const CATEGORY_MAPPING = '/categories/:categoryId';
export const categoryMapping = (categoryId: string) =>
  CATEGORY_MAPPING.replace(':categoryId', categoryId);
const METERING_POINTS_MAPPING = CATEGORY_MAPPING + '/metering-points';
const METERING_POINT_NEW_MAPPING = CATEGORY_MAPPING + '/metering-point-new';
export const meteringPointNewMapping = (categoryId: string) =>
  METERING_POINT_NEW_MAPPING.replace(':categoryId', categoryId);
const METERING_POINT_EDIT_MAPPING = METERING_POINTS_MAPPING + '/:meteringPointId';
export const meteringPointEditMapping = (categoryId: string, meteringPointId: string) =>
  METERING_POINT_EDIT_MAPPING.replace(':categoryId', categoryId).replace(':meteringPointId', meteringPointId);
export const READINGS_MAPPING = METERING_POINT_EDIT_MAPPING + '/readings';
export const meteringPointReadingsMapping = (meteringPointId: string) =>
  READINGS_MAPPING.replace(':meteringPointId', meteringPointId);
const READING_ADD_MAPPING = READINGS_MAPPING + '/readings-new';
const READING_EDIT_MAPPING = READINGS_MAPPING + '/:readingId';
export const readingEditMapping = (meteringPointId: string, readingId: string) => READING_EDIT_MAPPING.replace(':readingId', readingId).replace(':meteringPointId', meteringPointId);
export const readingAddMapping = (meteringPointId: string) =>
  READING_ADD_MAPPING.replace(':meteringPointId', meteringPointId);
export const READING_CHART_MAPPING = METERING_POINT_EDIT_MAPPING + '/chart';
export const readingChartMapping = (categoryId: string, meteringPointId: string) =>
  READING_CHART_MAPPING.replace(':categoryId', categoryId).replace(':meteringPointId', meteringPointId);
export const UNITS_MAPPING = '/units';
export const UNIT_NEW_MAPPING = '/unit-new';
const UNIT_EDIT_MAPPING = '/units/:unitId';
export const unitEditMapping = (unitId: string) => UNIT_EDIT_MAPPING.replace(':unitId', unitId);
export const TARIFF_PROVIDER_MAPPING = '/tariff-providers';
const TARIFF_PROVIDER_EDIT_MAPPING = '/tariff-providers/:tariffProviderId';
export const tariffProviderEditMapping = (tariffProviderId: string) => TARIFF_PROVIDER_EDIT_MAPPING.replace(':tariffProviderId', tariffProviderId);
export const TARIFF_PROVIDER_NEW_MAPPING = '/tariff-provider-new';
const TARIFF_MAPPING = TARIFF_PROVIDER_EDIT_MAPPING + '/tariffs';
export const tariffsMapping = (tariffProviderId: string) => TARIFF_MAPPING.replace(':tariffProviderId', tariffProviderId);
const TARIFF_NEW_MAPPING = TARIFF_PROVIDER_EDIT_MAPPING + '/tariff-provider-new';
export const tariffNewMapping = (tariffProviderId: string) => TARIFF_NEW_MAPPING.replace(':tariffProviderId', tariffProviderId);
const TARIFF_EDIT_MAPPING = TARIFF_MAPPING + '/:tariffId';
export const tariffEditMapping = (tariffProviderId: string, tariffId: string) =>
  TARIFF_EDIT_MAPPING.replace(':tariffProviderId', tariffProviderId).replace(':tariffId', tariffId);
export const BILLS_MAPPING = METERING_POINT_EDIT_MAPPING + '/bills';
export const meteringPointBillsMapping = (meteringPointId: string) =>
  BILLS_MAPPING.replace(':meteringPointId', meteringPointId);

export const AUTH_REDIRECT_PATH = '/auth/redirect';
export const AUTH_SILENT_REDIRECT_PATH = '/auth/silent-redirect';

export function AppRouter() {
  const userHasElectricitySensors = false;

  return (
    <BrowserRouter>
      <DrawerProvider>
        <AppBarSettingsProvider>
          <UserManagerProvider>
            <Switch>
              <Route path={AUTH_REDIRECT_PATH} exact={true} component={RedirectCallback}/>
              <Route path={AUTH_SILENT_REDIRECT_PATH} exact={true} component={SilentRedirectCallback}/>
              <Route path={'*'}>
                <UserProvider>
                  <AxiosProvider>
                    <AccountProvider>
                      <PageFrame>
                        <Switch>
                          <Route path={HOME_MAPPING} exact={true}>
                            {userHasElectricitySensors &&
                              <PowerGridPage/>
                            }
                            {!userHasElectricitySensors &&
                              <Dashboard/>
                            }
                          </Route>
                          <Route
                            path={METERING_POINT_NEW_MAPPING}
                            exact={true}
                            render={props =>
                              <NewMeteringPointPage
                                {...props}
                                categoryId={props.match.params.categoryId}
                              />}
                          />
                          <Route
                            path={METERING_POINT_EDIT_MAPPING}
                            exact={true}
                            render={props =>
                              <EditMeteringPointPage
                                {...props}
                                meteringPointId={props.match.params.meteringPointId}
                                categoryId={props.match.params.categoryId}
                              />}
                          />
                          <Route
                            path={CATEGORY_MAPPING}
                            exact={true}
                            render={props =>
                              props.match.params.categoryId == 4 ?
                                <MobilityDashboardPage/> :
                                <CategoryDashboardPage
                                  {...props}
                                  categoryId={props.match.params.categoryId}
                                />
                            }
                          />
                          <Route
                            path={PV_MAPPING}
                            exact={true}
                            render={props =>
                              <PvPage/>}
                          />
                          <Route
                            path={HEAT_PUMP_MAPPING}
                            exact={true}
                            render={props =>
                              <HeatPumpPage/>}
                          />
                          <Route
                            path={READINGS_MAPPING}
                            exact={true}
                            render={props =>
                              <ReadingsPage meteringPointId={props.match.params.meteringPointId}/>}
                          />
                          <Route
                            path={READING_ADD_MAPPING}
                            exact={true}
                            render={props =>
                              <NewReadingPage
                                {...props}
                                meteringPointId={props.match.params.meteringPointId}
                              />}
                          />
                          <Route
                            path={READING_EDIT_MAPPING}
                            exact={true}
                            render={props =>
                              <EditReadingPage
                                {...props}
                                meteringPointId={props.match.params.meteringPointId}
                                readingId={props.match.params.readingId}
                              />}
                          />
                          <Route
                            path={READING_CHART_MAPPING}
                            exact={true}
                            render={props =>
                              <ReadingChartPage
                                {...props}
                                meteringPointId={props.match.params.meteringPointId}
                              />}
                          />
                          <Route
                            path={BILLS_MAPPING}
                            exact={true}
                            render={props =>
                              <BillsPage
                                {...props}
                                meteringPointId={props.match.params.meteringPointId}
                              />}
                          />
                          <Route path={ACCOUNT_MAPPING} exact={true} component={AccountPage}/>
                          <Route path={ACCOUNT_DELETION_MAPPING} exact={true}
                                 render={props => <AccountDeletionPage token={props.match.params.token}/>}
                          />
                          <Route
                            path={TARIFF_PROVIDER_MAPPING}
                            exact={true}
                            render={props =>
                              <TariffProvidersPage/>}
                          />
                          <Route
                            path={TARIFF_PROVIDER_EDIT_MAPPING}
                            exact={true}
                            render={props =>
                              <EditTariffProviderPage tariffProviderId={props.match.params.tariffProviderId}/>}
                          />
                          <Route
                            path={TARIFF_PROVIDER_NEW_MAPPING}
                            exact={true}
                            render={props =>
                              <NewTariffProviderPage/>}
                          />
                          <Route
                            path={TARIFF_MAPPING}
                            exact={true}
                            render={props =>
                              <TariffsPage tariffProviderId={props.match.params.tariffProviderId}/>}
                          />
                          <Route
                            path={TARIFF_EDIT_MAPPING}
                            exact={true}
                            render={props =>
                              <EditTariffPage tariffProviderId={props.match.params.tariffProviderId}
                                              tariffId={props.match.params.tariffId}/>}
                          />
                          <Route
                            path={TARIFF_NEW_MAPPING}
                            exact={true}
                            render={props =>
                              <NewTariffPage tariffProviderId={props.match.params.tariffProviderId}/>}
                          />
                          <Route render={(props) => {
                            console.log('Default route. Url: ' + props.location.pathname);
                            return (<> </>);
                          }}/>
                        </Switch>
                      </PageFrame>
                    </AccountProvider>
                  </AxiosProvider>
                </UserProvider>
              </Route>
              <Loading/>
            </Switch>
          </UserManagerProvider>
        </AppBarSettingsProvider>
      </DrawerProvider>
    </BrowserRouter>
  );
}
