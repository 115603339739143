import { UploadedFile, uploadedFileSchema } from '../uploadedFiles/FilesApi';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { Meter } from '../meters/Meter';

export interface Bill {
  id: number,
  version: number,
  date: string
  totalAmount: number,
  startReadingDate?: string,
  startReadingValue?: number,
  endReadingDate?: string,
  endReadingValue?: number,
  billDocuments?: UploadedFile[]
}

export function defaultBill() {
  return {date: DateTime.utc().toISODate(), totalAmount: 0} as Bill;
}

export function billSchema(): yup.SchemaOf<Bill> {
  return yup.object({
    id: yup.number(),
    version: yup.number(),
    date: yup.string().required(),
    totalAmount: yup.number().required(),
    startReadingDate: yup.string(),
    startReadingValue: yup.number(),
    endReadingDate: yup.string(),
    endReadingValue: yup.number(),
    billDocuments: yup.array(uploadedFileSchema())
  }).defined() as unknown as yup.SchemaOf<Bill>;
}
