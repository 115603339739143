import { Magnitude, MAGNITUDES, Unit } from '../units/Unit';
import { MeterReadingType } from '../meters/Meter';
import { EnergyCategory } from '../categories/EnergyCategory';
import * as yup from 'yup';
import { Bill } from '../bills/Bill';

export const METER_TYPE_PV_GENERATED = 'PV_GENERATED';
export const METER_TYPE_HP_POWER = 'HP_POWER';

export class MeterType {
  id: number;
  version: number;
  name: string;
  displayName: string;
  category: EnergyCategory;
  magnitudes?: Magnitude[];
  unit: Unit;
  defaultMeterReadingType: MeterReadingType;
}

export function meterTypeSchema(): yup.SchemaOf<MeterType> {
  return yup.object({
    id: yup.number().defined(),
    version: yup.number().defined(),
    name: yup.string().required(),
    displayName: yup.string().defined(),
    category: yup.mixed<EnergyCategory>().defined(),
    magnitudes: yup.array(yup.mixed<Magnitude>()),
    unit: yup.mixed<Unit>().defined(),
    defaultMeterReadingType: yup.mixed<MeterReadingType>().defined()
  }) as unknown as yup.SchemaOf<MeterType>;
}
