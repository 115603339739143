import { UnitCodes } from './Unit';

export enum Substance {
  ELECTRICAL_ENERGY = 'ELECTRICAL_ENERGY',
  HEAT_ENERGY = 'HEAT_ENERGY',
  WATER = 'WATER',
  OIL = 'OIL',
  PELLET = 'PELLET',
  GAS = 'GAS',
  GASOLINE = 'GASOLINE',
  DISTANCE = 'DISTANCE',
  NONE = 'NONE'
}

export const SUBSTANCES = Object.keys(Substance).map(k => Substance[k]);

export function getUnitForSubstance(substance: Substance): UnitCodes | undefined {
  switch (substance) {
    case Substance.DISTANCE:
      return UnitCodes.METER;
    case Substance.ELECTRICAL_ENERGY:
    case Substance.HEAT_ENERGY:
      return UnitCodes.Wh;
    case Substance.GAS:
    case Substance.WATER:
      return UnitCodes.CUBIC_METER;
    case Substance.OIL:
      return UnitCodes.LITRE;
    case Substance.PELLET:
      return UnitCodes.TON;
  }
  return undefined;
}
