import * as yup from 'yup';
import { EntityRef, entityRefSchema } from '../shared/technical/rest/pageable';
import { DeltaValueOperator } from './Meter';

export interface MeterCalculation {
  id?: string,
  version?: number;
  meteringPointLeft: EntityRef;
  operator: DeltaValueOperator;
  meteringPointRight: EntityRef;
  minValue?: number;
  maxValue?: number;
  useEarliestMeterBegin?: boolean;
}

export function newMeterCalculation() {
  return {} as MeterCalculation;
}

export function meterCalculationSchema(): yup.SchemaOf<MeterCalculation> {
  return yup.object({
    id: yup.string(),
    version: yup.number(),
    meteringPointLeft: entityRefSchema(),
    meteringPointRight: entityRefSchema(),
    operator: yup.mixed<DeltaValueOperator>().required(),
    minValue: yup.number(),
    maxValue: yup.number(),
    useEarliestMeterBegin: yup.boolean()
  });
}
