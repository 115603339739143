import * as React from 'react';
import { useEffect } from 'react';
import { useUserManager } from './UserManagerProvider';
import { log } from '../../../logConfig';
import { useHistory } from 'react-router';
import { HOME_MAPPING } from '../../components/router/AppRouter';
import { captureMessage, Severity } from '@sentry/react';

export function SilentRedirectCallback(props: {}) {
  const {userManager} = useUserManager();
  const history = useHistory();

  useEffect(() => {
    if (userManager) {
      userManager.signinSilentCallback().then((user) => {
        log.debug('Inside Silent Signin Callback: Got user and forwarding to home... user: ' + JSON.stringify(user));
        history.push(HOME_MAPPING);
      }).catch((error) => {
        log.info('Could not retrieve user: ' + JSON.stringify(error));
        captureMessage('SilentRedirectCallback: Could not retrieve user: ' + JSON.stringify(error), Severity.Info);
        userManager.removeUser().then(() => userManager.clearStaleState().then(() => userManager.signinRedirect().then()));
      });
    }
  });

  return (
    <div>
      <h3>Login wird abgeschlossen...</h3>
      <p>Sie werden in wenigen Augenblicken weiter geleitet. Falls nicht, können Sie sich hier auch erneut anmelden:</p>
      <button type="button" onClick={() => {
        userManager?.signinRedirect().then();
      }}>
        Anmelden
      </button>
    </div>
  );
}
