import { queryOne } from '../shared/technical/rest/restApi';
import { DateTime } from 'luxon';
import { DeltaValues } from './DeltaValues';
import { TimeUnit } from '../shared/technical/dateTime/TimeUnit';
import { PlainUnit } from '../units/Unit';
import { AxiosInstance } from 'axios';

export function getDeltaValues(customAxios: AxiosInstance, meteringPointId: string | number, from?: DateTime, to?: DateTime, timeUnit?: TimeUnit, unit?: PlainUnit): Promise<DeltaValues> {
  let params = `meteringPointId=${meteringPointId}`;
  if (from) params = params + `&from=${encodeURIComponent(from.toISO())}`;
  if (to) params = params + `&to=${encodeURIComponent(to.toISO())}`;
  if (timeUnit) params = params + `&timeUnit=${timeUnit}`;
  if (unit) params = params + `&unit=${unit.code}`;
  return queryOne<DeltaValues>(customAxios, 'values', undefined, params);
}
