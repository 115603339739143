import Grid from '@material-ui/core/Grid';
import { FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getMeteringPoints } from '../meters/MeteringPointApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { useTranslation } from 'react-i18next';
import { MeteringPoint } from '../meters/MeteringPoint';

export function ChartAdditionalValuesControl(props: {
  mainMeteringPoint: MeteringPoint,
  onAdditionalMeteringPointsChanged: (additionalMeteringPoints: MeteringPoint[]) => void,
  onShowStackedChanged: (showStacked: boolean) => void
}) {
  const {t} = useTranslation();
  const customAxios = useAxios();
  const [otherMeteringPoints, setOtherMeteringPoints] = useState<MeteringPoint[]>([]);
  const [additionalMeteringPoints, setAdditionalMeteringPoints] = useState<MeteringPoint[]>([]);
  const [showStacked, setShowStacked] = useState(false);
  const { onShowStackedChanged } = props;

  useEffect(() => {
    getMeteringPoints(customAxios).then(result => {
      const meteringPoints = result.filter(curr => curr.id !== props.mainMeteringPoint.id);
      setOtherMeteringPoints(meteringPoints);
    });
  }, [customAxios, props.mainMeteringPoint]);

  useEffect(() => {
    onShowStackedChanged(showStacked);
  }, [showStacked, onShowStackedChanged]);

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12} lg={3}>
        <FormControl error={false} fullWidth={true}>
          <Autocomplete
            value={additionalMeteringPoints}
            disableCloseOnSelect={false}
            multiple={true}
            options={otherMeteringPoints}
            fullWidth={true}
            getOptionLabel={option => option.name}
            onChange={(evt, val) => {
              setAdditionalMeteringPoints(val);
              props.onAdditionalMeteringPointsChanged(val);
            }}
            renderInput={params => <TextField
              {...(params)}
              label={t('ReadingChart.additionalMeteringPoints')}
              variant={'outlined'}
            />}
          />
        </FormControl>
      </Grid>
      <Grid item={true} xs={12} lg={3}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showStacked}
              onChange={() => setShowStacked(curr => !curr)}
              name="showStacked"
            />
          }
          label={t('ReadingChart.showStacked')}
        />
      </Grid>
    </Grid>
  );
}
