import React, { ReactNode, useContext, useEffect, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useUser } from '../security/UserProvider';
import { log } from '../../../logConfig';

export const AxiosContext = React.createContext<AxiosInstance | undefined>(undefined);

export function useAxios(): AxiosInstance {
  return useContext(AxiosContext)!!;
}

export function AxiosProvider(props: {
  children: ReactNode
}) {
  const {user} = useUser();
  const accessToken = user?.access_token;
  const [configured, setConfigured] = useState(false);

  useEffect(() => {
    if (!accessToken) {
      return;
    }
    log.trace('Setting axios token header to ' + accessToken);
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    if (!configured) {
      setConfigured(true);
    }
  }, [accessToken, configured]);

  if (!configured) {
    return (<>Loading...</>);
  }

  return (
    <AxiosContext.Provider value={axios}>
      {props.children}
    </AxiosContext.Provider>
  );
}
