import { DeltaValue, DeltaValues } from '../values/DeltaValues';
import { Magnitude } from '../units/Unit';
import { applyMagnitude, magnitudeFor } from '../units/magnitudeUtils';
import { formatTimeRange } from '../shared/technical/dateTime/DateTimeRange';
import { timeUnitToTimeRangeFormat } from '../shared/technical/dateTime/TimeUnit';

export const valuesChartDataField = 'values';
export const mainValueChartDataField = 'value';

export type ChartData = {
  period: string;
  values: Record<string, number>;
}

export function mapDeltaValue(deltaValue: DeltaValue, name: string, magnitude: Magnitude, timeRangeFormat: string) {
  const value = applyMagnitude(magnitude, deltaValue.value);
  let period = formatTimeRange(timeRangeFormat, deltaValue.range);
  let result = {period: period, values: {}};
  result.values[name] = (value < 1) ? value : parseFloat(value.toFixed(1));
  return result;
}

function mapDeltaValues(mainList: DeltaValues) {
  const timeRangeFormat = timeUnitToTimeRangeFormat(mainList.timeUnit);
  let maxValue = mainList.values
    .map((deltaValue) => deltaValue.value)
    .reduce((previousValue, currentValue) => Math.max(previousValue, currentValue), 0);
  const magnitude = magnitudeFor(maxValue);
  const chartData = mainList.values.map(deltaValue => {
    return mapDeltaValue(deltaValue, 'value', magnitude, timeRangeFormat);
  });
  return chartData;
}

export function mapDeltaValuesToChartData(mainList: DeltaValues, additionalValuesList: DeltaValues[], magnitude: Magnitude): ChartData[] {
  const chartData = mapDeltaValues(mainList);
  const combined = chartData.map((chartDataEntry, chartDataIndex) => {
    additionalValuesList && additionalValuesList.forEach((additionalValues, additionalValuesIndex) => {
      const deltaValue = additionalValues.values[chartDataIndex];
      if (!deltaValue) {
        return;
      }
      const value = applyMagnitude(magnitude, deltaValue.value);
      const floatValue = parseFloat(value.toFixed(1));
      chartDataEntry.values['' + additionalValuesIndex] = floatValue;
    });
    return chartDataEntry;
  });
  return combined;
}

export function getDefaultMagnitude(deltaValues: DeltaValues): Magnitude {
  let maxValue = deltaValues.values
    .map((deltaValue) => deltaValue.value)
    .reduce((previousValue, currentValue) => Math.max(previousValue, currentValue), 0);
  return magnitudeFor(maxValue);
}
