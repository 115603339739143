export const ERROR_DIFFERS_TOO_MUCH = 'DiffersTooMuch';
export const READING_NOT_IN_LINE = 'ReadingNotInLine';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const ACCOUNT_TEMPORARILY_LOCKED = 'ACCOUNT_TEMPORARILY_LOCKED';

export interface ValidationError {
  field: string;
  defaultMessage: string;
  code: string;
}

export class ValidationErrorResponse {
  error?: string;
  exception?: string;
  message?: string;
  errors: ValidationError[];
}

export function extractError(errors: ValidationError[], field?: string): ValidationError | null {
  if (!field) {
    return null;
  }
  let filtered = errors.filter((error) => error.field === field);
  if (filtered.length > 0) {
    return filtered[0];
  } else {
    return null;
  }
}

export function errorToString(validationError: ValidationError | null) {
  if (validationError === null) {
    return '';
  }
  switch (validationError.code) {
    case 'NotNull':
      return 'Darf nicht leer sein.';
    case ERROR_DIFFERS_TOO_MUCH:
      return 'Warnung: Der eingegebene Wert weicht deutlich vom vorausberechneten Wert ab. Bitte überprüfen.';
    case READING_NOT_IN_LINE:
      return 'Warnung: Der eingegebene Wert ist nicht aufsteigend oder absteigend wie die Werte davor.' +
        ' Eventuell wurde vergessen einen Zählerwechsel durch zu führen. Bitte überprüfen.';
    case LOGIN_FAILED:
      return 'Login fehlgeschlagen! Benutzername oder Passwort falsch.'
    case ACCOUNT_TEMPORARILY_LOCKED:
      return 'Account temporär gesperrt. Bitte versuchen Sie es später nochmal.'
    default:
      return validationError.defaultMessage;
  }
}
