import { User } from './user';
import { deleteOne, queryOne } from '../shared/technical/rest/restApi';
import { AxiosInstance } from 'axios';

export function getAccount(customAxios: AxiosInstance): Promise<User> {
  return queryOne<User>(customAxios, 'account');
}

export function markAccountForDeletion(customAxios: AxiosInstance): Promise<void> {
  return deleteOne<User>(customAxios, 'account');
}

export function deleteAccount(customAxios: AxiosInstance, token: string): Promise<void> {
  return deleteOne<User>(customAxios, 'account/' + token);
}
