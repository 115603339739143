import * as yup from 'yup';

export interface PagedResult<T> {
  number: number;
  totalElements: number;
  totalPages: number;
  content: T[];
}

export interface Pageable {
  /** page number, 0 based */
  page: number;
  /** size of page */
  size: number;
  sort?: Sort;
  search?: string;
}

export interface Sort {
  field: string;
  order: SortOrder;
}

export type SortOrder = 'asc' | 'desc';

export interface EntityRef {
  id: string;
}

export function entityRefSchema(): yup.SchemaOf<EntityRef> {
  return yup.object({
    id: yup.string().defined()
  }).defined();
}

export function emptyPage(): PagedResult<never> {
  return {
    number: 0,
    totalElements: 0,
    totalPages: 0,
    content: [],
  };
}

export function defaultPageable(): Pageable {
  return {
    page: 0,
    size: 10,
  };
}

export function singlePageable(): Pageable {
  return {
    page: 0,
    size: 1,
  };
}

export function fullPageable(): Pageable {
  return {
    page: 0,
    size: 10000,
  };
}
