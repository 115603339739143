import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ReadingsBarChart } from './ReadingsBarChart';
import { getDeltaValues } from '../values/DeltaValuesApi';
import { prevTimeUnit } from '../shared/technical/dateTime/TimeUnit';
import Grid from '@material-ui/core/Grid';
import { DeltaValues } from '../values/DeltaValues';
import { dateRangeBegin, dateRangeEnd, TimeRange } from '../shared/technical/dateTime/DateTimeRange';
import { readingChartMapping } from '../shared/components/router/AppRouter';
import { log } from '../logConfig';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getMeteringPoint } from '../meters/MeteringPointApi';
import { Loading } from '../shared/components/Loading';
import { ChartParams, isEqual, parseUriParam } from './ChartParamParser';
import * as H from 'history';
import { ChartTimeControls } from './ChartTimeControls';
import { ChartBottomControls } from './ChartBottomControls';
import { ChartAdditionalValuesControl } from './ChartAdditionalValuesControl';
import { MeteringPoint } from '../meters/MeteringPoint';
import { useTranslation } from 'react-i18next';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function createChartUri(newParams: ChartParams, chartUri) {
  if (newParams.from) chartUri = chartUri + 'from=' + encodeURIComponent(newParams.from.toISO()) + '&';
  if (newParams.to) chartUri = chartUri + 'to=' + encodeURIComponent(newParams.to.toISO()) + '&';
  if (newParams.resolution) chartUri = chartUri + 'resolution=' + newParams.resolution + '&';
  if (newParams.unit) chartUri = chartUri + 'unit=' + newParams.unit.code + '&';
  return chartUri;
}

export function updateChartPageUri(history: H.History, newParams: ChartParams, chartUri) {
  log.debug('from, to, resolution, unit changed!');
  chartUri = createChartUri(newParams, chartUri);
  history.push(chartUri);
}

export type Props = {
  meteringPointId: string;
};

export function ReadingChartPage(props: Props) {
  const location = useLocation();
  const history = useHistory();
  const {t} = useTranslation();
  const {setTitle} = useAppBarSetting();
  const customAxios = useAxios();
  const [mainValues, setMainValues] = useState<DeltaValues>();
  const [additionalValues, setAdditionalValues] = useState<DeltaValues[]>([]);
  const [params, setParams] = useState<ChartParams>({});
  const [mainMeteringPoint, setMainMeteringPoint] = useState<MeteringPoint>();
  const [additionalMeteringPoints, setAdditionalMeteringPoints] = useState<MeteringPoint[]>([]);
  const [showStacked, setShowStacked] = useState(false);

  let chartUri = mainMeteringPoint ? readingChartMapping(mainMeteringPoint.type.category.id.toString(), mainMeteringPoint.id) + '?' : '';
  const newParams = parseUriParam(location.search);
  const newParamsLoading = !isEqual(newParams, params);

  useEffect(() => {
    if (newParamsLoading) {
      log.debug('New params from location search string: ' + location.search);
      setParams(newParams);
    }
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (newParamsLoading) {
      return;
    }
    getMeteringPoint(customAxios, props.meteringPointId)
      .then(meteringPoint => {
        log.debug('Got main metering point!');
        setMainMeteringPoint(meteringPoint);
        setTitle(t('ReadingChart.title', {name: meteringPoint.name}));
      });
  }, [props.meteringPointId, customAxios, setMainMeteringPoint, newParamsLoading, setTitle, t]);

  useEffect(() => {
    if (newParamsLoading || !mainMeteringPoint) {
      log.debug('Not loading delta values, ' + (newParamsLoading ? 'newParamsLoading' : '') + ' ' + (!!mainMeteringPoint ? 'mainMeteringPoint' : ''));
      return;
    }
    log.debug('Getting deltaValues...');
    getDeltaValues(customAxios, props.meteringPointId, params.from, params.to, params.resolution, params.unit)
      .then(deltaValues => {
        deltaValues.name = mainMeteringPoint?.name || '';
        setMainValues(deltaValues);
      });
  }, [props.meteringPointId, mainMeteringPoint, params, customAxios, newParamsLoading]);

  useEffect(() => {
    log.debug('additionalMeteringPoints changed => loading delta values');
    if (additionalMeteringPoints.length === 0) {
      if (additionalValues && additionalValues.length > 0) {
        setAdditionalValues([]);
      } else {
        log.debug('additionalMeteringPoints still empty...');
      }
      return;
    }
    if (!mainValues || !mainValues.values || mainValues.values.length === 0) {
      log.debug('no main values available...');
      return;
    }

    const timeUnit = mainValues!!.timeUnit;
    const startRange = mainValues.values[0].range as TimeRange;
    const endRange = mainValues.values[mainValues.values.length - 1].range as TimeRange;
    const start = dateRangeBegin(startRange);
    const end = dateRangeEnd(endRange);

    Promise.all(additionalMeteringPoints.map((meteringPoint, index) => {
      return getDeltaValues(customAxios, meteringPoint.id, start, end, timeUnit, params.unit)
        .then(deltaValues => {
          deltaValues.name = meteringPoint.name;
          return deltaValues;
        });
    })).then(receivedDeltaValues => {
      log.debug('Setting deltaValues');
      setAdditionalValues(receivedDeltaValues);
    });
  }, [additionalMeteringPoints, additionalMeteringPoints.length, mainValues, additionalValues, customAxios, params]);

  if (!mainMeteringPoint || !mainValues || newParamsLoading) {
    return (
      <Loading/>
    );
  }

  return (
    <>
      <ChartTimeControls meteringPoint={mainMeteringPoint} params={params}/>
      <ReadingsBarChart
        mainValues={mainValues}
        meteringPoint={mainMeteringPoint}
        additionalValues={additionalValues}
        resolution={params.resolution}
        unit={params.unit}
        showGrid={true}
        showStacked={showStacked}
        handleChartClick={(value) => {
          log.info('Value: ' + JSON.stringify(value));
          let range = value.range as TimeRange;
          let start = dateRangeBegin(range);
          let end = dateRangeEnd(range);
          let resolution = prevTimeUnit(range.timeUnit);
          updateChartPageUri(history, {...params, from: start, to: end, resolution: resolution}, chartUri);
        }}
      />
      <Grid container justify={'space-between'}>
        <ChartBottomControls deltaValues={mainValues} params={params} meteringPoint={mainMeteringPoint}/>
      </Grid>
      <ChartAdditionalValuesControl
        mainMeteringPoint={mainMeteringPoint}
        onAdditionalMeteringPointsChanged={setAdditionalMeteringPoints}
        onShowStackedChanged={setShowStacked}
      />
    </>
  );
}

