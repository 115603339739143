import { formatNumber, formatReading, Reading } from './Reading';
import * as React from 'react';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';
import * as H from 'history';
import { TFunction } from 'i18next';
import { log } from '../logConfig';
import { saveReading } from './ReadingApi';
import { AxiosInstance } from 'axios';
import { getForecast } from '../meters/MeteringPointApi';
import { MeteringPoint } from '../meters/MeteringPoint';

export function saveButtonPressed(customAxios: AxiosInstance, reading: Reading, history: H.History) {
  saveReading(customAxios, reading).then(
    (response) => {
      if ((response as ValidationErrorResponse).errors) {
        log.error('Could not save reading: ' + JSON.stringify(response), null);
        // Hmm...
      } else {
        history.goBack();
      }
    },
    (reason) => {
      log.error(reason, null);
    });
}

export function handleReadDateChanges(customAxios: AxiosInstance, readDate: string, meteringPointId: string, setForecast: React.Dispatch<number>) {
  log.info('ReadDate changed: ' + readDate);
  getForecast(customAxios, meteringPointId, readDate).then(newForecast => {
    setForecast(newForecast.value);
  });
}

export function handleValueOrForecastChange(value: number,
                                            meteringPoint: MeteringPoint,
                                            forecast: number,
                                            setWarning: React.Dispatch<string>,
                                            t: TFunction) {
  log.info('Value: ' + value);
  log.info('Forecast: ' + forecast);
  if (forecast > 0) {
    let errorPercentile = Math.abs(forecast - value) / forecast;
    if (errorPercentile > 0.9) {
      log.debug('Value differs way too much! Error %: ' + errorPercentile);
      let forecastStr = formatNumber(forecast, meteringPoint.magnitude, meteringPoint.unit.code);
      let actualStr = formatReading({value: value, meteringPoint: meteringPoint} as Reading);
      let message = t('Reading.warningDifferWayTooMuch', {actual: actualStr, forecast: forecastStr});
      setWarning(message);
      // setErrors([{field: 'value', defaultMessage: message, code: 'CUSTOM'} as ValidationError])
    } else if (errorPercentile > 0.3) {
      log.debug('Value differs too much! Error %: ' + errorPercentile);
      let forecastStr = formatNumber(forecast, meteringPoint.magnitude, meteringPoint.unit.code);
      let actualStr = formatReading({value: value, meteringPoint: meteringPoint} as Reading);
      setWarning(t('Reading.warningDifferTooMuch', {actual: actualStr, forecast: forecastStr}));
    } else {
      setWarning('');
    }
  }
}
