import { parse, ParsedQuery } from 'query-string';
import { parseIsoFormat } from '../shared/technical/dateTime/DateTime';
import { TimeUnit } from '../shared/technical/dateTime/TimeUnit';
import { PlainUnit } from '../units/Unit';
import { DateTime } from 'luxon';
import { log } from '../logConfig';

function parseFrom(parsed: ParsedQuery) {
  const parsedFrom = parsed['from'];
  return parsedFrom ? parseIsoFormat(parsedFrom.toString()) : undefined;
}

function parseTo(parsed: ParsedQuery) {
  const parsedTo = parsed['to'];
  return parsedTo ? parseIsoFormat(parsedTo.toString()) : undefined;
}

function parseResolution(parsed: ParsedQuery) {
  const parsedResolution = parsed['resolution'];
  return parsedResolution ? parsedResolution as TimeUnit : undefined;
}

function parseUnit(parsedQuery: ParsedQuery) {
  const parsed = parsedQuery['unit'];
  return (parsed && !(parsed === 'undefined')) ? {code: parsed} as PlainUnit : undefined;
}

export interface ChartParams {
  from?: DateTime
  to?: DateTime
  resolution?: TimeUnit
  unit?: PlainUnit
}

export function isEqual(param1: ChartParams, param2?: ChartParams) {
  if (!param2) {
    return false;
  }
  log.trace('Checking equality of:');
  // log.trace(JSON.stringify(param1));
  // log.trace(JSON.stringify(param2));
  const resolutionEquals = param1.resolution === param2.resolution;
  const fromEquals = param1.from?.toSeconds() === param2.from?.toSeconds();
  const toEquals = param1.to?.toSeconds() === param2.to?.toSeconds();
  const unitEquals = param1.unit?.code === param2.unit?.code;
  const result = resolutionEquals && fromEquals && toEquals && unitEquals;
  log.trace(result ? 'Equal!' : 'Not Equal');
  return result;
}

export function parseUriParam(search: string): ChartParams {
  const parsed = parse(search);
  return {
    from: parseFrom(parsed),
    to: parseTo(parsed),
    resolution: parseResolution(parsed),
    unit: parseUnit(parsed)
  };
}
