import TextField from '@material-ui/core/TextField';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { InputProps as StandardInputProps } from '@material-ui/core/Input/Input';
import { retrieveError } from './Forms';

export function CustomTextField(props: {
  name: string,
  unit?: string;
} & Partial<Omit<TextFieldProps, 'onChange' | 'value' | 'name'>>) {
  const {name, unit, ...rest} = props;
  const {control, formState: {errors}} = useFormContext<any>();
  const errorMessage = retrieveError(name, errors);

  let additionalProps: Partial<StandardInputProps> | undefined = undefined;
  if (unit) {
    additionalProps = {
      endAdornment:
        <InputAdornment position="end">{unit}</InputAdornment>
    };
  }

  return (
    <Controller
      name={name}
      control={control}
      render={renderProps =>
        <TextField
          name={renderProps.field.name}
          onChange={renderProps.field.onChange}
          onBlur={renderProps.field.onBlur}
          value={renderProps.field.value || ''}
          error={!!errorMessage}
          helperText={errorMessage}
          InputProps={additionalProps}
          {...rest}
        />
      }
    />
  );
}
