import { Theme } from '@material-ui/core/styles';
import common from '@material-ui/core/colors/common';
import { ACCENT_COLOR, PRIMARY_COLOR } from './appTheme';
import { red } from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles } from '@material-ui/core';

export const useAppStyle = makeStyles((theme: Theme) => createStyles({
  formContainer: {},
  formFieldsContainer: {
    padding: theme.spacing(),
  },
  formItem: {
    padding: theme.spacing(),
  },
  fabButton: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    }
  },
  table: {
    minWidth: 70,
  },
  primaryColored: {
    background: PRIMARY_COLOR
  },
  accentColored: {
    color: ACCENT_COLOR
  },
  alertColored: {
    color: red['500']
  },
  whiteColored: {
    color: common.white,
  },
  pullRight: {
    right: 0
  }
}));
