import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { FormControl, FormHelperText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Input, { InputProps } from '@material-ui/core/Input/Input';
import { applyMagnitude, undoMagnitude } from '../../../units/magnitudeUtils';
import { Magnitude } from '../../../units/Unit';
import { retrieveError } from './Forms';

export function CustomNumberField(props: {
  name: string,
  label: string,
  unit?: string;
  magnitude?: Magnitude;
} & Partial<Omit<InputProps, 'onChange' | 'value' | 'name'>>) {
  const {name, label, unit, magnitude, ...rest} = props;
  const {control, formState: {errors}} = useFormContext<any>();
  const errorMessage = retrieveError(name, errors);

  let additionalProps = {};
  if (unit) {
    additionalProps = {
      endAdornment:
        <InputAdornment position="end">{unit}</InputAdornment>
    };
  }

  function getValueAndUndoMagnitude(event): number {
    let value = 0;
    if (event.currentTarget.value && event.currentTarget.value.length > 0) {
      value = event.currentTarget.valueAsNumber;
    }
    if (magnitude) {
      value = undoMagnitude(magnitude, value);
    }
    return value;
  }

  function getValueAndApplyMagnitude(value: number) {
    if (magnitude) {
      return applyMagnitude(magnitude, value);
    } else {
      return value || 0;
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={renderProps =>
        <FormControl error={!!errorMessage} fullWidth={true}>
          <InputLabel htmlFor={'input_' + name}>{label}</InputLabel>
          <Input
            type={'number'}
            name={renderProps.field.name}
            onChange={(event) => renderProps.field.onChange(getValueAndUndoMagnitude(event))}
            onBlur={renderProps.field.onBlur}
            value={getValueAndApplyMagnitude(renderProps.field.value)}
            {...additionalProps}
            {...rest}
          />
          <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
      }
    />
  );
}
