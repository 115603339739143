import React, { useEffect } from 'react';
import { Button, Snackbar } from '@material-ui/core';
import { register } from '../../../serviceWorkerRegistration';
import { useTranslation } from 'react-i18next';

export function ServiceWorkerWrapper() {
  const {t} = useTranslation();

  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    register({onUpdate: onSWUpdate});
  }, []);

  const reloadPage = () => {
    waitingWorker?.postMessage({type: 'SKIP_WAITING'});
    setShowReload(false);
    window.location.reload();
  };

  return (
    <Snackbar
      open={showReload}
      message={t('common.newVersionAvailable')}
      onClick={reloadPage}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      action={
        <Button
          color="inherit"
          size="small"
          onClick={reloadPage}
        >
          {t('actions.reload')}
        </Button>
      }
    />
  );
}
