import { OpenState, submitForm } from '../shared/components/forms/Forms';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ValidationError, ValidationErrorResponse } from '../shared/technical/rest/validation';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Bill } from './Bill';
import { createBill, updateBill } from './BillApi';
import { useTranslation } from 'react-i18next';
import { FileUsageType, UploadedFile, uploadFiles } from '../uploadedFiles/FilesApi';
import { DropzoneArea } from 'material-ui-dropzone';
import { BILL_FORM_ID, EditBillForm } from './EditBillForm';
import { getMeteringPoint } from '../meters/MeteringPointApi';
import { Loading } from '../shared/components/Loading';
import { log } from '../logConfig';
import { MeteringPoint } from '../meters/MeteringPoint';

export function BillFormDialog(props: {
  meteringPointId: string,
  bill: Bill
} & OpenState) {
  const customAxios = useAxios();
  const {t} = useTranslation();

  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [meteringPoint, setMeteringPoint] = useState<MeteringPoint>();
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);

  useEffect(() => {
    getMeteringPoint(customAxios, props.meteringPointId).then(setMeteringPoint);
  }, [props.meteringPointId, customAxios]);

  function handleSave(bill: Bill) {
    saveFiles(files).then((files) => {
        let promise = saveBill(bill, files);
        return promise.then((response) => {
          setSaveButtonEnabled(true);
          if ((response as ValidationErrorResponse).errors) {
            setErrors((response as ValidationErrorResponse).errors);
          } else {
            props.close();
          }
        });
      }
    );
  }

  function saveBill(bill: Bill, files: UploadedFile[]) {
    const billDocuments: UploadedFile[] = [];
    billDocuments.push(...files);
    bill.billDocuments && billDocuments.push(...bill.billDocuments);
    const updatedBill = {...bill!!, date: bill.date.toString().substring(0, 10), billDocuments: billDocuments};
    let promise;
    if (bill.id) {
      promise = updateBill(customAxios, props.meteringPointId, bill.id, updatedBill);
    } else {
      promise = createBill(customAxios, props.meteringPointId, updatedBill);
    }
    return promise;
  }

  function saveFiles(files: File[]) {
    if (!files || files.length === 0) {
      log.debug('No files to save');
      return Promise.resolve([]);
    }
    log.debug('Saving files: ' + files);
    setFiles(files);
    return uploadFiles(customAxios, files, FileUsageType.BILL);
  }

  function handleClose() {
    log.debug('' + errors);
    props.close();
  }

  if (!meteringPoint) {
    return <Loading/>;
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t('Bill.title')} - {meteringPoint.name}</DialogTitle>
      <DialogContent>
        <EditBillForm meteringPoint={meteringPoint} bill={props.bill} onSave={handleSave}/>
        <DropzoneArea
          onChange={setFiles}
          dropzoneText={t('BillsPage.uploadDialogDropzoneText')}
          acceptedFiles={['image/jpeg', 'image/png', 'application/pdf']}
          showPreviews={true}
          maxFileSize={5000000}
        />
        {props.bill.billDocuments?.map((uploadedFile) => {
          return (
            <div key={uploadedFile.id}>{uploadedFile.fileName}</div>
          );
        })
        }
      </DialogContent>
      <DialogActions>
        <Button id="cancel_button" onClick={() => handleClose()} color="primary">
          {t('actions.cancel')}
        </Button>
        <Button id="save_button" disabled={!saveButtonEnabled} onClick={() => {
          setSaveButtonEnabled(false);
          submitForm(BILL_FORM_ID);
        }} color="primary">
          {t('actions.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
