import { ConcreteValueReading, DeltaValueReading, formatNumber } from './Reading';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { readingEditMapping } from '../shared/components/router/AppRouter';
import { defaultPageable, fullPageable, PagedResult } from '../shared/technical/rest/pageable';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { MeterDataSource, MeterReadingType } from '../meters/Meter';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { deleteReading, getReadingsOfMeter } from './ReadingApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { Loading } from '../shared/components/Loading';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { formatStringDateTime } from '../shared/technical/dateTime/DateTime';
import { muiDataTablesLabels } from '../shared/components/tables/muiDataTableUtils';
import { MeteringPoint } from '../meters/MeteringPoint';

export function ReadingsTable(props: {
  meteringPoint: MeteringPoint;
  showActions: boolean;
}) {
  const customAxios = useAxios();
  const history = useHistory();
  const {t} = useTranslation();
  const theme = useTheme();
  const biggerScreens = useMediaQuery(theme.breakpoints.up('md'));
  const [readings, setReadings] = useState<PagedResult<ConcreteValueReading> | PagedResult<DeltaValueReading>>();
  const [pageable, setPageable] = useState(defaultPageable());

  const meteringPoint = props.meteringPoint;
  const meterReadingType = meteringPoint.activeMeter.meterReadingType;
  const meterDataSource = meteringPoint.activeMeter.dataSource;

  useEffect(() => {
    getReadingsOfMeter(customAxios, pageable, props.meteringPoint).then(setReadings);
  }, [props.meteringPoint, customAxios, pageable]);

  const ActionCell = useCallback((dataIndex: number, rowIndex: number) => {
    const reading = readings?.content[rowIndex];
    if (!reading) {
      return (<></>);
    }
    return (
      <>
        <IconButton
          size={biggerScreens ? 'medium' : 'small'}
          aria-label={t('actions.edit')}
          onClick={() => history.push(readingEditMapping(reading.meteringPoint.id, reading.id + ''))}
        >
          <EditIcon/>
        </IconButton>
        <IconButton
          size={biggerScreens ? 'medium' : 'small'}
          aria-label={t('actions.delete')}
          onClick={() => {
            if (!window.confirm(`Soll die Messung vom ${reading.readDate} wirklich gelöscht werden?`)) {
              return;
            }
            deleteReading(customAxios, reading).then(() =>
              getReadingsOfMeter(customAxios, fullPageable(), props.meteringPoint).then(setReadings));
          }}
        >
          <DeleteIcon/>
        </IconButton>
      </>
    );
  }, [history, readings, customAxios, biggerScreens, t, props.meteringPoint]);


  if (!readings) {
    return (
      <Loading/>
    );
  }

  let columns: MUIDataTableColumnDef[] = [];
  if (meterReadingType === MeterReadingType.COUNTER_VALUES ||
    meterReadingType === MeterReadingType.CONCRETE_VALUES ||
    meterDataSource === MeterDataSource.MQTT) {
    columns = [
      {
        label: t('Reading.readDate'),
        name: 'readDate',
        options: {customBodyRender: (date) => formatStringDateTime(date)}
      },
      {
        label: t('Reading.value'),
        name: 'value',
        options: {customBodyRender: (value) => formatNumber(value, meteringPoint.magnitude, meteringPoint.unit.code)}
      }
    ];
  }

  if (meterReadingType === MeterReadingType.DELTA_VALUES || meterReadingType === MeterReadingType.CALCULATED_VALUES) {
    columns = [
      {
        label: t('DateTimeRange.firstDateTime'),
        name: 'dateRange',
        options: {
          customBodyRender: (dateRange, tableMeta) => {
            return formatStringDateTime(dateRange.firstDateTime);
          }
        }
      },
      {
        label: t('DateTimeRange.lastDateTime'),
        name: 'dateRange',
        options: {customBodyRender: (date) => formatStringDateTime(date.lastDateTime)}
      },
      {
        label: t('Reading.value'),
        name: 'value',
        options: {customBodyRender: (value) => formatNumber(value, meteringPoint.magnitude, meteringPoint.unit.code)}
      }
    ];
  }
  if (props.showActions) {
    columns.push(
      {
        label: t('actions.title'),
        name: 'id',
        options: {customBodyRenderLite: ActionCell, filter: false, sort: false, print: false}
      }
    );
  }


  return (
    <MUIDataTable
      title={t('ReadingsPage.title')}
      columns={columns}
      data={readings.content}
      options={{
        download: false,
        sort: false,
        selectableRows: 'none',
        print: false,
        responsive: 'standard',
        pagination: true,
        serverSide: true,
        count: readings.totalElements,
        onChangePage: (currentPage: number) => setPageable((old) => ({...old, page: currentPage})),
        onChangeRowsPerPage: (numberOfRows: number) => setPageable((old) => ({...old, size: numberOfRows})),
        textLabels: muiDataTablesLabels(t)
      }}
    />
  );
}
