import { createOne, queryOne, queryPage, updateOne } from '../shared/technical/rest/restApi';
import { AxiosInstance } from 'axios';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';
import { Unit } from './Unit';
import { fullPageable, PagedResult } from '../shared/technical/rest/pageable';

export function getUnits(customAxios: AxiosInstance): Promise<PagedResult<Unit>> {
  return queryPage<Unit>(customAxios, 'units', fullPageable());
}

export function getUnit(customAxios: AxiosInstance, unitId: string | number): Promise<Unit> {
  return queryOne<Unit>(customAxios, 'units', unitId);
}

export function saveUnit(customAxios: AxiosInstance, unit: Unit): Promise<Unit | ValidationErrorResponse> {
  if (unit.id) {
    return updateOne<Unit>(customAxios, 'units', unit);
  } else {
    return createOne<Unit>(customAxios, 'units', unit);
  }
}
