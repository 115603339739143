import * as React from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      marginLeft: theme.spacing(2),
      marginRight: 'auto',
      background: 'red'
    }
  };
});

export function DeleteButton(props: {
  onClick: () => void
}) {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <Button
      key={'delete_button'}
      id={'delete_button'}
      variant={'contained'}
      size="medium"
      aria-label={t('actions.delete')}
      className={classes.button}
      onClick={props.onClick}
    >
      {t('actions.delete')}
    </Button>
  );
}
