import * as React from 'react';
import { ReactNode, useState } from 'react';
import * as yup from 'yup';
import { createStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import * as lodash from 'lodash';
import { FieldErrors, FormProvider, UseFormReturn } from 'react-hook-form';

export const useFormStyle = makeStyles((theme: Theme) => createStyles({
  formContainer: {},
  formFieldsContainer: {
    padding: theme.spacing(1),
  },
  formSection: {
    padding: theme.spacing(1),
  },
  formItem: {
    paddingTop: theme.spacing(2),
  },
  sectionHeader: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  fullWidth: {
    width: '100%',
  }
}));

export interface DirtyState {
  dirty: boolean;
}

export interface OpenState {
  open: boolean;
  close: () => void;
}

export function RhfForm<T>(props: {
  id?: string,
  form: UseFormReturn<T>,
  handleSave: (T) => void,
  children?: ReactNode
}) {
  const classes = useFormStyle();
  return (
      <form id={props.id}
            onSubmit={props.form.handleSubmit((records) => props.handleSave(records), handleFormError)}
            noValidate={true} autoComplete="off">
        <Grid container={true} spacing={0} className={classes.formContainer}>
          {props.children}
        </Grid>
      </form>
  );
}

export function FormSection(props: {
  title?: string,
  fullWidth?: boolean,
  children?: ReactNode
}) {
  const classes = useFormStyle();

  return (
    <Grid item={true} xs={12} md={props.fullWidth ? 12 : 6} className={classes.formSection}>
      <Paper>
        <Grid container={true} spacing={0} className={classes.formFieldsContainer}>
          {props.title &&
            <Grid item={true} xs={12}>
              <Typography variant={'caption'} color={'primary'}>{props.title}</Typography>
            </Grid>
          }
          {props.children}
        </Grid>
      </Paper>
    </Grid>
  );
}

export function CollapsibleFormSection(props: {
  id?: string,
  title?: string,
  fullWidth?: boolean,
  initiallyExpanded?: boolean,
  children?: ReactNode
}) {
  const classes = useFormStyle();
  const [expanded, setExpanded] = useState(props.initiallyExpanded);

  return (
    <Grid item={true} xs={12} md={props.fullWidth ? 12 : 6} className={classes!!.formItem}>
      <Paper>
        <Accordion expanded={expanded} onChange={() => setExpanded(oldExpandend => !oldExpandend)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="section-content"
            id={props.id ? props.id : 'section-header'}
          >
            <Typography className={classes.sectionHeader}>{props.title}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{display: 'block'}}>
            {props.children}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Grid>
  );
}

export function FormItem(props: {
  fullWidth?: boolean,
  hidden?: boolean,
  children?: ReactNode
}) {
  const classes = useFormStyle();
  const style = props.fullWidth ? {width: '100%'} : {};
  return (
    <Grid item={true} xs={12} style={{...style}} hidden={props.hidden}
          className={classes.formItem + ' ' + (props.fullWidth ? classes.fullWidth : '')}>
      {props.children}
    </Grid>
  );
}

export const useButtonBarStyle = makeStyles((theme: Theme) => createStyles({
  buttonBar: {
    padding: theme.spacing(),
    position: 'relative',
  }
}));

export function ButtonBar(props: {
  children?: ReactNode
}) {
  const classes = useButtonBarStyle();
  return (
    <Grid
      container={true}
      alignItems={'flex-end'}
      justify={'flex-end'}
      direction={'row'}
      spacing={1}
      className={classes.buttonBar}
    >
      {React.Children.map(props.children, child => {
        return (
          <Grid item={true}>{child}</Grid>
        );
      })
      }
    </Grid>
  );
}

/**
 * Uses the private _exclusive field in the schema to get whether or not
 * the field is marked as required or not.
 */
export function makeRequired<T>(schema: yup.SchemaOf<T>) {
  const fields = (schema as any).fields;
  return Object.keys(fields).reduce((accu, field) => {
    if (fields[field].fields) {
      accu[field] = makeRequired(fields[field]);
    } else {
      let exclusiveTests = fields[field]?.exclusiveTests;
      accu[field] = exclusiveTests?.required || exclusiveTests?.defined;
    }
    return accu;
  }, {} as any);
}

export function retrieveError(name: string, errors?: FieldErrors<any>): string | undefined {
  // log.info('Retrieving error for ' + name + " from " + JSON.stringify(errors));
  return lodash.get(errors, name)?.message;
}

export function submitForm(formId: string) {
  const element = window?.document?.getElementById(formId);
  element?.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
}

export function handleFormError(errors: FieldErrors<any>) {
  console.warn('Form has errors: ' + JSON.stringify(errors));
}
