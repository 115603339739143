import { queryList, queryOne } from '../shared/technical/rest/restApi';
import { EnergyCategory } from './EnergyCategory';
import { AxiosInstance } from 'axios';

export function getEnergyCategories(customAxios: AxiosInstance): Promise<EnergyCategory[]> {
  return queryList<EnergyCategory>(customAxios, 'energy-categories');
}

export function getEnergyCategory(customAxios: AxiosInstance, energyCategoryId: number): Promise<EnergyCategory> {
  return queryOne<EnergyCategory>(customAxios, 'energy-categories', energyCategoryId);
}
