export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
}

export enum Roles {
  ADMIN = 'ADMIN',
  USER = 'USER',
}
