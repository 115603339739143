import { useHistory } from 'react-router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { useAppStyle } from '../styles/appStyle';
import { meteringPointNewMapping } from '../shared/components/router/AppRouter';
import { EnergyCategory, MOBILITY_CATEGORY } from '../categories/EnergyCategory';
import Fab from '@material-ui/core/Fab';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getMeteringPointOverviewForCategory } from '../meters/MeteringPointApi';
import { getEnergyCategory } from '../categories/CategoryApi';
import { Loading } from '../shared/components/Loading';
import { MeterCard } from '../meters/MeterCard';
import { useTranslation } from 'react-i18next';
import { createStyles, Tooltip } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { RenaultAdapterDialog } from './adapter/renault/RenaultAdapterDialog';
import Button from '@material-ui/core/Button';
import { LongText } from '../shared/components/text/LongText';
import { Vehicle } from './Vehicle';
import { getVehicles } from './VehiclesApi';
import { VehiclesList } from './VehiclesList';
import { MeteringPointOverview } from '../meters/MeteringPoint';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export const useStyle = makeStyles((theme: Theme) => createStyles({
  renaultButton: {
    width: 'fit-content',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(3),
      right: theme.spacing(11),
    }
  },

}));

export function MobilityDashboardPage(props: {}) {
  const [meteringPointOverview, setMeteringPointOverview] = useState<MeteringPointOverview[]>();
  const [category, setCategory] = useState<EnergyCategory>();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [renaultAdapterDialogOpen, setRenaultAdapterDialogOpen] = useState<boolean>(false);
  const customAxios = useAxios();
  const appClasses = useAppStyle();
  const classes = useStyle();
  const {t} = useTranslation();
  const history = useHistory();
  const {} = useAppBarSetting(t('MobilityDashboardPage.title'));

  useEffect(() => {
    getMeteringPointOverviewForCategory(customAxios, MOBILITY_CATEGORY).then(setMeteringPointOverview);
    getVehicles(customAxios).then(setVehicles);
    getEnergyCategory(customAxios, MOBILITY_CATEGORY).then(setCategory);
// eslint-disable-next-line
  }, [customAxios]);

  if (!meteringPointOverview || !category) {
    return (
      <>
        <Loading/>
      </>
    );
  }
  return (
    <>
      <VehiclesList vehicles={vehicles}/>
      <Grid id={'cards'} container={true} spacing={2} style={{maxHeight: 'max-content', marginTop: 16}}>
        {meteringPointOverview.length === 0 &&
          <strong>
            <LongText i18nKey={'MobilityDashboardPage.noMeterMessage'}/>
          </strong>
        }
        {meteringPointOverview.map((meteringPointOverview) => {
          return (
            <MeterCard
              key={meteringPointOverview.id}
              category={category}
              meteringPointOverview={meteringPointOverview}
              setMeteringPointOverviews={(meteringPointOverviews: MeteringPointOverview[]) => setMeteringPointOverview(meteringPointOverviews)}
            />
          );
        })
        }
        <Tooltip title={t('MobilityDashboardPage.actions.newRenault.tooltip') as string}>
          <Button variant={'contained'} className={classes.renaultButton} onClick={() => setRenaultAdapterDialogOpen(true)}>
            <img
              alt={t('MobilityDashboardPage.actions.newRenault.tooltip')}
              src={'https://cdn.group.renault.com/ren/master/my-renault/logos/Logo_Uk@1xx.png.asset.png/1547637961952.png'}
            />
          </Button>
        </Tooltip>
        <Tooltip title={t('MobilityDashboardPage.actions.new.tooltip') as string}>
          <Fab
            key={'add_button'}
            id={'add_button'}
            color="primary"
            size="medium"
            aria-label={t('actions.new')}
            className={appClasses.fabButton}
            onClick={() => history.push(meteringPointNewMapping(category.id.toString()))}
          >
            <AddIcon/>
          </Fab>
        </Tooltip>
        <div style={{width: '100%', height: '10%'}}/>
      </Grid>
      {renaultAdapterDialogOpen &&
        <RenaultAdapterDialog open={renaultAdapterDialogOpen} close={() => {
          setRenaultAdapterDialogOpen(false);
          getMeteringPointOverviewForCategory(customAxios, MOBILITY_CATEGORY).then(setMeteringPointOverview);
        }}/>
      }
      <div style={{width: '100%', height: '10%'}}/>
    </>
  );
}

