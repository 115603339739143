import { FormItem, FormSection } from '../../shared/components/forms/Forms';
import { StaticTextForm } from '../../shared/components/forms/StaticTextForm';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MqttConfiguration } from './MqttConfiguration';
import { getMqttConfiguration } from './MqttConfigurationApi';
import { useAxios } from '../../shared/technical/rest/AxiosProvider';


export function MqttForm(props: {
  meterId: number
}) {
  const {t} = useTranslation();
  const customAxios = useAxios();
  const [mqttConfiguration, setMqttConfiguration] = useState<MqttConfiguration>();

  useEffect(() => {
    getMqttConfiguration(customAxios).then(mqttConfiguration => {
      console.info('Loaded mqttConfiguration: ' + JSON.stringify(mqttConfiguration));
      setMqttConfiguration(mqttConfiguration);
    });
  }, [customAxios]);

  if (!mqttConfiguration) {
    return <></>;
  }

  return (
    <FormSection title={t('MqttConfiguration.title')}>
      <FormItem key={'mqttUserName'}>
        <StaticTextForm
          field={'userName'}
          value={mqttConfiguration.userName}
          label={t('MqttConfiguration.userName')}
          {...props}
        />
      </FormItem>
      <FormItem key={'mqttPassword'}>
        <StaticTextForm
          field={'password'}
          value={mqttConfiguration.password}
          label={t('MqttConfiguration.password')}
          {...props}
        />
      </FormItem>
      <FormItem key={'mqttHost'}>
        <StaticTextForm
          field={'host'}
          value={mqttConfiguration.host}
          label={t('MqttConfiguration.host')}
          {...props}
        />
      </FormItem>
      <FormItem key={'mqttPort'}>
        <StaticTextForm
          field={'port'}
          value={mqttConfiguration.port}
          label={t('MqttConfiguration.port')}
          {...props}
        />
      </FormItem>
      <FormItem key={'mqttTopic'}>
        <StaticTextForm
          field={'topic'}
          value={mqttConfiguration.topic + '/' + props.meterId}
          label={t('MqttConfiguration.topic')}
          {...props}
        />
      </FormItem>
      <FormItem key={'mqttBrokerUri'}>
        <StaticTextForm
          label={t('MqttConfiguration.brokerUri')}
          field={'brokerUri'}
          value={mqttConfiguration.metersBrokerUri + '/' + props.meterId}
        />
      </FormItem>
    </FormSection>
  );
}
