import { FormItem, FormSection, makeRequired } from '../shared/components/forms/Forms';
import { CustomSelect } from '../shared/components/forms/CustomSelect';
import { METER_DATA_SOURCES, METER_READING_TYPES, MeterDataSource, MeterReadingType } from './Meter';
import { translation } from '../i18n';
import { MeterCalculationForm } from './MeterCalculationForm';
import { CustomTextField } from '../shared/components/forms/CustomTextField';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { MeteringPoint, meteringPointSchema } from './MeteringPoint';
import { useTranslation } from 'react-i18next';
import { MeterCalculation, newMeterCalculation } from './MeterCalculation';

export function MeterForm(props: {
  expertMode: boolean
}) {
  const {t} = useTranslation();
  const {control, setValue} = useFormContext<MeteringPoint>();
  const required = makeRequired<MeteringPoint>(meteringPointSchema());
  const [showCalculation, setShowCalculation] = useState<boolean>(false);

  const meterReadingType: MeterReadingType | undefined = useWatch({control: control, name: 'activeMeter.meterReadingType'});
  const activeMeterCalculation: MeterCalculation | undefined = useWatch({control: control, name: 'activeMeter.meterCalculation'});
  useEffect(() => {
    if (meterReadingType === MeterReadingType.CALCULATED_VALUES) {
      setShowCalculation(true);
      if (!activeMeterCalculation) {
        setValue('activeMeter.meterCalculation', newMeterCalculation());
      }
    } else {
      setShowCalculation(false);
      setValue('activeMeter.meterCalculation', undefined);
    }
    // eslint-disable-next-line
  }, [meterReadingType, activeMeterCalculation, setValue]);

  return (
    <>
      <FormSection title={t('Meter.titleSection')}>
        <FormItem key={'meterReadingType'} hidden={!props.expertMode}>
          <CustomSelect
            name={'activeMeter.meterReadingType'}
            label={t('Meter.meterReadingType')}
            required={required.activeMeter.meterReadingType}
            list={METER_READING_TYPES}
            getListKey={(entry: MeterReadingType) => entry}
            getListLabel={(entry: MeterReadingType) => translation.t('MeterReadingType.' + entry)}
          />
        </FormItem>
        <FormItem key={'meterDataSource'}>
          <CustomSelect
            name={'activeMeter.dataSource'}
            label={t('Meter.dataSource')}
            required={required.activeMeter.dataSource}
            list={METER_DATA_SOURCES}
            getListKey={(entry: MeterDataSource) => entry}
            getListLabel={(entry: MeterDataSource) => translation.t('MeterDataSource.' + entry)}
          />
        </FormItem>
        <FormItem key={'serialNumber'}>
          <CustomTextField
            required={required.serialNumber}
            name={'activeMeter.serialNumber'}
            label={t('MeteringPoint.serialNumber')}
            fullWidth={true}
          />
        </FormItem>
      </FormSection>
      {props.expertMode && showCalculation &&
        <MeterCalculationForm/>
      }
    </>
  );
}
