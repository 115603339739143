import { Controller, useFormContext } from 'react-hook-form';
import * as React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';
import { retrieveError } from './Forms';

export function CustomDatePicker(props: {
  name: string
} & Partial<Omit<KeyboardDatePickerProps, 'onChange' | 'value' | 'name'>>) {
  const {name, ...rest} = props;
  const {control, formState: {errors}} = useFormContext<any>();
  const errorMessage = retrieveError(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      render={renderProps =>
        <KeyboardDatePicker
          name={renderProps.field.name}
          onChange={renderProps.field.onChange}
          onBlur={renderProps.field.onBlur}
          value={renderProps.field.value || ''}
          views={['year', 'date', 'month']}
          error={!!errorMessage}
          helperText={errorMessage}
          {...rest}
        />
      }
    />
  );
}
