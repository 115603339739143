import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { useAppStyle } from '../../../styles/appStyle';
import { useTranslation } from 'react-i18next';

export function AddButton(props: {
  onClick: () => void
}) {
  const {t} = useTranslation();
  const classes = useAppStyle();
  return (
    <Fab
      key={'add_button'}
      id={'add_button'}
      color="primary"
      size="medium"
      aria-label={t('actions.new')}
      className={classes.fabButton}
      onClick={props.onClick}
    >
      <AddIcon/>
    </Fab>
  )
}
