import * as React from 'react';
import { useEffect } from 'react';
import { CollapsibleFormSection, FormItem, FormSection, makeRequired, RhfForm } from '../shared/components/forms/Forms';
import { useTranslation } from 'react-i18next';
import { Bill, billSchema } from './Bill';
import { applyMagnitude, undoMagnitude } from '../units/magnitudeUtils';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomDatePicker } from '../shared/components/forms/CustomDatePicker';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomTextField } from '../shared/components/forms/CustomTextField';
import { dateFormatSmall } from '../shared/technical/dateTime/DateTime';
import { log } from '../logConfig';
import { magnitizedUnit, MeteringPoint } from '../meters/MeteringPoint';

export const BILL_FORM_ID = 'BillForm';

export function EditBillForm(props: {
  meteringPoint: MeteringPoint,
  bill: Bill;
  onSave: (bill: Bill) => void;
}) {
  const {t} = useTranslation();

  const form = useForm<Bill>({
    resolver: yupResolver(billSchema()),
    defaultValues: props.bill,
    mode: 'onBlur'
  });
  const {reset, control, formState: {errors}} = form;

  log.info('errors: ' + JSON.stringify(errors));
  log.info('control: ' + JSON.stringify(control));

  useEffect(() => {
    const updatedBill = props.bill;
    if (!updatedBill.startReadingValue && !updatedBill.endReadingValue) {
      return;
    }
    if (updatedBill.startReadingValue) {
      updatedBill.startReadingValue = props.meteringPoint?.magnitude ?
        applyMagnitude(props.meteringPoint.magnitude, updatedBill.startReadingValue) : updatedBill.startReadingValue;
    }
    if (updatedBill.endReadingValue) {
      updatedBill.endReadingValue = props.meteringPoint.magnitude ?
        applyMagnitude(props.meteringPoint.magnitude, updatedBill.endReadingValue) : updatedBill.endReadingValue;
    }
    reset(updatedBill);
  }, [props.bill, props.meteringPoint.magnitude, reset]);

  function saveBill(updatedBill: Bill) {
    log.info('Saving bill...');
    if (props.bill) {
      updatedBill.id = props.bill.id;
      updatedBill.version = props.bill.version;
    }
    if (updatedBill.startReadingValue) {
      updatedBill.startReadingValue = props.meteringPoint?.magnitude ?
        undoMagnitude(props.meteringPoint.magnitude, updatedBill.startReadingValue) : updatedBill.startReadingValue;
    }
    if (updatedBill.endReadingValue) {
      updatedBill.endReadingValue = props.meteringPoint.magnitude ?
        undoMagnitude(props.meteringPoint.magnitude, updatedBill.endReadingValue) : updatedBill.endReadingValue;
    }
    props.onSave(updatedBill);
  }

  const required = makeRequired<Bill>(billSchema());

  return (
    <FormProvider {...form}>
      <RhfForm id={BILL_FORM_ID} handleSave={saveBill} form={form}>
        <FormSection fullWidth={true}>
          <FormItem key={'date'}>
            <CustomDatePicker
              name="date"
              label={t('Bill.date')}
              required={required.date}
              format={dateFormatSmall(t)}
            />
          </FormItem>
          <FormItem key={'totalAmount'}>
            <CustomTextField
              name={'totalAmount'}
              label={t('Bill.totalAmount')}
              type={'number'}
              required={required.totalAmount}
              unit={'€'}
              autoFocus={true}
            />
          </FormItem>
        </FormSection>
        <CollapsibleFormSection title={t('BillsPage.startReading')} initiallyExpanded={!!props.bill.startReadingValue}>
          <FormItem key={'startReadingDate'}>
            <CustomDatePicker
              name="startReadingDate"
              label={t('Reading.readDate')}
              format={dateFormatSmall(t)}
              clearable={true}
            />
          </FormItem>
          <FormItem key={'startReadingValue'}>
            <CustomTextField
              name={'startReadingValue'}
              label={t('Reading.value') + ' in ' + magnitizedUnit(props.meteringPoint)}
              required={required.startReadingValue}
            />
          </FormItem>
        </CollapsibleFormSection>
        <CollapsibleFormSection title={t('BillsPage.endReading')} initiallyExpanded={!!props.bill.endReadingValue}>
          <FormItem key={'endReadingDate'} fullWidth={true}>
            <CustomDatePicker
              name="endReadingDate"
              label={t('Reading.readDate')}
              format={dateFormatSmall(t)}
              clearable={true}
            />
          </FormItem>
          <FormItem key={'endReadingValue'}>
            <CustomTextField
              name={'endReadingValue'}
              type={'number'}
              label={t('Reading.value') + ' in ' + magnitizedUnit(props.meteringPoint)}
              required={required.endReadingValue}
            />
          </FormItem>
        </CollapsibleFormSection>
      </RhfForm>
    </FormProvider>
  );
}
