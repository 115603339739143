import { AxiosInstance } from 'axios';
import { PagedResult, Pageable } from './pageable';
import { ValidationErrorResponse } from './validation';
import { handleErrorResponse, handleSuccessResponse } from './axiosUtils';

export const apiHost = '/api/v1';

export function queryPage<DTO>(customAxios: AxiosInstance, relativeUri: string, pageable: Pageable, params?: string): Promise<PagedResult<DTO>> {
  const baseUri = apiHost + '/' + relativeUri;
  return customAxios.get(`${baseUri}?${toUrlParams(pageable)}` + (params ? '&' + params : ''))
    .then(handleSuccessResponse, handleErrorResponse);
}

export function queryList<DTO>(customAxios: AxiosInstance, relativeUri: string, params?: string): Promise<DTO[]> {
  const baseUri = apiHost + '/' + relativeUri;
  return customAxios.get(`${baseUri}?` + (params ? params : ''))
    .then(handleSuccessResponse, handleErrorResponse);
}

export function queryOne<DTO>(customAxios: AxiosInstance, relativeUri: string, id?: string | number, params?: string): Promise<DTO> {
  const baseUri = apiHost + '/' + relativeUri;
  const url = id ? `${baseUri}/${id}` : baseUri;
  return customAxios.get(url  + (params ? '?' + params : ''))
    .then(handleSuccessResponse, handleErrorResponse);
}

export function createOne<DTO>(customAxios: AxiosInstance, relativeUri: string, entity: DTO): Promise<DTO | ValidationErrorResponse> {
  const baseUri = apiHost + '/' + relativeUri;
  return customAxios.post(baseUri, entity)
    .then(handleSuccessResponse, handleErrorResponse);
}

export function updateOne<DTO>(customAxios: AxiosInstance, relativeUri: string, entity: DTO): Promise<DTO | ValidationErrorResponse> {
  const baseUri = apiHost + '/' + relativeUri;
  let id = 'id';
  return customAxios.put(`${baseUri}/${entity[id]}`, entity)
    .then(handleSuccessResponse, handleErrorResponse);
}

export function deleteOne<DTO>(customAxios: AxiosInstance, relativeUri: string, id?: string | number): Promise<void> {
  const baseUri = apiHost + '/' + relativeUri;
  const uri = id ? `${baseUri}/${id}` : baseUri;
  return customAxios.delete(uri)
    .then(handleSuccessResponse, handleErrorResponse);
}

function toUrlParams(pageable: Pageable) {
  let params = `page=${pageable.page}&size=${pageable.size}`;
  if (pageable.search) {
    params += `search=${pageable.search}`;
  }
  if (pageable.sort) {
    params += `sort=${pageable.sort}`;
  }
  return params;
}
