import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TariffProviderTable } from './TariffProviderTable';
import { TARIFF_PROVIDER_NEW_MAPPING } from '../shared/components/router/AppRouter';
import { useHistory } from 'react-router';
import { AddButton } from '../shared/components/buttons/AddButton';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function TariffProvidersPage(props: {}) {
  const {t} = useTranslation();
  const history = useHistory();
  const {} = useAppBarSetting(t('TariffProvider.title'));

  return (
    <>
      <TariffProviderTable/>
      <AddButton
        onClick={() => history.push(TARIFF_PROVIDER_NEW_MAPPING)}
      />
    </>
  );
}
