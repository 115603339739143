import { OpenState, submitForm } from '../shared/components/forms/Forms';
import * as React from 'react';
import { Meter } from './Meter';
import { EditMeterForm, METER_FORM_ID } from './EditMeterForm';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getMeteringPoint } from './MeteringPointApi';
import { log } from '../logConfig';
import { addNewMeter } from './MeterApi';
import { MeteringPoint } from './MeteringPoint';

export function MeterChangeDialog(props: {
  meteringPoint: MeteringPoint;
  meter: Meter;
} & OpenState) {
  const customAxios = useAxios();

  function handleSave(changedMeter: Meter) {
    // TODO add update Meter method!!!!
   addNewMeter(customAxios, props.meteringPoint.id, changedMeter).then((response) => {
      if ((response as ValidationErrorResponse).errors) {
        log.error('Validation result: ' + JSON.stringify(response), null);
      } else {
        // we load the changed metering point and update the active meter
        getMeteringPoint(customAxios, props.meteringPoint.id).then(meteringPoint => {
          props.meteringPoint.activeMeter = meteringPoint.activeMeter;
        });
        props.close();
      }
    });
  }

  function handleClose() {
    props.close();
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Zählertausch: Neuer Zähler</DialogTitle>
      <DialogContent>
        <Typography>Bei einem Zählerwechseln am Besten eine letzte Ablesung vom alten Zähler machen und dann nach dem Wechseln gleich eine
          Ablesung vom neuen Zähler mit dem Anfangswert.</Typography>
        <EditMeterForm
          meteringPoint={props.meteringPoint}
          meter={props.meter}
          onSave={handleSave}/>
      </DialogContent>
      <DialogActions>
        <Button id="edit_meter_cancel_button" onClick={() => handleClose()} color="primary">
          Abbrechen
        </Button>
        <Button id="edit_meter_save_button" onClick={() => {
          submitForm(METER_FORM_ID);
        }} color="primary">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
}
