import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export function DeleteIconButton(props: {
  id: string | number,
  name: string,
  tooltipTitle: string,
  onDelete: () => void,
}) {
  const {t} = useTranslation();

  return (
    <Tooltip title={t('actions.delete') as string}>
      <IconButton
        id={'delete_' + props.id}
        aria-label={t('actions.delete')}
        onClick={() => {
          if (!window.confirm(props.tooltipTitle)) {
            return;
          }
          props.onDelete();
        }}
      >
        <DeleteIcon/>
      </IconButton>
    </Tooltip>

  );
}
