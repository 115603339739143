import { User } from './user';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { getAccount } from './AccountApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import * as Sentry from '@sentry/react';
import { User as SentryUser } from '@sentry/types';
import { log } from '../logConfig';

export const AccountContext = React.createContext<User | undefined>(undefined);

export function useAccount(): User {
  return useContext(AccountContext)!!;
}

export function AccountProvider(props: {
  children: ReactNode
}) {
  const customAxios = useAxios();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    log.info('Axios did change => getting new account');
    getAccount(customAxios).then(setUser);
  }, [customAxios]);

  useEffect(() => {
    Sentry.setUser({id: user?.id} as SentryUser);
  }, [user]);

  log.debug('Inside AccountProvider. User: ' + user);
  return (
    <AccountContext.Provider value={user}>
      {user && props.children}
    </AccountContext.Provider>
  );
}
