import { DeltaValues } from '../values/DeltaValues';
import { ChartParams } from './ChartParamParser';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { readingChartMapping } from '../shared/components/router/AppRouter';
import { dateRangeBegin, dateRangeEnd } from '../shared/technical/dateTime/DateTimeRange';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as React from 'react';
import { updateChartPageUri } from './ReadingChartPage';
import { MeteringPoint } from '../meters/MeteringPoint';

export function ChartBottomControls(props: {
  deltaValues: DeltaValues,
  params: ChartParams,
  meteringPoint: MeteringPoint
}) {
  const history = useHistory();
  const {t} = useTranslation();

  let chartUri = readingChartMapping(props.meteringPoint.type.category.id.toString(), props.meteringPoint.id) + '?';

  // Should not occur since we normally get at least one value back from backend. But this error occured in Sentry...
  const enableButtons = !!props.deltaValues.values && props.deltaValues.values.length > 0

  function expandLeft() {
    return () => {
      const firstDeltaValue = props.deltaValues;
      const fromSave = props.params.from || dateRangeBegin(firstDeltaValue.values[0].range);
      const toSave = props.params.to || dateRangeEnd(firstDeltaValue.values[firstDeltaValue.values.length - 1].range);
      const duration = fromSave.until(toSave).toDuration();
      updateChartPageUri(history, {...props.params, from: fromSave.minus(duration)}, chartUri);
    };
  }

  function previousInterval() {
    return () => {
      const firstDeltaValue = props.deltaValues;
      const fromSave = props.params.from || dateRangeBegin(firstDeltaValue.values[0].range);
      const toSave = props.params.to || dateRangeEnd(firstDeltaValue.values[firstDeltaValue.values.length - 1].range);
      const duration = fromSave.until(toSave).toDuration();
      updateChartPageUri(history, {...props.params, from: fromSave.minus(duration), to: fromSave}, chartUri);
    };
  }

  function nextInterval() {
    return () => {
      const firstDeltaValue = props.deltaValues;
      const fromSave = props.params.from || dateRangeBegin(firstDeltaValue.values[0].range);
      const toSave = props.params.to || dateRangeEnd(firstDeltaValue.values[firstDeltaValue.values.length - 1].range);
      const duration = fromSave.until(toSave).toDuration();
      updateChartPageUri(history, {...props.params, from: toSave, to: toSave.plus(duration)}, chartUri);
    };
  }

  function expandRight() {
    return () => {
      const firstDeltaValue = props.deltaValues;
      const fromSave = props.params.from || dateRangeBegin(firstDeltaValue.values[0].range);
      const toSave = props.params.to || dateRangeEnd(firstDeltaValue.values[firstDeltaValue.values.length - 1].range);
      const duration = fromSave.until(toSave).toDuration();
      updateChartPageUri(history, {...props.params, to: toSave.plus(duration)}, chartUri);
    };
  }

  return (
    <>
      <Grid item>
        <Tooltip title={t('ReadingChart.expandIntervalIntoPast') as string}>
          <IconButton disabled={!enableButtons} onClick={expandLeft()}>
            <ArrowBackIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title={t('ReadingChart.previousInterval') as string}>
          <IconButton disabled={!enableButtons} onClick={previousInterval()}>
            <ChevronLeftIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={t('ReadingChart.nextInterval') as string}>
          <IconButton disabled={!enableButtons} onClick={nextInterval()}>
            <ChevronRightIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title={t('ReadingChart.expandIntervalIntoFuture') as string}>
          <IconButton disabled={!enableButtons} onClick={expandRight()}>
            <ArrowForwardIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
    </>
  );
}
