import { CountableTimeInterval, timeDay, timeHour, timeMinute, timeMonth, timeSecond, timeWeek, timeYear } from 'd3-time';
import { DateTime } from 'luxon';

export enum TimeUnit {
  CENTURY = 'CENTURY',
  DECADE = 'DECADE',
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
  SECOND = 'SECOND',
}

export function timeUnitToTimeRangeFormat(timeUnit: TimeUnit): string {
  switch (timeUnit) {
    case TimeUnit.CENTURY:
      return 'yyyy';
    case TimeUnit.YEAR:
      return 'yyyy';
    case TimeUnit.QUARTER:
      return 'Q q/yyyy';
    case TimeUnit.MONTH:
      return 'MM.yyyy';
    case TimeUnit.WEEK:
      return 'KW w/yyyy';
    case TimeUnit.DAY:
      return 'dd.MM.yyyy';
    case TimeUnit.HOUR:
      return 'hh:00';
    case TimeUnit.MINUTE:
      return 'hh:mm';
    case TimeUnit.SECOND:
      return 'ss';
    default:
      return 'yyyy';
  }
}

export function timeUnitToDateTimeFormat(timeUnit: TimeUnit): string {
  switch (timeUnit) {
    case TimeUnit.CENTURY:
      return 'yyyy';
    case TimeUnit.YEAR:
      return 'yyyy';
    case TimeUnit.QUARTER:
      return 'MM.yyyy';
    case TimeUnit.MONTH:
      return 'MM.yyyy';
    case TimeUnit.WEEK:
      return 'dd.MM.yyyy';
    case TimeUnit.DAY:
      return 'dd.MM.yyyy';
    case TimeUnit.HOUR:
      return 'dd.MM.yyyy HH:mm';
    case TimeUnit.MINUTE:
      return 'dd.MM.yyyy HH:mm';
    case TimeUnit.SECOND:
      return 'dd.MM.yyyy HH:mm:ss';
    default:
      return 'dd.MM.yyyy HH:mm:ss';
  }
}

export function isTimeUnitWithTime(timeUnit: TimeUnit) {
  switch (timeUnit) {
    case TimeUnit.CENTURY:
    case TimeUnit.YEAR:
    case TimeUnit.QUARTER:
    case TimeUnit.MONTH:
    case TimeUnit.WEEK:
    case TimeUnit.DAY:
      return false
    case TimeUnit.HOUR:
    case TimeUnit.MINUTE:
    case TimeUnit.SECOND:
    default:
      return true;
  }
}

export enum DateUnit {
  CENTURY = 'CENTURY',
  DECADE = 'DECADE',
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
}

export function prevDateTime(dateTime: DateTime, timeUnit: TimeUnit) {
  switch (timeUnit) {
    case TimeUnit.CENTURY:
      return dateTime.minus({years: 10});
    case TimeUnit.YEAR:
      return dateTime.minus({years: 1});
    case TimeUnit.QUARTER:
      return dateTime.minus({months: 3});
    case TimeUnit.MONTH:
      return dateTime.minus({months: 1});
    case TimeUnit.WEEK:
      return dateTime.minus({days: 7});
    case TimeUnit.DAY:
      return dateTime.minus({days: 1});
    case TimeUnit.HOUR:
      return dateTime.minus({hours: 1});
    case TimeUnit.MINUTE:
      return dateTime.minus({minutes: 1});
    case TimeUnit.SECOND:
      return dateTime.minus({seconds: 1});
    default:
      return dateTime;
  }
}

export function nextDateTime(dateTime: DateTime, timeUnit: TimeUnit) {
  switch (timeUnit) {
    case TimeUnit.CENTURY:
      return dateTime.plus({years: 10});
    case TimeUnit.YEAR:
      return dateTime.plus({years: 1});
    case TimeUnit.QUARTER:
      return dateTime.plus({months: 3});
    case TimeUnit.MONTH:
      return dateTime.plus({months: 1});
    case TimeUnit.WEEK:
      return dateTime.plus({days: 7});
    case TimeUnit.DAY:
      return dateTime.plus({days: 1});
    case TimeUnit.HOUR:
      return dateTime.plus({hours: 1});
    case TimeUnit.MINUTE:
      return dateTime.plus({minutes: 1});
    case TimeUnit.SECOND:
      return dateTime.plus({seconds: 1});
    default:
      return dateTime;
  }
}

export const dateUnits = Object.keys(DateUnit) as string[];
export const timeUnits = Object.keys(TimeUnit) as string[];
export const TIME_UNITS = Object.keys(TimeUnit).map(timeUnitStr => TimeUnit[timeUnitStr]) as TimeUnit[];

const ignoreTimeUnits = [TimeUnit.QUARTER, TimeUnit.WEEK];

export function prevTimeUnit(timeUnit: TimeUnit) {
  if(!timeUnit) {
    return TimeUnit.DAY;
  }
  const currentIndex = timeUnits.indexOf(timeUnit as string);
  const newTimeUnit = timeUnits[currentIndex + 1] as TimeUnit;
  if (ignoreTimeUnits.findIndex(value => value === newTimeUnit) >= 0) {
    return prevTimeUnit(newTimeUnit);
  }
  return newTimeUnit || TimeUnit.DAY;
}

export enum ChronoField {
  DAY_OF_MONTH = 'DAY_OF_MONTH',
  MONTH_OF_YEAR = 'MONTH_OF_YEAR',
  YEAR = 'YEAR',
}

export function timeUnitToCountableTimeInterval(timeUnit: TimeUnit): CountableTimeInterval {
  switch (timeUnit) {
    case TimeUnit.CENTURY:
      return timeYear;
    case TimeUnit.DECADE:
      return timeYear;
    case TimeUnit.YEAR:
      return timeYear;
    case TimeUnit.QUARTER:
      return timeMonth;
    case TimeUnit.MONTH:
      return timeMonth;
    case TimeUnit.WEEK:
      return timeWeek;
    case TimeUnit.DAY:
      return timeDay;
    case TimeUnit.HOUR:
      return timeHour;
    case TimeUnit.MINUTE:
      return timeMinute;
    case TimeUnit.SECOND:
      return timeSecond;
  }
  return timeDay;
}
