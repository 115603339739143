import { ValidityInfo, validityInfoDateOnlySchema } from '../shared/technical/validity/ValidityInfo';
import * as yup from 'yup';
import { Tariff } from './TariffProvider';

export interface TariffSubscription {
  id: number,
  version: number,
  tariff: Tariff,
  validityInfo: ValidityInfo
}

export function tariffSubscriptionSchema(): yup.SchemaOf<TariffSubscription> {
  return yup.object({
    id: yup.number(),
    version: yup.number(),
    tariff: yup.mixed<Tariff>().required(),
    validityInfo: validityInfoDateOnlySchema().required()
  }) as unknown as yup.SchemaOf<TariffSubscription>;
}
