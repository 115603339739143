import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface DateFormInputProps {
  id?: string;
  field?: string;
  label?: string;
  required?: boolean;
  views?: ('date' | 'year' | 'month')[];
  format?: string;
  value?: DateTime | undefined;
  setValue?: (value: DateTime | undefined) => void;
}

export function DateFormInput(props: DateFormInputProps) {
  const [value, setValue] = useState<DateTime | undefined>(props.value);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const {id, label, field, required} = props;
  return (
    <FormControl error={false} fullWidth={true}>
      <KeyboardDatePicker
        id={id ? id : 'input_' + field}
        label={label}
        format={props.format ? props.format : 'dd.MM.yyyy'}
        value={value ? value : null}
        views={props.views || []}
        variant={'dialog'}
        clearable={true}
        fullWidth={true}
        onChange={(date: MaterialUiPickersDate | null, value?: string | null) => {
          let result: DateTime | undefined = undefined;
          if (value && date && !date.isValid) {
            return;
          }
          if (date) {
            result = date;
          } else {
            result = undefined;
          }
          setValue(result);
          if (props.setValue) {
            props.setValue(result);
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        required={required || false}
        error={false}
      />
      <FormHelperText>{}</FormHelperText>
    </FormControl>
  );
}

