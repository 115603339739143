import * as React from 'react';
import { useEffect, useState } from 'react';
import { emptyMeter, Meter } from './Meter';
import { MeteringPointForm } from './MeteringPointForm';
import { emptyPage, fullPageable, PagedResult as RestPage } from '../shared/technical/rest/pageable';
import { useHistory } from 'react-router';
import { ButtonBar } from '../shared/components/forms/Forms';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { log } from '../logConfig';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';
import { categoryMapping } from '../shared/components/router/AppRouter';
import Button from '@material-ui/core/Button';
import { MeterChangeDialog } from './MeterChangeDialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import { formatStringDate } from '../shared/technical/dateTime/DateTime';
import { MeterType } from '../meterTypes/MeterType';
import { getMeteringPoint, saveMeteringPoint } from './MeteringPointApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getMeterTypesForCategory } from '../meterTypes/MeterTypeApi';
import { Loading } from '../shared/components/Loading';
import { MeteringPoint } from './MeteringPoint';
import { getAllMeter, updateMeter } from './MeterApi';
import { useTranslation } from 'react-i18next';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function EditMeteringPointPage(props: {
  meteringPointId: string;
  categoryId: string;
}) {
  const history = useHistory();
  const {t} = useTranslation();
  const {setTitle} = useAppBarSetting();

  const [meteringPoint, setMeteringPoint] = useState<MeteringPoint>();
  const [meters, setMeters] = useState<Meter[]>();
  const [meterTypes, setMeterTypes] = useState<RestPage<MeterType>>(emptyPage());
  const [showMeterChangePopup, setShowMeterChangePopup] = useState(false);
  const customAxios = useAxios();

  useEffect(() => {
    getMeteringPoint(customAxios, props.meteringPointId).then(setMeteringPoint);
    getAllMeter(customAxios, props.meteringPointId).then(setMeters);
    getMeterTypesForCategory(customAxios, fullPageable(), props.categoryId.toString()).then(setMeterTypes);
  }, [props.meteringPointId, props.categoryId, customAxios]);

  useEffect(() => {
    meteringPoint && setTitle(t('MeteringPoint.title') + ' ' + meteringPoint.name);
  }, [meteringPoint, setTitle, t]);

  function saveButtonPressed(updatedMeteringPoint: MeteringPoint) {
    if (updatedMeteringPoint.activeTariffSubscription) {
      if (!updatedMeteringPoint.activeTariffSubscription.tariff || !updatedMeteringPoint.activeTariffSubscription.tariff.id) {
        updatedMeteringPoint.activeTariffSubscription = undefined;
      }
    }
    return saveMeteringPoint(customAxios, updatedMeteringPoint).then(
      (response) => {
        if ((response as ValidationErrorResponse).errors) {
          return ((response as ValidationErrorResponse).errors);
        } else {
          return updateMeter(customAxios, updatedMeteringPoint.activeMeter).then(
            (response) => {
              if ((response as ValidationErrorResponse).errors) {
                return ((response as ValidationErrorResponse).errors);
              } else {
                history.push(categoryMapping(props.categoryId.toString()));
                return undefined;
              }
            }
          );
        }
      },
      (reason) => {
        log.error(reason, null);
        return undefined;
      });
  }

  if (!meteringPoint) {
    return (
      <Loading/>
    );
  }
  return (
    <>
      <MeteringPointForm
        meteringPoint={meteringPoint}
        meterTypes={meterTypes}
        onSave={saveButtonPressed}
      />
      <ButtonBar>
        <Button onClick={() => setShowMeterChangePopup(true)} variant="contained" id={'change_button'}>
          {t('MeteringPoint.buttonChangeMeter')}
        </Button>
      </ButtonBar>
      <MeterChangeDialog
        meteringPoint={meteringPoint}
        meter={emptyMeter()}
        open={showMeterChangePopup}
        close={() => setShowMeterChangePopup(false)}
      />
      {meters && meters.length > 1 &&
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Typography>{t('MeteringPoint.sectionChangedMeter')}:</Typography>
          </Grid>
          <Grid item={true} xs={12} md={4}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Gültig von</TableCell>
                  <TableCell>Gültig bis</TableCell>
                  <TableCell>Nummer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meters.map(meter => {
                  return (
                    <TableRow key={meter.id}>
                      <TableCell>{meter.validityInfo && formatStringDate(meter.validityInfo.validFrom)}</TableCell>
                      <TableCell>{meter.validityInfo && formatStringDate(meter.validityInfo.validTo)}</TableCell>
                      <TableCell>{meter.serialNumber}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>}
    </>
  );
}
