import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TariffForm from './TariffForm';
import { useHistory } from 'react-router';
import { tariffsMapping } from '../shared/components/router/AppRouter';
import { saveTariff } from './tariffProviderApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { newTariff } from './TariffProvider';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function NewTariffPage(props: {
  tariffProviderId: string
}) {
  const history = useHistory();
  const {t} = useTranslation();
  const customAxios = useAxios();
  const {} = useAppBarSetting(t('Tariff.titleNew'));

  return (
    <>
      <TariffForm
        tariffProviderId={props.tariffProviderId}
        tariff={newTariff()}
        onSave={(tariff => {
          saveTariff(customAxios, tariff).then(() => history.push(tariffsMapping(props.tariffProviderId)));
        })}
      />
    </>
  );
}
