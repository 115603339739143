import { Category, CategoryConfiguration, CategoryLogger, CategoryServiceFactory, LogLevel } from 'typescript-logging';

// Optionally change default settings, in this example set default logging to Info.
// Without changing configuration, categories will log to Error.
CategoryServiceFactory.setDefaultConfiguration(new CategoryConfiguration(LogLevel.Debug));

// Create categories, they will autoregister themselves.
// This creates one root logger, with 1 child sub category.
export const catRoot = new Category('root');
export const catProd = new Category('security', catRoot);

// Get a logger, this can be retrieved for root categories only (in the example above, the "service" category).
export const log: CategoryLogger = CategoryServiceFactory.getLogger(catRoot);

log.info(`homemeter version ${process.env.REACT_APP_GIT_TAG}`);
