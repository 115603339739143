import { AxiosInstance } from 'axios';
import { queryList, updateOne } from '../shared/technical/rest/restApi';
import { Vehicle } from './Vehicle';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';

export function getVehicles(customAxios: AxiosInstance): Promise<Vehicle[]> {
  return queryList(customAxios, 'vehicles');
}

export function updateVehicle(customAxios: AxiosInstance, vehicle: Vehicle): Promise<Vehicle | ValidationErrorResponse> {
  return updateOne(customAxios, 'vehicles', vehicle)
}
