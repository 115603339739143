import { Meter, METER_READING_TYPES, MeterReadingType, meterSchema } from './Meter';
import * as React from 'react';
import { useEffect } from 'react';
import { FormItem, FormSection, makeRequired, RhfForm } from '../shared/components/forms/Forms';
import { translation } from '../i18n';
import { dateFormatSmall } from '../shared/technical/dateTime/DateTime';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomSelect } from '../shared/components/forms/CustomSelect';
import { CustomTextField } from '../shared/components/forms/CustomTextField';
import { CustomDatePicker } from '../shared/components/forms/CustomDatePicker';
import { MeteringPoint } from './MeteringPoint';

export const METER_FORM_ID = 'MeterForm';

export function EditMeterForm(props: {
  meteringPoint: MeteringPoint;
  meter: Meter;
  onSave: (meter: Meter) => void;
}) {
  const {t} = useTranslation();
  const form = useForm<Meter>({
    resolver: yupResolver(meterSchema()),
  });
  const {reset} = form;

  useEffect(() => {
    reset(props.meter);
  }, [props.meter, reset]);

  const required = makeRequired<Meter>(meterSchema());

  return (
    <FormProvider {...form}>
      <RhfForm id={METER_FORM_ID} handleSave={props.onSave} form={form}>
        <FormSection>
          <FormItem key={'meterReadingType'}>
            <CustomSelect
              name={'meterReadingType'}
              label={t('Meter.meterReadingType')}
              required={required.meterReadingType}
              list={METER_READING_TYPES}
              getListKey={(entry: MeterReadingType) => entry}
              getListLabel={(entry: MeterReadingType) => translation.t('MeterReadingType.' + entry)}
            />
          </FormItem>
          <FormItem>
            <FormItem key={'serialNumber'}>
              <CustomTextField
                required={required.serialNumber}
                name={'serialNumber'}
                label={t('Meter.serialNumber')}/>
            </FormItem>
          </FormItem>
          <FormItem key={'validity'}>
            <CustomDatePicker
              label={t('ValidityInfo.validFrom')}
              name={'validityInfo.validFrom'}
              format={dateFormatSmall(t)}/>
            <CustomDatePicker
              label={t('ValidityInfo.validTo')}
              name={'validityInfo.validTo'}
              format={dateFormatSmall(t)}/>
          </FormItem>
        </FormSection>
      </RhfForm>
    </FormProvider>
  );
}
