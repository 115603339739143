import * as React from 'react';
import { useEffect, useState } from 'react';
import { ConcreteValueReading, concreteValueReadingShortSchema, DeltaValueReading, Reading } from './Reading';
import { MeterReadingType } from '../meters/Meter';
import { FormItem, FormSection, RhfForm } from '../shared/components/forms/Forms';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomDateTimePicker } from '../shared/components/forms/CustomDateTimePicker';
import { CustomNumberField } from '../shared/components/forms/CustomNumberField';
import { StaticTextForm } from '../shared/components/forms/StaticTextForm';
import { getForecast } from '../meters/MeteringPointApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import Typography from '@material-ui/core/Typography';
import { handleValueOrForecastChange } from './ReadingPageUtils';
import { translation } from '../i18n';
import { magnitizedUnit } from '../meters/MeteringPoint';

export interface ReadingFormProps {
  reading: ConcreteValueReading | DeltaValueReading;
  onSave: (reading: Reading) => void;
}

export const READING_FORM_ID = 'ReadingForm';

export function ReadingForm(props: ReadingFormProps) {
  const {t} = useTranslation();
  const customAxios = useAxios();
  const [forecast, setForecast] = useState<number>(0);
  const [warnings, setWarnings] = useState<string>('');

  const form = useForm<ConcreteValueReading | DeltaValueReading>({
    resolver: yupResolver(concreteValueReadingShortSchema()),
    defaultValues: props.reading,
    mode: 'onBlur'
  });
  const {register, control} = form;

  const readDate: string | undefined = useWatch({control: control, name: 'readDate'});
  const value: number | undefined = useWatch({control: control, name: 'value'});

  useEffect(() => {
    if (!readDate || !(readDate as any).isValid) {
      return;
    }
    getForecast(customAxios, props.reading.meteringPoint.id, readDate).then(newForecast => {
      setForecast(newForecast.value);
    }).catch(() => {
      // Ignore errors
    });
  }, [customAxios, props.reading.meteringPoint.id, readDate, setForecast]);

  useEffect(() => {
    if (value && forecast) {
      handleValueOrForecastChange(value, props.reading.meteringPoint, forecast, setWarnings, t);
    }
  }, [forecast, value, props.reading.meteringPoint, t]);

  useEffect(() => {
    register('id');
    register('meteringPoint');
  }, [props.reading, register]);

  return (
    <FormProvider {...form}>
      <RhfForm id={READING_FORM_ID} handleSave={props.onSave} form={form}>
        <FormSection>
          {props.reading.meteringPoint.activeMeter.meterReadingType === MeterReadingType.DELTA_VALUES &&
            <>
              <FormItem>
                <CustomDateTimePicker
                  name={'dateRange.firstDateTime'}
                  label={translation.t('DateRange.firstDate')}
                />
              </FormItem>
              <FormItem>
                <CustomDateTimePicker
                  name={'dateRange.lastDateTime'}
                  label={translation.t('DateRange.lastDate')}
                />
              </FormItem>
            </>
          }
          {props.reading.meteringPoint.activeMeter.meterReadingType !== MeterReadingType.DELTA_VALUES &&
            <FormItem>
              <CustomDateTimePicker
                name={'readDate'}
                label={t('Reading.readDate')}
              />
            </FormItem>
          }
          <FormItem>
            <CustomNumberField
              name={'value'}
              autoFocus={true}
              label={t('Reading.value') + ' in ' + magnitizedUnit(props.reading.meteringPoint) + ''}
              unit={magnitizedUnit(props.reading.meteringPoint)}
              magnitude={props.reading.meteringPoint.magnitude}
            />
          </FormItem>
          <FormItem>
            <StaticTextForm label={t('MeteringPoint.title')} field={'meteringPoint'} value={props.reading.meteringPoint.name}/>
          </FormItem>
        </FormSection>
        {warnings.length > 0 &&
          <Typography color={'secondary'}>
            {warnings}
          </Typography>
        }
      </RhfForm>
    </FormProvider>
  );
}
