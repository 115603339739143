import * as React from 'react';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { User, UserManager } from 'oidc-client-ts';
import { log } from '../../../logConfig';
import { useUserManager } from './UserManagerProvider';
import { captureMessage, Severity } from '@sentry/react';

export const useUser = () => {
  return useContext<UserContextProps>(UserContext);
};

type UserContextProps = {
  user?: User;
}

const UserContext = React.createContext<UserContextProps>({});

async function forceSigninRedirect(userManager: UserManager) {
  log.debug('Force singing redirect');
  // log.info('Clearing stale state');
  // await userManager.clearStaleState();
  // log.info('Removing user');
  // await userManager.removeUser();
  log.info('Signin Redirect');
  await userManager.signoutRedirect();
}

export function UserProvider(props: {
  children: ReactNode
}) {
  const {userManager} = useUserManager();
  const [user, setUser] = useState<User>();

  log.debug('Inside UserProvider');

  useEffect(() => {
    return subscribeTokenExpiredEvents(userManager);
  }, [userManager]);

  useEffect(() => {
    return subscribeTokenExpiringEvents(userManager);
  }, [userManager]);

  useEffect(() => {
    return subscribeUserLoadedEvents(userManager, setUser);
  }, [userManager]);

  useEffect(() => {
    if (userManager) {
      userManager.signinSilent().catch((error: Error) => {
        log.info('Error while calling signinSilent: ' + '/ ' + error);
        captureMessage('Error while calling signinSilent: ' + error.message, Severity.Info);
        if (error.message === 'invalid_grant') {
          userManager.signoutRedirect().then();
        }
        if (error.message === 'login_required') {
          userManager.signinRedirect().then();
        }
      });
    }
  }, [userManager]);

  if (!userManager || !user) {
    return <></>;
  }

  return (
    <UserContext.Provider value={{user}}>
      {user && props.children}
    </UserContext.Provider>
  );
}

function subscribeUserLoadedEvents(userManager: UserManager | undefined, setUser: (value: (((prevState: (User | undefined)) => (User | undefined)) | User | undefined)) => void) {
  if (!userManager) {
    return;
  }
  log.debug('UserProvider: Got UserManager and registering events');

  const updateUser = async () => {
    log.info('Inside userLoaded Callback');
    const user = await userManager.getUser();
    if (user?.expired === undefined) {
      log.debug('No expired flag set, ignoring user...');
    } else if (user && !user.expired) {
      log.debug('User not expired => setting it...');
      setUser(user);
    } else if (!user) {
      log.info('User not loaded!');
    } else {
      log.info('User expired');
      captureMessage('UserLoaded: user expired, calling forceSigninRedirect ', Severity.Info);
      forceSigninRedirect(userManager).then();
    }
  };

  userManager.events.addUserLoaded(updateUser);

  return () => userManager.events.removeUserLoaded(updateUser);
}

function subscribeTokenExpiringEvents(userManager: UserManager | undefined) {
  if (!userManager) {
    return;
  }
  log.debug('UserProvider: Got UserManager and registering access token expiring events');

  const accessTokenExpiring = async () => {
    log.debug('Access token about to expire!');
    userManager.signinSilent().catch(() => userManager.signinRedirect());
  };

  userManager.events.addAccessTokenExpiring(accessTokenExpiring);

  return () => userManager.events.removeAccessTokenExpiring(accessTokenExpiring);
}

function subscribeTokenExpiredEvents(userManager: UserManager | undefined) {
  if (!userManager) {
    return;
  }
  log.debug('UserProvider: Got UserManager and registering access token expired events');

  const accessTokenExpired = async () => {
    log.info('Access token expired!');
    userManager.signinSilent().catch(() => userManager.signinRedirect());
  };

  userManager.events.addAccessTokenExpired(accessTokenExpired);

  return () => userManager.events.removeAccessTokenExpired(accessTokenExpired);
}
