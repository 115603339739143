import * as React from 'react';
import { useEffect, useState } from 'react';
import { ConcreteValueReading, DeltaValueReading } from './Reading';
import { ButtonBar } from '../shared/components/forms/Forms';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { emptyMeter, MeterReadingType } from '../meters/Meter';
import { MeterChangeDialog } from '../meters/MeterChangeDialog';
import Button from '@material-ui/core/Button';
import { ReadingsTable } from './ReadingsTable';
import { useTranslation } from 'react-i18next';
import { Loading } from '../shared/components/Loading';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getDeltaReading, getReading } from './ReadingApi';
import { READING_FORM_ID, ReadingForm } from './ReadingForm';
import { FormSaveButton } from '../shared/components/buttons/SaveButton';
import { saveButtonPressed } from './ReadingPageUtils';
import { useHistory } from 'react-router';
import { getMeteringPoint } from '../meters/MeteringPointApi';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

type  Props = {
  meteringPointId: string;
  readingId: string;
};

export function EditReadingPage(props: Props) {
  const [reading, setReading] = useState<ConcreteValueReading | DeltaValueReading>();
  const [showMeterChangePopup, setShowMeterChangePopup] = useState<boolean>(false);

  const {t} = useTranslation();
  const history = useHistory();
  const customAxios = useAxios();
  const {} = useAppBarSetting(t('Reading.titleEdit'));

  useEffect(() => {
    getMeteringPoint(customAxios, props.meteringPointId).then((meteringPoint) => {
      if (meteringPoint.activeMeter.meterReadingType === MeterReadingType.DELTA_VALUES) {
        getDeltaReading(customAxios, props.meteringPointId, props.readingId).then((reading) => {
          setReading(reading);
        });
      } else {
        getReading(customAxios, props.meteringPointId, props.readingId).then((reading) => {
          setReading(reading);
        });
      }
    });
  }, [props.readingId, props.meteringPointId, customAxios]);

  if (!reading) {
    return (
      <Loading/>
    );
  }
  return (
    <>
      <ReadingForm
        reading={reading}
        onSave={(reading) => saveButtonPressed(customAxios, reading, history)}
      />
      <ButtonBar>
        <FormSaveButton formName={READING_FORM_ID}/>
        <Button onClick={() => setShowMeterChangePopup(true)} variant="outlined" id={'change_button'}>
          {t('Reading.meterChange')}
        </Button>
      </ButtonBar>
      <MeterChangeDialog
        meteringPoint={reading.meteringPoint}
        meter={emptyMeter()}
        open={showMeterChangePopup}
        close={() => setShowMeterChangePopup(false)}
      />
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Typography>{t('Reading.formerReadings')}</Typography>
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <ReadingsTable meteringPoint={reading.meteringPoint} showActions={false}/>
        </Grid>
      </Grid>
    </>
  );
}
