import { translateMagnitude } from './magnitudeUtils';
import * as yup from 'yup';

export enum Magnitude {
  DEFAULT = 'DEFAULT',
  KILO = 'KILO',
  MEGA = 'MEGA',
  GIGA = 'GIGA'
}

export const MAGNITUDES = Object.values(Magnitude).map(k => Magnitude[k]);

export function formatUnit(unit: Unit): string {
  return translateMagnitude(unit.defaultMagnitude) + unit.code;
}

export function formatCurrency(amount: number): string {
  return `${amount} ${CurrencyCode.EUR}`;
}

export class Unit {
  id: number;
  code: string;
  description: string;
  defaultMagnitude: Magnitude;
  ownerId: string;
}

export function unitSchema(): yup.SchemaOf<Unit> {
  return yup.object({
    id: yup.number().defined(),
    code: yup.string().required(),
    description: yup.string().defined(),
    defaultMagnitude: yup.mixed().defined(),
    ownerId: yup.string().defined()
  });
}

export type UnitCode = 'Wh' | 'W' | 'm³' | 't' | 'l' | 'm' | '%'

export enum UnitCodes {
  Wh = 'Wh',
  CUBIC_METER = 'm³',
  METER = 'm',
  TON = 't',
  LITRE = 'l',
  PERCENTAGE = '%'
}

export enum CurrencyCode {
  EUR = '€'
}

export class PlainUnit {
  code: string;
}
