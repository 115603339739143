import { Controller, useFormContext } from 'react-hook-form';
import * as React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { SelectProps } from '@material-ui/core/Select/Select';
import { retrieveError } from './Forms';

export function CustomSelect<LIST_TYPE>(props: {
  name: string,
  label: string,
  list: LIST_TYPE[];
  getListLabel?: (entry: LIST_TYPE) => string;
  getListKey?: (entry: LIST_TYPE) => string | number;
  getListValue?: (entry: LIST_TYPE) => string | number | LIST_TYPE;
  noSelectionText?: string
} & Partial<Omit<SelectProps, 'onChange' | 'onBlur' | 'value' | 'name'>>) {
  const {name, label, list, getListLabel, getListKey, getListValue, noSelectionText, ...rest} = props;
  const {control, formState: {errors}} = useFormContext<any>();
  const errorMessage = retrieveError(name, errors);

  function getListKeyOrDefault(value: any) {
    let result: string | number = '';
    if (value) {
      if (getListKey) {
        result = getListKey(value);
      } else {
        result = value.toString();
      }
    }
    if (!result) {
      result = '';
    }
    return result;
  }

  function getListLabelOrDefault(value: LIST_TYPE) {
    if (!value || list.length === 0) {
      return '';
    }
    if (!getListLabel) {
      return (value as any).toString();
    }
    return getListLabel(value);
  }

  return (
    <Controller
      name={name}
      control={control}
      render={renderProps =>
        <FormControl error={!!errorMessage} fullWidth={true} required={props.required || false} id={'select_' + name}>
          <InputLabel htmlFor={'select_' + name}>{label}</InputLabel>
          <Select
            name={renderProps.field.name}
            onChange={event => {
              const e = event as {} as React.ChangeEvent<HTMLSelectElement>;
              let value = list.find((entry: LIST_TYPE) => getListKeyOrDefault(entry) === e.target.value);
              if (value) {
                renderProps.field.onChange(value);
              }
            }
            }
            onBlur={renderProps.field.onBlur}
            value={getListKeyOrDefault(renderProps.field.value)}
            error={!!errorMessage}
            {...rest}
          >
            <MenuItem value={''} disabled={true} key={-1}>{noSelectionText || '---'}</MenuItem>
            {list.map((item) =>
              <MenuItem
                id={'option_' + getListKeyOrDefault(item)}
                value={getListKeyOrDefault(item)}
                key={getListKeyOrDefault(item)}
              >{getListLabelOrDefault(item)}
              </MenuItem>)}
          </Select>
          <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
      }
    />
  );
}
