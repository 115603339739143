import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import axios, { AxiosResponse } from 'axios';
import { HomemeterConfig } from './config';
import { AUTH_REDIRECT_PATH, AUTH_SILENT_REDIRECT_PATH } from '../../components/router/AppRouter';

export function getOidcConfiguration(): Promise<UserManagerSettings> {
  return axios.create().get('/api/v1/config').then((response: AxiosResponse<HomemeterConfig>) => {
    const config = response.data;
    const oidcConfig = {
      client_id: config.oauthClientId,
      authority: config.oauthUrl,
      scope: 'openid',
      redirect_uri: `${window.location.origin}${AUTH_REDIRECT_PATH}`,
      silent_redirect_uri: `${window.location.origin}${AUTH_SILENT_REDIRECT_PATH}`,
      post_logout_redirect_uri: window.location.origin,
      response_type: 'code',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      accessTokenExpiringNotificationTime: 30,
      automaticSilentRenew: true,
      loadUserInfo: false,
      monitorSession: false
    } as UserManagerSettings;
    return oidcConfig;
  });
}
