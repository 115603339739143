import * as yup from 'yup';
import { TimeUnit } from '../shared/technical/dateTime/TimeUnit';
import { Magnitude, Unit, unitSchema } from '../units/Unit';
import { Substance } from '../units/Substance';
import { EntityRef } from '../shared/technical/rest/pageable';
import { translation } from '../i18n';
import { ValidityInfo, validityInfoDateOnlySchema } from '../shared/technical/validity/ValidityInfo';

export interface TariffProvider {
  id: number,
  version: number,
  name: string,
  owner?: EntityRef
}

export function newTariffProvider() {
  return {} as TariffProvider;
}

export function tariffProviderSchema(): yup.SchemaOf<TariffProvider> {
  return yup.object({
    id: yup.number(),
    version: yup.number(),
    name: yup.string().required()
  }).defined() as unknown as yup.SchemaOf<TariffProvider>;
}

export interface Money {
  amount: number,
  currencyCode: string;
}

export function moneySchema(parentEntity: string): yup.SchemaOf<Money> {
  return yup.object({
    amount: yup.number().required(getRequiredMessage(parentEntity)),
    currencyCode: yup.string().default(DEFAULT_CURRENCY_CODE).defined()
  });
}

export const DEFAULT_CURRENCY_CODE = 'EUR';

export interface Tariff {
  id: number,
  version: number,
  name: string,
  basicRate: Money,
  basicRatePeriod: TimeUnit,
  rate: Money,
  rateUnit: Unit,
  rateMagnitude: Magnitude,
  substance: Substance,
  tariffProvider: TariffProvider,
  validityInfo: ValidityInfo,
  private: boolean
}

export function newTariff() {
  return {} as Tariff;
}

let getRequiredMessage = function (entity: string) {
  return (params) => translation.t('validation.required', {'field': translation.t(entity + '.' + params.path)});
};

export function tariffSchema(): yup.SchemaOf<Tariff> {
  return yup.object({
    id: yup.number(),
    version: yup.number(),
    name: yup.string().required(getRequiredMessage('Tariff')),
    basicRate: moneySchema('Tariff').required(getRequiredMessage('Tariff')),
    basicRatePeriod: yup.mixed<TimeUnit>().required(getRequiredMessage('Tariff')),
    rate: moneySchema('Tariff').required(getRequiredMessage('Tariff')),
    rateUnit: unitSchema().required(getRequiredMessage('Tariff')),
    rateMagnitude: yup.mixed<Magnitude>().required(getRequiredMessage('Tariff')),
    substance: yup.mixed<Substance>().required(getRequiredMessage('Tariff')),
    tariffProvider: tariffProviderSchema().required(getRequiredMessage('Tariff')),
    validityInfo: validityInfoDateOnlySchema(),
    private: yup.boolean()
  }).defined() as unknown as yup.SchemaOf<Tariff>;
}

export function defaultTariff() {
  return {
    basicRate: {currencyCode: DEFAULT_CURRENCY_CODE},
    basicRatePeriod: TimeUnit.MONTH,
    rate: {currencyCode: DEFAULT_CURRENCY_CODE}
  } as Tariff;
}

