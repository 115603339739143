import { Magnitude } from './Unit';

const DEFAULT_MAG = 1;
const KILO_MAG = DEFAULT_MAG * 1000;
const MEGA_MAG = KILO_MAG * 1000;
const GIGA_MAG = MEGA_MAG * 1000;

export function formatValue(value: number, code: string, magnitudes?: Magnitude[]): string {
  let magnitude = magnitudeFor(value, magnitudes);
  value = applyMagnitude(magnitude, value);
  const rounded = parseFloat(value.toFixed(1));
  return rounded + translateMagnitude(magnitude) + code;
}

export function formatPercentage(value: number): string {
  const percentage = value * 100;
  return percentage.toFixed(1) + '%';
}

export function formatMagnitizedValue(code: string, magnitude: Magnitude, value: number): string {
  const rounded = parseFloat(value.toFixed(1));
  return rounded + translateMagnitude(magnitude) + code;
}

export function applyMagnitude(magnitude: Magnitude, value: number): number {
  if (!value) {
    return value;
  }
  switch (magnitude) {
    case Magnitude.GIGA:
      return value / GIGA_MAG;
    case Magnitude.MEGA:
      return value / MEGA_MAG;
    case Magnitude.KILO:
      return value / KILO_MAG;
    default:
      return value;
  }
}

export function undoMagnitude(magnitude: Magnitude | undefined, value: number): number {
  switch (magnitude) {
    case Magnitude.GIGA:
      return value * GIGA_MAG;
    case Magnitude.MEGA:
      return value * MEGA_MAG;
    case Magnitude.KILO:
      return value * KILO_MAG;
    default:
      return value;
  }
}

export function magnitudeFor(value: number, magnitudes?: Magnitude[]): Magnitude {
  if (!magnitudes) {
    if (value > GIGA_MAG) {
      return Magnitude.GIGA;
    } else if (value > MEGA_MAG) {
      return Magnitude.MEGA;
    } else if (value > KILO_MAG) {
      return Magnitude.KILO;
    }
    return Magnitude.DEFAULT;
  }
  if (value > GIGA_MAG && magnitudes.find(magnitude => magnitude === Magnitude.GIGA)) {
    return Magnitude.GIGA;
  } else if (value > MEGA_MAG && magnitudes.find(magnitude => magnitude === Magnitude.MEGA)) {
    return Magnitude.MEGA;
  } else if (value > KILO_MAG && magnitudes.find(magnitude => magnitude === Magnitude.KILO)) {
    return Magnitude.KILO;
  }
  return Magnitude.DEFAULT;

}

export function translateMagnitude(magnitude: Magnitude): string {
  switch (magnitude) {
    case Magnitude.GIGA:
      return 'G';
    case Magnitude.MEGA:
      return 'M';
    case Magnitude.KILO:
      return 'k';
    default:
      return '';
  }
}
