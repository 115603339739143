import { FormItem, FormSection } from '../shared/components/forms/Forms';
import { CustomSelect } from '../shared/components/forms/CustomSelect';
import { DELTA_VALUES_OPERATORS, DeltaValueOperator } from './Meter';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getMeteringPoints } from './MeteringPointApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { CustomCheckbox } from '../shared/components/forms/CustomCheckbox';
import { MeteringPoint } from './MeteringPoint';

export function MeterCalculationForm(props: {}) {
  const {getValues} = useFormContext<MeteringPoint>();
  const {t} = useTranslation();
  const customAxios = useAxios();
  const [meteringPoints, setMeteringPoints] = useState<MeteringPoint[]>();
  const meteringPoint: MeteringPoint = getValues();

  useEffect(() => {
    getMeteringPoints(customAxios).then((newMeteringPoints) => {
      const filtered = newMeteringPoints.filter((curr) => curr.id !== meteringPoint.id);  // Remove ourself from list
      setMeteringPoints(filtered);
    });
  }, [customAxios, meteringPoint.id]);

  return (
    <FormSection title={t('Meter.titleMeterCalculation')}>
      <FormItem key={'meteringPointLeft'}>
        <CustomSelect
          name={'activeMeter.meterCalculation.meteringPointLeft'}
          label={t('MeterCalculation.meteringPointLeft')}
          required={true}
          list={meteringPoints || []}
          getListKey={(entry: MeteringPoint) => entry.id}
          getListLabel={(entry: MeteringPoint) => entry.name}
          getListValue={(entry: MeteringPoint) => ({id: entry.id} as MeteringPoint)}
        />
      </FormItem>
      <FormItem key={'operator'}>
        <CustomSelect
          name={'activeMeter.meterCalculation.operator'}
          label={''}
          required={true}
          list={DELTA_VALUES_OPERATORS}
          getListKey={(entry: DeltaValueOperator) => entry}
          getListLabel={(entry: DeltaValueOperator) => t('DeltaValueOperator.' + entry)}
        />
      </FormItem>
      <FormItem key={'meteringPointRight'}>
        <CustomSelect
          name={'activeMeter.meterCalculation.meteringPointRight'}
          label={t('MeterCalculation.meteringPointRight')}
          required={true}
          list={meteringPoints || []}
          getListKey={(entry: MeteringPoint) => entry.id}
          getListLabel={(entry: MeteringPoint) => entry.name}
          getListValue={(entry: MeteringPoint) => ({id: entry.id} as MeteringPoint)}
        />
      </FormItem>
      <FormItem key={'useEarliestMeterBegin'}>
        <CustomCheckbox
          name={'activeMeter.meterCalculation.useEarliestMeterBegin'}
          label={t('MeterCalculation.useEarliestMeterBegin')}
        />
      </FormItem>
    </FormSection>
  );
}
