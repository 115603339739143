import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormSection, OpenState, submitForm } from '../../../shared/components/forms/Forms';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogTitle, FormHelperText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { MY_RENAULT_FORM_ID, MyRenaultLoginForm } from './MyRenaultLoginForm';
import { RenaultConnectionStatus, RenaultLogin } from './RenaultAdapter';
import { useAxios } from '../../../shared/technical/rest/AxiosProvider';
import { connectMyRenault, getRenaultConnectionStatus, refreshMyRenault, unConnectMyRenault } from './RenaultAdapterApi';
import { StaticTextForm } from '../../../shared/components/forms/StaticTextForm';
import { LongText } from '../../../shared/components/text/LongText';
import { errorToString, ValidationErrorResponse } from '../../../shared/technical/rest/validation';
import { VehicleWithStatus } from '../../Vehicle';
import { DeleteButton } from '../../../shared/components/buttons/DeleteButton';

export function RenaultAdapterDialog(props: {} & OpenState) {
  const {t} = useTranslation();
  const customAxios = useAxios();

  const [vehicles, setVehicles] = useState<VehicleWithStatus[]>([]);
  const [connectionStatus, setConnectionStatus] = useState<RenaultConnectionStatus>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    getRenaultConnectionStatus(customAxios).then((result) => {
      setConnectionStatus(result);
      setVehicles(result.vehicles);
    });
  }, [customAxios]);

  function handleClose() {
    props.close();
  }

  function handleRefresh() {
    refreshMyRenault(customAxios).then((response) => {
      if ((response as ValidationErrorResponse).errors) {
        setError((response as ValidationErrorResponse).errors.map(errorToString).join(' - '));
      } else {
        setVehicles(response as VehicleWithStatus[]);
        setConnectionStatus({connected: true, vehicles: response as VehicleWithStatus[]});
      }
    })
      .catch((error) => {
        setError('Konnte nicht zu My Renault verbinden!' + error);
        console.log('Could not connect to my renault');
      });
  }

  function handleDelete() {
    unConnectMyRenault(customAxios).then(() => props.close());
  }

  function handleSave(renaultLogin: RenaultLogin) {
    connectMyRenault(customAxios, renaultLogin)
      .then((response) => {
        if ((response as ValidationErrorResponse).errors) {
          setError((response as ValidationErrorResponse).errors.map(errorToString).join(' - '));
        } else {
          setVehicles(response as VehicleWithStatus[]);
          setConnectionStatus({connected: true, vehicles: response as VehicleWithStatus[]});
        }
      })
      .catch((error) => {
        setError('Konnte nicht zu My Renault verbinden!' + error);
        console.log('Could not connect to my renault');
      });
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t('RenaultAdapterDialog.title')}</DialogTitle>
      <DialogContent>
        <LongText i18nKey={'RenaultAdapterDialog.description'}/>
        {connectionStatus && !connectionStatus.connected && <MyRenaultLoginForm onSave={handleSave}/>}
        {error &&
        <FormHelperText error={true}>{error}</FormHelperText>
        }
        {connectionStatus && connectionStatus.connected && vehicles.map(vehicleWithStatus => {
          const vehicle = vehicleWithStatus.vehicle;
          return (
            <FormSection key={vehicle.vin}>
              <StaticTextForm label={t('Vehicle.brand')} field={'brand'} value={vehicle.brand}/>
              <StaticTextForm label={t('Vehicle.model')} field={'model'} value={vehicle.model}/>
              <StaticTextForm label={t('Vehicle.vin')} field={'vin'} value={vehicle.vin}/>
            </FormSection>
          );
        })
        }
      </DialogContent>
      <DialogActions>
        {(connectionStatus && connectionStatus.connected) &&
        <DeleteButton onClick={() => handleDelete()}/>
        }
        {(!connectionStatus || !connectionStatus.connected) &&
        <Button id="edit_renault_cancel_button" variant={'contained'} onClick={() => handleClose()}>
          {t('actions.cancel')}
        </Button>
        }
        {(connectionStatus && connectionStatus.connected) &&
        <Button id="edit_renault_refresh_button" variant={'contained'} onClick={() => handleRefresh()}>
          {t('actions.refresh')}
        </Button>
        }
        <Button id="edit_renault_save_button" variant={'contained'} color="primary" onClick={() => {
          if (!connectionStatus || !connectionStatus.connected) {
            submitForm(MY_RENAULT_FORM_ID);
          } else {
            handleClose();
          }
        }}>
          {t('actions.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
