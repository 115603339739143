import { AxiosInstance } from 'axios';
import { Meter } from './Meter';
import { createOne, queryList, queryOne, updateOne } from '../shared/technical/rest/restApi';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';

export function getMeter(customAxios: AxiosInstance, meterId: string | number): Promise<Meter> {
  return queryOne<Meter>(customAxios, 'meters', meterId);
}

export function addNewMeter(customAxios: AxiosInstance, meteringPointId: string | number, meter: Meter): Promise<Meter | ValidationErrorResponse> {
  return createOne<Meter>(customAxios, 'metering-points/' + meteringPointId + '/meter', meter);
}

export function getAllMeter(customAxios: AxiosInstance, meteringPointId: string | number): Promise<Meter[]> {
  return queryList<Meter>(customAxios, 'metering-points/' + meteringPointId + '/meter');
}

export function updateMeter(customAxios: AxiosInstance, meter: Meter): Promise<Meter | ValidationErrorResponse> {
  return updateOne(customAxios, 'meters', meter);
}
