import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TariffProviderForm from './TariffProviderForm';
import { createTariffProvider } from './tariffProviderApi';
import { useHistory } from 'react-router';
import { TARIFF_PROVIDER_MAPPING } from '../shared/components/router/AppRouter';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { newTariffProvider } from './TariffProvider';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function NewTariffProviderPage(props: {}) {
  const history = useHistory();
  const customAxios = useAxios();
  const {t} = useTranslation();
  const {} = useAppBarSetting(t('TariffProvider.titleNew'));

  return (
    <>
      <TariffProviderForm tariffProvider={newTariffProvider()} onSave={(tariffProvider => {
        createTariffProvider(customAxios, tariffProvider).then(() => history.push(TARIFF_PROVIDER_MAPPING));
      })}/>
    </>
  );
}
