import * as React from 'react';
import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { TimeUnit } from '../shared/technical/dateTime/TimeUnit';
import { DeltaValue, DeltaValues } from '../values/DeltaValues';
import { ACCENT_COLOR } from '../styles/appTheme';
import { translateMagnitude } from '../units/magnitudeUtils';
import { log } from '../logConfig';
import { colors } from '@material-ui/core';
import { getDefaultMagnitude, mainValueChartDataField, mapDeltaValuesToChartData, valuesChartDataField } from './ChartData';
import { Magnitude, PlainUnit } from '../units/Unit';
import { MeteringPoint } from '../meters/MeteringPoint';

interface Props {
  mainValues: DeltaValues;
  additionalValues?: DeltaValues[];
  meteringPoint: MeteringPoint;
  resolution?: TimeUnit;
  unit?: PlainUnit;
  showGrid?: boolean;
  showLabel?: boolean;
  showStacked?: boolean;
  handleChartClick?: (data: DeltaValue, index: number) => void;
}

const additionalColors = [colors.blue['100'], colors.blue['700'], colors.blue['900']];
const stackField = 'stack';

let getUnit = function (magnitude: Magnitude, props: Props) {
  let translatedMagnitude = translateMagnitude(magnitude);
  let unitCode = props.unit?.code || props.meteringPoint.unit.code;
  let unit = translatedMagnitude + unitCode;
  if (unit.length > 4) {  // We have kWh/100km as Magnitude, but it destroys the layout
    return unit.substring(0, 3) + '..';
  }
  return unit;
};

export function ReadingsBarChart(props: Props) {
  log.trace('Inside ReadingsChart...');

  const magnitude = getDefaultMagnitude(props.mainValues);
  const chartData = useMemo(() => {
    return mapDeltaValuesToChartData(props.mainValues, props.additionalValues || [], magnitude);
  }, [props.mainValues, props.additionalValues, magnitude]);

  function handleChartClick(param: { activeLabel: string }) {
    if (!param || !param.activeLabel) {
      log.warn('No activeLabel given! param: ' + JSON.stringify(param));
      return;
    }
    const index = chartData.findIndex(value => value.period === param.activeLabel);
    let deltaValue = props.mainValues.values[index];
    if(!deltaValue) {
      return;
    }
    props.handleChartClick && props.handleChartClick(deltaValue, index);
  }

  const mainName = props.mainValues.name;
  const unit = getUnit(magnitude, props);
  return (
    <ResponsiveContainer minHeight={100} height={'50%'}>
      <BarChart data={chartData} onClick={handleChartClick}>
        {props.showGrid && <CartesianGrid strokeDasharray="3 3"/>}
        <XAxis dataKey="period"/>
        <YAxis unit={unit} type="number" domain={[0, 'dataMax']} allowDecimals={true}/>
        <Tooltip/>
        <Bar dataKey={valuesChartDataField + '.' + mainValueChartDataField}
             name={mainName}
             unit={unit}
             fill={ACCENT_COLOR}
             key={mainValueChartDataField}
             label={!!props.showLabel}
        />
        {props.additionalValues && props.additionalValues.map((value, index) => {
          return <Bar dataKey={valuesChartDataField + '.' + index}
                      name={value.name || 'Kein Name'}
                      key={index}
                      label={!!props.showLabel}
                      fill={additionalColors[index]}
                      stackId={props.showStacked ? stackField : index}
          />;
        })}
        {props.additionalValues &&
        <Legend/>
        }
      </BarChart>
    </ResponsiveContainer>
  );
}
