import { Controller, useFormContext } from 'react-hook-form';
import * as React from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker';
import { TimeUnit } from '../../technical/dateTime/TimeUnit';
import { retrieveError } from './Forms';

export function CustomDateTimePicker(props: {
  name: string
} & Partial<Omit<KeyboardDatePickerProps, 'onChange' | 'value' | 'name'>>) {
  const {name, ...rest} = props;
  const {control, formState: {errors}} = useFormContext<any>();
  const errorMessage = retrieveError(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      render={renderProps =>
        <KeyboardDateTimePicker
          name={renderProps.field.name}
          onChange={renderProps.field.onChange}
          onBlur={renderProps.field.onBlur}
          value={renderProps.field.value || ''}
          format={props.format ? props.format : 'dd.MM.yyyy HH:mm:ss'}
          ampm={false}
          variant={'dialog'}
          clearable={true}
          fullWidth={true}
          error={!!errorMessage}
          helperText={errorMessage}
          {...rest}
        />
      }
    />
  );
}

export type DateTimeViewType = ('year' | 'date' | 'month' | 'hours' | 'minutes')[]
export type DateViewType = ('year' | 'month' | 'date')[]

export function timeUnitToDateTimeViews(timeUnit: TimeUnit): DateTimeViewType {
  switch (timeUnit) {
    case TimeUnit.CENTURY:
    case TimeUnit.YEAR:
      return ['year'];
    case TimeUnit.QUARTER:
    case TimeUnit.MONTH:
      return ['month'];
    case TimeUnit.WEEK:
    case TimeUnit.DAY:
      return ['date'];
    case TimeUnit.HOUR:
    case TimeUnit.MINUTE:
      return ['hours', 'minutes'];
    case TimeUnit.SECOND:
      return [];
    default:
      return [];
  }
}

export function timeUnitToDateViews(timeUnit: TimeUnit): DateViewType {
  switch (timeUnit) {
    case TimeUnit.CENTURY:
    case TimeUnit.YEAR:
      return ['year'];
    case TimeUnit.QUARTER:
    case TimeUnit.MONTH:
      return ['month'];
    case TimeUnit.WEEK:
    case TimeUnit.DAY:
      return ['date'];
    case TimeUnit.HOUR:
    case TimeUnit.MINUTE:
    case TimeUnit.SECOND:
      return [];
    default:
      return [];
  }
}
