import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import Select, { SelectProps } from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';

interface DropdownFormInputProps<TYPE, LIST_TYPE> {
  label: string;
  field: string;
  entity?: TYPE;
  list: LIST_TYPE[];
  required?: boolean;
  disabled?: boolean;
  setValue?: (value: LIST_TYPE) => void;
  getValue?: () => LIST_TYPE | undefined;
  value?: LIST_TYPE;
  getListLabel(entry: LIST_TYPE): string;
  getListKey(entry: LIST_TYPE): string;
}

export function DropDownFormInput<TYPE, LIST_TYPE>(props: DropdownFormInputProps<TYPE, LIST_TYPE>) {
  const [position, setPosition] = useState<string>();

  useEffect(() => {
    let value = getValue(props);
    setPosition(getPosition(props.list, props.getListKey, value));
  }, [props]);

  const {label, field, list, required} = props;
  return (
    <FormControl error={false} fullWidth={true} required={required || false} id={'select_' + field}>
      <InputLabel shrink={position !== undefined} htmlFor={'select_' + field}>{label}</InputLabel>
      <Select
        id={'select_' + field}
        name={'select_' + field}
        value={position || ''}
        renderValue={(currValue: SelectProps['value']) => {
          if (currValue !== 0 && (!currValue || currValue === '-1' || currValue === true)) {
            return '-';
          }
          let intValue = currValue as number;
          let item = list[intValue];
          return props.getListLabel(item);
        }}
        onChange={event => {
          const e = event as {} as React.ChangeEvent<HTMLSelectElement>;
          let value = list[e.target.value];
          setPosition(e.target.value);
          if (props.setValue) {
            props.setValue && props.setValue(value);
          }
          if (props.entity) {
            props.entity && (props.entity[field] = value);
          }
        }}
        input={<Input type={'text'}/>}
        error={false}
        disabled={props.disabled ? props.disabled : false}
        fullWidth={true}
      >
        <MenuItem value={''} disabled={true} key={-1}>Bitte wählen...</MenuItem>
        {list.map((item, i) =>
          <MenuItem
            id={'option_' + props.getListKey(item)}
            value={i}
            key={i}
          >{props.getListLabel(item)}
          </MenuItem>)}
      </Select>
      <FormHelperText>{}</FormHelperText>
    </FormControl>
  );
}

function getPosition<LIST_TYPE>(list: LIST_TYPE[], listKeyExtractor: (item: LIST_TYPE) => string, listItem?: LIST_TYPE): string {
  if (!listItem) {
    return '';
  }
  let listKeys = list.map(item => listKeyExtractor(item));
  let searchElement = listKeyExtractor(listItem);
  return listKeys.lastIndexOf(searchElement) + '';
}

function getValue<LIST_TYPE>(props: DropdownFormInputProps<{}, LIST_TYPE>): LIST_TYPE | undefined {
  if (props.entity && props.field) {
    return props.entity[props.field] || undefined;
  }
  if (props.getValue) {
    return props.getValue() || undefined;
  } else if (props.value) {
    return props.value;
  }
  return undefined;
}
