import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BillsTable } from './BillsTable';
import { BillFormDialog } from './BillFormDialog';
import { Bill, defaultBill } from './Bill';
import { emptyPage, PagedResult } from '../shared/technical/rest/pageable';
import { deleteBill, getBills } from './BillApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { AddButton } from '../shared/components/buttons/AddButton';
import { getMeteringPoint } from '../meters/MeteringPointApi';
import { MeteringPoint } from '../meters/MeteringPoint';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function BillsPage(props: {
  meteringPointId: string
}) {
  const {t} = useTranslation();
  const {setTitle} = useAppBarSetting();
  const customAxios = useAxios();

  const [billFormDialogOpen, setBillFormDialogOpen] = useState<boolean>(false);
  const [bill, setBill] = useState<Bill>();
  const [bills, setBills] = useState<PagedResult<Bill>>(emptyPage);
  const [meteringPoint, setMeteringPoint] = useState<MeteringPoint>();

  useEffect(() => {
    getMeteringPoint(customAxios, props.meteringPointId).then((meteringPoint) => {
      setMeteringPoint(meteringPoint);
      setTitle(t('BillsPage.title') + ' - ' + meteringPoint?.name);
    });
  }, [props.meteringPointId, customAxios, setTitle, t]);

  useEffect(() => {
    getBills(customAxios, props.meteringPointId).then((page) => setBills(page));
  }, [customAxios, props.meteringPointId]);

  return (
    <>
      <BillsTable meteringPointId={props.meteringPointId}
                  bills={bills}
                  onDelete={billId => {
                    deleteBill(customAxios, props.meteringPointId, billId).then(() =>
                      setBills(curr => {
                        return curr && {...curr, content: curr.content.filter(currProvider => currProvider.id !== billId)};
                      })
                    );
                  }}
                  onClick={(selectedBill) => {
                    setBill(selectedBill);
                    setBillFormDialogOpen(true);
                  }}/>
      <AddButton
        onClick={() => {
          setBill(defaultBill());
          setBillFormDialogOpen(true);
        }}
      />

      {bill &&
        <BillFormDialog meteringPointId={props.meteringPointId} bill={bill} open={billFormDialogOpen} close={() => {
          setBillFormDialogOpen(false);
          getBills(customAxios, props.meteringPointId).then((page) => setBills(page));
        }}/>}

    </>
  );
}
