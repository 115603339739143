import { EntityRef } from '../shared/technical/rest/pageable';
import { MeterType } from '../meterTypes/MeterType';
import { Magnitude, Unit } from '../units/Unit';
import { ValidityInfo, validityInfoSchema } from '../shared/technical/validity/ValidityInfo';
import * as yup from 'yup';
import { ConcreteValueReading } from '../readings/Reading';
import { DeltaValues } from '../values/DeltaValues';
import { Meter, MeterEntry, meterSchema } from './Meter';
import { translateMagnitude } from '../units/magnitudeUtils';
import { TariffSubscription, tariffSubscriptionSchema } from '../provider/TariffSubscription';

export interface MeteringPoint extends EntityRef {
  name: string;
  type: MeterType;
  unit: Unit;
  alternativeUnit?: Unit;
  magnitude: Magnitude;
  location?: string;
  activeMeter: Meter;
  activeTariffSubscription?: TariffSubscription;
  validityInfo: ValidityInfo;
}

export function newMeteringPoint() {
  return {} as MeteringPoint;
}

export function meteringPointSchema(): yup.SchemaOf<MeteringPoint> {
  return yup.object({
    id: yup.string(),
    name: yup.string().required(),
    type: yup.mixed<MeterType>().defined(),
    unit: yup.mixed<Unit>(),
    alternativeUnit: yup.mixed<Unit>(),
    magnitude: yup.mixed(),
    location: yup.string(),
    activeMeter: meterSchema(),
    activeTariffSubscription: tariffSubscriptionSchema().notRequired(),
    validityInfo: validityInfoSchema()
  }).defined() as unknown as yup.SchemaOf<MeteringPoint>;
}

export interface MeteringPointOverview extends MeteringPoint {
  position: number;
  lastReading: ConcreteValueReading;
  deltaValues: DeltaValues;
}

export interface MeteringPointEntry {
  id: number,
  position: number,
  name: string,
  magnitude: Magnitude,
  location: string
  activeMeter: MeterEntry
}

export function magnitizedUnit(meter: MeteringPoint) {
  return translateMagnitude(meter.magnitude) + meter.unit.code;
}
