import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Settings } from 'luxon';

export function initI18n() {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      debug: true,
      supportedLngs: ['de'],
      fallbackLng: 'de',
      returnObjects: true,
      react: {},
      interpolation: {
        escapeValue: false
      }
    })
    .then(() => {
      Settings.defaultLocale = i18n.language;
    })
    .catch(console.error);
}

export const translation = i18n;
