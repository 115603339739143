import { useHistory } from 'react-router';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { PRIMARY_COLOR } from '../styles/appTheme';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { CATEGORIES_MAPPING } from '../shared/components/router/AppRouter';
import Typography from '@material-ui/core/Typography/Typography';
import { EnergyCategory } from './EnergyCategory';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getEnergyCategories } from './CategoryApi';
import { useAppStyle } from '../styles/appStyle';
import { Loading } from '../shared/components/Loading';
import { useTranslation } from 'react-i18next';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function Dashboard() {
  const {t} = useTranslation();
  const customAxios = useAxios();
  const history = useHistory();
  const classes = useAppStyle();
  const {setTitle} = useAppBarSetting(t('title'));

  const [categories, setCategories] = useState<EnergyCategory[]>();

  useEffect(() => {
    getEnergyCategories(customAxios).then(setCategories);
  }, [customAxios]);
  if (!categories) {
    return (
      <>
        <Loading/>
      </>
    );
  }
  return (
    <>
      <Grid id={'cards'} container={true} spacing={2}>
        {categories.map((category) => {
          return (
            <Grid item={true} key={category.id} xs={12} sm={10} md={8} lg={6}>
              <Card
                id={'category_' + category.name}
                style={{background: PRIMARY_COLOR, cursor: 'pointer'}}
                onClick={() => {
                  history.push(`${CATEGORIES_MAPPING}/${category.id}`);
                }}
              >
                <CardHeader
                  title={<Typography variant={'h6'} className={classes.whiteColored}>{category.displayName}</Typography>}
                  titleTypographyProps={{color: 'secondary'}}
                  avatar={<img alt={category.displayName} src={category.name.toLowerCase() + '.svg'} width={100} height={100}/>}
                />

              </Card>
            </Grid>
          );
        })
        }
      </Grid>
    </>
  );
}
