import * as React from 'react';
import { useEffect } from 'react';
import { useAccount } from './AccountProvider';
import { Loading } from '../shared/components/Loading';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import Typography from '@material-ui/core/Typography';
import { deleteAccount } from './AccountApi';
import { useUserManager } from '../shared/technical/security/UserManagerProvider';
import { CustomAppBar } from '../shared/components/appbar/CustomAppBar';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function AccountDeletionPage(props: { token: string }) {
  const account = useAccount();
  const axios = useAxios();
  const {signOut} = useUserManager();
  const {t} = useTranslation();
  const {} = useAppBarSetting(t('AccountDeletionPage.title'));

  useEffect(() => {
    if (account && signOut) {
      deleteAccount(axios, props.token).then(() => setTimeout(() => signOut(), 5000));
    }
  }, [axios, account, props.token, signOut]);

  if (!account) {
    return (
      <Loading/>
    );
  } else {
    return (
      <>
        <CustomAppBar icon={'BACK'}/>
        <div>
          <Typography variant={'h3'}>{t('AccountDeletionPage.deletionConfirmation')}</Typography>
        </div>
      </>
    );
  }
}
