import * as React from 'react';
import { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { HeatPumpData } from './HeatPumpData';
import { Typography } from '@material-ui/core';
import { formatValue } from '../units/magnitudeUtils';
import { DateTime } from 'luxon';
import { DropDownFormInput } from '../shared/components/forms/DropDownFormInput';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getHeatPumpData } from './HeatPumpDataApi';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function HeatPumpPage(props: {}) {
  const {t} = useTranslation();
  const [heatPumpData, setHeatPumpData] = useState<HeatPumpData>();
  const [year, setYear] = useState('' + DateTime.local().year);
  const customAxios = useAxios();
  const {} = useAppBarSetting(t('HeatPumpPage.title'));

  const years = ['2017', '2018', '2019', '2020', '2021', '2022'];

  useEffect(() => {
    getHeatPumpData(customAxios, year).then(setHeatPumpData);
  }, [year, customAxios]);

  return (
    <>
      <Paper elevation={0} style={{marginBottom: 10, marginTop: 10, padding: 16}}>
        <DropDownFormInput
          label={t('HeatPumpPage.year')}
          field={'year'}
          list={years}
          getListLabel={year => year}
          getListKey={year => year}
          getValue={() => year}
          setValue={setYear}/>
        <Typography
          variant={'body1'}>{t('HeatPumpPage.totalElectricityConsumed')}{heatPumpData && formatValue(heatPumpData.totalElectricityConsumed, 'Wh')}</Typography>
        <Typography
          variant={'body1'}>{t('HeatPumpPage.totalHeatProduced')}{heatPumpData && formatValue(heatPumpData.totalHeatProduced, 'Wh')}</Typography>
        <Typography
          variant={'body1'}>{t('HeatPumpPage.coefficientOfPerformance')}{heatPumpData && (heatPumpData.totalHeatProduced / heatPumpData.totalElectricityConsumed).toFixed(1)}</Typography>
      </Paper>
    </>
  );
}
