import * as React from 'react';
import { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a9b5f022a2454e97abc406d9878b2514@sentry.io/1412039',
    release: process.env.REACT_APP_GIT_TAG,
    environment: window.location.hostname
  });
  Sentry.configureScope(scope => {
    scope.setTag('git_tag', process.env.REACT_APP_GIT_TAG || 'undefined');
    scope.setTag('git_commit', process.env.REACT_APP_GIT_COMMIT || 'undefined');
  });
}

export function MyErrorBoundary(props: {
  children?: ReactNode;
}) {
  const {t} = useTranslation();

  function FallbackComponent() {
    return (
      <Dialog open={true}>
        <DialogTitle id="alert-dialog-title">{t('ErrorBoundaryDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('ErrorBoundaryDialog.text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.reload(true)} color="primary" autoFocus={true}>
            {t('ErrorBoundaryDialog.reloadButton')}
          </Button>
          <Button onClick={() => window.location.href = '/'} color="primary">
            {t('ErrorBoundaryDialog.homeButton')}
          </Button>
          <Button onClick={() => Sentry.showReportDialog()}>{t('ErrorBoundaryDialog.feedbackButton')}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const fallbackComponent = <FallbackComponent/>;

  return (
    <Sentry.ErrorBoundary showDialog={true} fallback={fallbackComponent}>{props.children}</Sentry.ErrorBoundary>
  );
}
