import * as React from 'react';
import { useEffect, useState } from 'react';
import { TariffProvider } from './TariffProvider';
import { useTranslation } from 'react-i18next';
import { deleteTariffProvider, getTariffProviders } from './tariffProviderApi';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { muiDataTablesLabels } from '../shared/components/tables/muiDataTableUtils';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router';
import Button from '@material-ui/core/Button';
import { tariffProviderEditMapping, tariffsMapping } from '../shared/components/router/AppRouter';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { useAccount } from '../user/AccountProvider';

export function TariffProviderTable(props: {}) {
  const {t} = useTranslation();
  const history = useHistory();
  const customAxios = useAxios();
  const account = useAccount();

  const [tariffProviders, setTariffProviders] = useState<TariffProvider[]>([]);

  useEffect(() => {
    getTariffProviders(customAxios).then((page) => setTariffProviders(page.content));
  }, [customAxios]);

  function deleteIcon(id: number) {
    let owner = tariffProviders.find((provider) => provider.id == id)?.owner;
    console.info("Provider owner: " + (owner ? owner.id : ''));
    console.info("Account id: " + (account.id));
    console.info("Disabled: " + (!owner || (owner?.id !== account.id)));
    return <IconButton
      disabled={!owner || (owner?.id !== account.id)}
      onClick={(event) => {
        event.preventDefault();
        if (!window.confirm(t('actions.reallyDelete', {name: t('TariffProvider.title') + ' ' + id}))) {
          return;
        }
        deleteTariffProvider(customAxios, id).then(() => setTariffProviders(curr => curr.filter(currProvider => currProvider.id !== id)));
      }}><DeleteIcon/></IconButton>;
  }

  function tariffIcon(id: number) {
    return <Button variant={'outlined'} onClick={(event) => {
      event.preventDefault();
      const path = tariffsMapping('' + id);
      history.push(path);
    }}>{t('TariffProvider.tariffs')}</Button>;
  }

  const columns: MUIDataTableColumnDef[] = [
    {label: t('TariffProvider.id'), name: 'id'},
    {label: t('TariffProvider.name'), name: 'name'},
    {
      label: t('TariffProvider.tariffs'),
      name: 'id',
      options: {customBodyRender: tariffIcon}
    },
    {
      label: t('actions.delete'),
      name: 'id',
      options: {customBodyRender: deleteIcon}
    },
  ];

  function handleCellClick(colData: any, cellMeta: { colIndex: number; rowIndex: number; dataIndex: number; event: React.MouseEvent }) {
    if (cellMeta.colIndex > 1) {
      // Not clicked in ID, NAME
      return;
    }
    const tariffProvider = tariffProviders[cellMeta.dataIndex];
    const path = tariffProviderEditMapping('' + tariffProvider.id);
    history.push(path);
  }

  return (<>
    <MUIDataTable
      title={t('TariffProvider.title')}
      columns={columns}
      data={tariffProviders}
      options={{
        download: false,
        selectableRows: 'none',
        print: false,
        textLabels: muiDataTablesLabels(t),
        onCellClick: handleCellClick
      }}
    />
  </>);
}
