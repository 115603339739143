import { createOne, deleteOne, queryList, queryOne, updateOne } from '../shared/technical/rest/restApi';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';
import { AxiosInstance } from 'axios';
import { MeteringPoint, MeteringPointOverview } from './MeteringPoint';
import { DataPoint } from './DataPoint';

export function getMeteringPointOverviews(customAxios: AxiosInstance): Promise<MeteringPointOverview[]> {
  return queryList<MeteringPointOverview>(customAxios, 'metering-points-overview');
}

export function getMeteringPointOverviewForCategory(customAxios: AxiosInstance, categoryId: number): Promise<MeteringPointOverview[]> {
  return queryList<MeteringPointOverview>(customAxios, 'metering-points-overview', 'categoryId=' + categoryId);
}

export function getMeteringPointOverviewForUnit(customAxios: AxiosInstance, unit: string): Promise<MeteringPointOverview[]> {
  return queryList<MeteringPointOverview>(customAxios, 'metering-points-overview', 'unit=' + unit);
}

export function getMeteringPoints(customAxios: AxiosInstance,): Promise<MeteringPoint[]> {
  return queryList<MeteringPoint>(customAxios, 'metering-points');
}

export function getMeteringPoint(customAxios: AxiosInstance, meteringPointId: string | number): Promise<MeteringPoint> {
  return queryOne<MeteringPoint>(customAxios, 'metering-points', meteringPointId);
}

export function saveMeteringPoint(customAxios: AxiosInstance, meteringPoint: MeteringPoint): Promise<MeteringPoint | ValidationErrorResponse> {
  if (meteringPoint.activeTariffSubscription && !meteringPoint.activeTariffSubscription.validityInfo) {
    meteringPoint.activeTariffSubscription.validityInfo = {};
  }
  if (meteringPoint.id) {
    return updateOne<MeteringPoint>(customAxios, 'metering-points', meteringPoint);
  } else {
    return createOne<MeteringPoint>(customAxios, 'metering-points', meteringPoint);
  }
}

export function deleteMeteringPoint(customAxios: AxiosInstance, meteringPointId: string | number): Promise<void> {
  return deleteOne<MeteringPoint>(customAxios, 'metering-points', meteringPointId);
}

export function getForecast(customAxios: AxiosInstance, meteringPointId: string | number, date: string): Promise<DataPoint> {
  return queryOne<DataPoint>(customAxios, 'metering-points/' + meteringPointId + '/forecast', undefined, 'date=' + encodeURIComponent(date));
}
