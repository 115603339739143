import * as React from 'react';
import { useEffect, useState } from 'react';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { IconButton, Theme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import HomeIcon from '@material-ui/icons/Home';
import { HOME_MAPPING } from '../router/AppRouter';
import makeStyles from '@material-ui/core/styles/makeStyles';
import common from '@material-ui/core/colors/common';
import { useHistory, useLocation } from 'react-router';
import { useDrawer } from '../drawer/DrawerProvider';
import { useAppBarSetting } from './AppBarSettingsProvider';

const useStyles = makeStyles((theme: Theme) => {
  return {
    appBar: {},
    appBarText: {
      color: common.white,
      fontSize: 'large',
      fontWeight: 500,
    },
    appBarIcon: {
      color: common.white,
      marginLeft: -12,
      marginRight: 20,
    },
    appBarHomeIcon: {
      color: common.white,
      marginLeft: 'auto',
    },
  };
});

export function CustomAppBar(props: {}) {
  const classes = useStyles();
  const location = useLocation();
  const [icon, setIcon] = useState<'PRIMARY' | 'BACK'>('PRIMARY');

  useEffect(() => {
    if (location.pathname.length > 1) {
      setIcon('BACK');
    } else {
      setIcon('PRIMARY');
    }
  }, [location]);

  return (
    <AppBar position={'relative'} className={classes.appBar}>
      <StandardContent icon={icon}/>
    </AppBar>
  );
}

function StandardContent(props: {
  icon?: 'PRIMARY' | 'BACK',
}) {
  const classes = useStyles();
  const history = useHistory();
  const {toggleDrawer} = useDrawer();
  const {title} = useAppBarSetting();

  return (
    <Toolbar>
      {(!props.icon || (props.icon === 'PRIMARY')) &&
        <Hidden mdUp={true} implementation={'js'}>
          <IconButton
            id="open_drawer_button"
            className={classes.appBarIcon}
            aria-label="Menu"
            onClick={toggleDrawer}
          >
            <MenuIcon/>
          </IconButton>
        </Hidden>
      }
      {(props.icon && (props.icon === 'BACK')) &&
        <IconButton
          id="back_button"
          className={classes.appBarIcon}
          aria-label="Menu"
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon/>
        </IconButton>
      }
      <Typography className={classes.appBarText}>{title}</Typography>
      <IconButton
        aria-label="Home"
        className={classes.appBarHomeIcon}
        onClick={() => history.push(HOME_MAPPING)}
      >
        <HomeIcon/>
      </IconButton>
    </Toolbar>
  );
}
