import { createOne, deleteOne, queryPage, updateOne } from '../shared/technical/rest/restApi';
import { AxiosInstance } from 'axios';
import { Bill } from './Bill';
import { fullPageable } from '../shared/technical/rest/pageable';

export function getBills(customAxios: AxiosInstance, meteringPointId: string) {
  return queryPage<Bill>(customAxios, `metering-points/${meteringPointId}/bills`, fullPageable());
}

export function createBill(customAxios: AxiosInstance, meteringPointId: string, bill: Bill) {
  return createOne<Bill>(customAxios, `metering-points/${meteringPointId}/bills`, bill);
}

export function updateBill(customAxios: AxiosInstance, meteringPointId: string, billId: string | number, bill: Bill) {
  return updateOne<Bill>(customAxios, `metering-points/${meteringPointId}/bills`, bill);
}

export function deleteBill(customAxios: AxiosInstance, meteringPointId: string, billId: string | number): Promise<void> {
  return deleteOne<Bill>(customAxios, `metering-points/${meteringPointId}/bills`, billId);
}
