import * as React from 'react';
import { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { ReadingsTable } from './ReadingsTable';
import { getMeteringPoint } from '../meters/MeteringPointApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core';
import { Loading } from '../shared/components/Loading';
import { useTranslation } from 'react-i18next';
import { MeteringPoint } from '../meters/MeteringPoint';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

interface Props {
  meteringPointId: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  responsivePaper: {
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    }
  },
}));

export function ReadingsPage(props: Props) {
  const customAxios = useAxios();
  const classes = useStyles();
  const {t} = useTranslation();
  const {setTitle} = useAppBarSetting();

  const [meteringPoint, setMeteringPoint] = useState<MeteringPoint>();

  useEffect(() => {
    getMeteringPoint(customAxios, props.meteringPointId)
      .then((meteringPoint) => {
        setMeteringPoint(meteringPoint);
        setTitle(t('ReadingsPage.title') + ' ' + meteringPoint.name);
      });
  }, [props.meteringPointId, customAxios, setTitle, t]);

  if (!meteringPoint) {
    return (
      <Loading/>
    );
  }
  return (
    <>
      <Paper className={classes.responsivePaper}>
        <ReadingsTable showActions={true} meteringPoint={meteringPoint}/>
      </Paper>
    </>
  );
}
