import * as React from 'react';
import { Bill } from './Bill';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { muiDataTablesLabels } from '../shared/components/tables/muiDataTableUtils';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { PagedResult } from '../shared/technical/rest/pageable';
import { formatStringDate } from '../shared/technical/dateTime/DateTime';
import { formatCurrency } from '../units/Unit';

export function BillsTable(props: {
  meteringPointId: string,
  bills: PagedResult<Bill>,
  onClick: (bill: Bill) => void,
  onDelete: (billId: number) => void,
}) {
  const {t} = useTranslation();

  function deleteIcon(id: number) {
    return <IconButton onClick={(event) => {
      event.preventDefault();
      if (!window.confirm(t('actions.reallyDelete', {name: t('Bill.title') + ' ' + id}))) {
        return;
      }
      props.onDelete(id);
    }}>
      <DeleteIcon/>
    </IconButton>;
  }

  const columns: MUIDataTableColumnDef[] = [
    {label: t('Bill.id'), name: 'id'},
    {
      label: t('Bill.date'),
      name: 'date',
      options: {customBodyRender: (date) => formatStringDate(date)}
    },
    {label: t('Bill.totalAmount'), name: 'totalAmount', options: {customBodyRender: (totalAmount) => formatCurrency(totalAmount)}},
    {
      label: t('actions.delete'),
      name: 'id',
      options: {customBodyRender: deleteIcon, filter: false, sort: false, print: false}
    },
  ];

  function handleCellClick(colData: any, cellMeta: { colIndex: number; rowIndex: number; dataIndex: number; event: React.MouseEvent }) {
    console.debug('Cell clicked: ' + cellMeta.colIndex + ' of row ' + cellMeta.rowIndex);
    if (cellMeta.colIndex > 1) {
      // Not clicked in ID, NAME
      return;
    }
    props.onClick(props.bills.content[cellMeta.rowIndex]);
  }

  return (<>
    <MUIDataTable
      title={t('BillsPage.title')}
      columns={columns}
      data={props.bills.content}
      options={{
        download: false,
        selectableRows: 'none',
        print: false,
        responsive: 'standard',
        textLabels: muiDataTablesLabels(t),
        onCellClick: handleCellClick
      }}
    />
  </>);
}
