import { ConcreteValueReading, DeltaValueReading, Reading, ReadingShort } from './Reading';
import { createOne, deleteOne, queryOne, queryPage, updateOne } from '../shared/technical/rest/restApi';
import { Pageable, PagedResult } from '../shared/technical/rest/pageable';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';
import { MeterReadingType } from '../meters/Meter';
import { AxiosInstance } from 'axios';
import { MeteringPoint } from '../meters/MeteringPoint';
import { getMeter } from '../meters/MeterApi';

export function getReadingsOfMeter(customAxios: AxiosInstance, pageable: Pageable, meteringPoint: MeteringPoint): Promise<PagedResult<ConcreteValueReading> | PagedResult<DeltaValueReading>> {
  if (meteringPoint.activeMeter.meterReadingType === MeterReadingType.COUNTER_VALUES ||
    meteringPoint.activeMeter.meterReadingType === MeterReadingType.CONCRETE_VALUES ||
    meteringPoint.activeMeter.meterReadingType === MeterReadingType.CALCULATED_VALUES) {
    return queryPage<ConcreteValueReading>(customAxios, 'metering-points/' + meteringPoint.id + '/readings', pageable);
  } else if (meteringPoint.activeMeter.meterReadingType === MeterReadingType.DELTA_VALUES) {
    return queryPage<DeltaValueReading>(customAxios, 'metering-points/' + meteringPoint.id + '/delta-readings', pageable);
  }
  throw new TypeError('Unknown reading type');
}

export function getReading(customAxios: AxiosInstance, meteringPointId: string | number, readingId: string): Promise<ConcreteValueReading> {
  return queryOne<ConcreteValueReading>(customAxios, 'metering-points/' + meteringPointId + '/readings', readingId);
}

export function getDeltaReading(customAxios: AxiosInstance, meteringPointId: string | number, readingId: string): Promise<DeltaValueReading> {
  return queryOne<DeltaValueReading>(customAxios, 'metering-points/' + meteringPointId + '/delta-readings', readingId);
}

export function saveReading(customAxios: AxiosInstance, reading: Reading): Promise<Reading | ValidationErrorResponse> {
  if (reading.meteringPoint.activeMeter.meterReadingType === MeterReadingType.COUNTER_VALUES ||
    reading.meteringPoint.activeMeter.meterReadingType === MeterReadingType.CONCRETE_VALUES) {
    return saveConcreteReading(customAxios, reading as ConcreteValueReading);
  } else if (reading.meteringPoint.activeMeter.meterReadingType === MeterReadingType.DELTA_VALUES) {
    return saveDeltaReading(customAxios, reading as DeltaValueReading);
  }
  throw new TypeError('Unknown reading type');
}

function saveConcreteReading(customAxios: AxiosInstance, reading: ConcreteValueReading): Promise<ConcreteValueReading | ValidationErrorResponse> {
  if (reading.id) {
    return updateOne<ConcreteValueReading>(customAxios, 'metering-points/' + reading.meteringPoint.id + '/readings', reading);
  } else {
    return createOne<ConcreteValueReading>(customAxios, 'metering-points/' + reading.meteringPoint.id + '/readings', reading);
  }
}

function saveDeltaReading(customAxios: AxiosInstance, reading: DeltaValueReading): Promise<DeltaValueReading | ValidationErrorResponse> {
  if (reading.id) {
    return updateOne<DeltaValueReading>(customAxios, 'metering-points/' + reading.meteringPoint.id + '/delta-readings', reading);
  } else {
    return createOne<DeltaValueReading>(customAxios, 'metering-points/' + reading.meteringPoint.id + '/delta-readings', reading);
  }
}

export function deleteReading(customAxios: AxiosInstance, reading: ReadingShort): Promise<any> {
  return getMeter(customAxios, reading.meter.id).then((meter) => {
    if (meter.meterReadingType === MeterReadingType.COUNTER_VALUES) {
      return deleteConcreteReading(customAxios, reading);
    } else if (meter.meterReadingType === MeterReadingType.DELTA_VALUES) {
      return deleteDeltaReading(customAxios, reading);
    }
    throw new TypeError('Unknown reading type');
  });
}

export function deleteDeltaReading(customAxios: AxiosInstance, reading: ReadingShort): Promise<void> {
  return deleteOne<DeltaValueReading>(customAxios, 'metering-points/' + reading.meteringPoint.id + '/delta-readings', reading.id);
}

export function deleteConcreteReading(customAxios: AxiosInstance, reading: ReadingShort): Promise<void> {
  return deleteOne<ConcreteValueReading>(customAxios, 'metering-points/' + reading.meteringPoint.id + '/readings', reading.id);
}
