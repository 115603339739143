import { queryOne } from '../shared/technical/rest/restApi';
import { PvData } from './PvData';
import { AxiosInstance } from 'axios';

export function getPvData(customAxios: AxiosInstance, year: string | number) {
  return queryOne<PvData>(customAxios, 'pv', undefined,  'year=' + year);
}

export function getYearsWithPv(customAxios: AxiosInstance) {
  return queryOne<number[]>(customAxios, 'pv/years');
}
