import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { colors, createStyles, List, ListItem, ListItemIcon, ListItemText, Theme } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import LinkIcon from '@material-ui/icons/Link';
import HomeIcon from '@material-ui/icons/Home';
import CloseIcon from '@material-ui/icons/Close';
import common from '@material-ui/core/colors/common';
import { Roles } from '../../../user/user';
import {
  ACCOUNT_MAPPING,
  CATEGORIES_MAPPING,
  HEAT_PUMP_MAPPING,
  HOME_MAPPING,
  PV_MAPPING,
  TARIFF_PROVIDER_MAPPING,
  UNITS_MAPPING
} from '../router/AppRouter';
import { useHistory } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { METER_TYPE_HP_POWER, METER_TYPE_PV_GENERATED } from '../../../meterTypes/MeterType';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import EcoIcon from '@material-ui/icons/Eco';
import StoreIcon from '@material-ui/icons/Store';
import { useAccount } from '../../../user/AccountProvider';
import { getMeteringPoints } from '../../../meters/MeteringPointApi';
import { useAxios } from '../../technical/rest/AxiosProvider';
import { EnergyCategory } from '../../../categories/EnergyCategory';
import { getEnergyCategories } from '../../../categories/CategoryApi';
import { useTranslation } from 'react-i18next';
import { useUserManager } from '../../technical/security/UserManagerProvider';
import { useDrawer } from './DrawerProvider';
import { MeteringPoint } from '../../../meters/MeteringPoint';

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawerHeader: {
    background: theme.palette.primary.dark,
    padding: theme.spacing(),
  },
}));

export function DrawerContent() {
  const account = useAccount();
  const history = useHistory();
  const customAxios = useAxios();
  const {t} = useTranslation();
  const classes = useStyles();
  const {signOut} = useUserManager();
  const {toggleDrawer} = useDrawer();
  const [meteringPoints, setMeteringPoints] = useState<MeteringPoint[]>([]);
  const [categories, setCategories] = useState<EnergyCategory[]>();

  const [hasPV, setHasPV] = useState(false);
  const [hasHP, setHasHP] = useState(false);

  useEffect(() => {
    getEnergyCategories(customAxios).then(setCategories);
  }, [customAxios]);

  useEffect(() => {
    getMeteringPoints(customAxios).then(setMeteringPoints);
  }, [customAxios]);

  useEffect(() => {
    setHasPV(meteringPoints.find(value => value.type.name === METER_TYPE_PV_GENERATED) !== undefined);
    setHasHP(meteringPoints.find(value => value.type.name === METER_TYPE_HP_POWER) !== undefined);
  }, [meteringPoints]);

  return (
    <div>
      <div className={classes.drawerHeader}>
        <Typography>&nbsp;</Typography>
        <Typography variant="subtitle1" style={{color: common.white}} noWrap={true} align={'center'}>
          {account ? account.firstName + ' ' + account.lastName : ''}
        </Typography>
        <Typography variant="subtitle1" style={{color: common.white}} noWrap={true} align={'center'}>
          {account ? account.email : ''}
        </Typography>
        {account && account.roles.some(role => role === Roles.ADMIN) &&
        <Typography variant="subtitle1" style={{color: common.white}} noWrap={true} align={'center'}>
          {account.roles.join(', ')}
        </Typography>
        }
        <Typography>&nbsp;</Typography>
      </div>
      <Divider/>
      <div>
        <List onClick={toggleDrawer}>
          <ListItem
            id="drawer_menu_overview"
            button={true}
          >
            <ListItemIcon>
              <HomeIcon/>
            </ListItemIcon>
            <ListItemText
              primary={t('drawer.overview')}
              onClick={() => history.push(HOME_MAPPING)}
            />
          </ListItem>
          <Divider/>
          {categories && categories.map((category) => {
            return (
              <MenuEntry
                key={category.name}
                id={category.name}
                name={category.displayName}
                targetLocation={`${CATEGORIES_MAPPING}/${category.id}`}
                icon={<img alt={category.displayName} src={'/' + category.name.toLowerCase() + '.svg'} width={24} height={24}
                           style={{stroke: 'blue'}}/>}
              />
            );
          })}
          {hasPV &&
          <>
            <Divider/>
            <ListItem
              id="drawer_menu_pv"
              button={true}
            >
              <ListItemIcon>
                <WbSunnyIcon/>
              </ListItemIcon>
              <ListItemText
                primary={t('drawer.pv')}
                onClick={() => history.push(PV_MAPPING)}
              />
            </ListItem>
          </>
          }
          {hasHP &&
          <>
            <Divider/>
            <ListItem
              id="drawer_menu_hp"
              button={true}
            >
              <ListItemIcon>
                <EcoIcon/>
              </ListItemIcon>
              <ListItemText
                primary={t('drawer.hp')}
                onClick={() => history.push(HEAT_PUMP_MAPPING)}
              />
            </ListItem>
          </>
          }
          <Divider/>
          <ListItem
            id="drawer_menu_tariff_providers"
            button={true}
          >
            <ListItemIcon>
              <StoreIcon/>
            </ListItemIcon>
            <ListItemText
              primary={t('TariffProvider.title')}
              onClick={() => history.push(TARIFF_PROVIDER_MAPPING)}
            />
          </ListItem>
          {account && account.roles.some(role => role === Roles.ADMIN) &&
          <>
            <ListItem
              id="drawer_menu_units"
              button={true}
            >
              <ListItemIcon>
                <PeopleIcon/>
              </ListItemIcon>
              <ListItemText
                primary={t('drawer.units')}
                onClick={() => history.push(UNITS_MAPPING)}
              />
            </ListItem>
          </>
          }
          <ListItem button={true}>
            <ListItemIcon>
              <PersonIcon/>
            </ListItemIcon>
            <ListItemText primary={t('drawer.account')} onClick={() => history.push(ACCOUNT_MAPPING)}/>
          </ListItem>
          <Divider/>
          <ListItem button={true}>
            <ListItemIcon>
              <LinkIcon/>
            </ListItemIcon>
            <ListItemText
              primary={t('drawer.imprint')}
              onClick={() => window.open('https://www.meinemessungen.de/impressum/')}
            />
          </ListItem>
          <Divider/>
          <ListItem button={true}>
            <ListItemIcon>
              <LinkIcon/>
            </ListItemIcon>
            <ListItemText
              primary={t('drawer.privacyStatement')}
              onClick={() => window.open('https://www.meinemessungen.de/datenschutzerklaerung/')}
            />
          </ListItem>
          <Divider/>
          <ListItem button={true}>
            <ListItemIcon>
              <CloseIcon/>
            </ListItemIcon>
            <ListItemText primary={t('drawer.logout')} onClick={() => signOut && signOut()}/>
          </ListItem>
        </List>
      </div>
      <Divider/>
      <div>
        <Typography>&nbsp;</Typography>
        <Typography variant="subtitle1" style={{color: colors.grey['500']}} noWrap={true} align={'center'}>
          {'Version ' + process.env.REACT_APP_GIT_TAG}
        </Typography>
        <Typography>&nbsp;</Typography>
      </div>
    </div>
  );
}

function MenuEntry(props: {
  id: string,  // Used for element id
  name: string,  // Used for menu item name
  targetLocation: string,  // Used for history push
  icon: ReactNode
}) {
  const history = useHistory();

  return (
    <ListItem
      id={props.id}
      button={true}
    >
      <ListItemIcon>
        {props.icon}
      </ListItemIcon>
      <ListItemText
        primary={props.name}
        onClick={() => history.push(props.targetLocation)}
      />
    </ListItem>
  );
}
