import * as React from 'react';
import { useEffect, useState } from 'react';
import { Tariff } from './TariffProvider';
import { useTranslation } from 'react-i18next';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { muiDataTablesLabels } from '../shared/components/tables/muiDataTableUtils';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { tariffEditMapping } from '../shared/components/router/AppRouter';
import { useHistory } from 'react-router';
import { formatDate, parseIsoFormat } from '../shared/technical/dateTime/DateTime';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { deleteTariff, getTariffsByTariffProvider } from './tariffProviderApi';

export function TariffTable(props: {
  tariffProviderId: string
}) {
  const {t} = useTranslation();
  const history = useHistory();
  const customAxios = useAxios();
  const {tariffProviderId} = {...props};

  const [tariffs, setTariffs] = useState<Tariff[]>([]);

  useEffect(() => {
    getTariffsByTariffProvider(customAxios, tariffProviderId).then(setTariffs);
  }, [tariffProviderId, customAxios]);

  function deleteIcon(id: number) {
    return <IconButton onClick={() => {
      if (!window.confirm(`Soll der Tarif wirklich gelöscht werden?`)) {
        return;
      }
      deleteTariff(customAxios, id).then(() => setTariffs(curr => curr.filter(currProvider => currProvider.id !== id)));
    }}><DeleteIcon/></IconButton>;
  }

  function handleCellClick(colData: any, cellMeta: { colIndex: number; rowIndex: number; dataIndex: number; event: React.MouseEvent }) {
    if(!tariffs || tariffs.length === 0) {
      // No tariffs loaded
      return;
    }
    if (cellMeta.colIndex > 1) {
      // Not clicked in ID, NAME
      return;
    }
    const tariff = tariffs[cellMeta.dataIndex];
    const path = tariffEditMapping('' + tariffProviderId, '' + tariff.id);
    history.push(path);
  }

  const columns: MUIDataTableColumnDef[] = [
    {label: t('Tariff.id'), name: 'id'},
    {label: t('Tariff.name'), name: 'name'},
    {
      label: t('ValidityInfo.validFrom'),
      name: 'validityInfo',
      options: {customBodyRender: (validityInfo) => formatDate(validityInfo.validFrom && parseIsoFormat(validityInfo.validFrom))}
    },
    {
      label: t('ValidityInfo.validTo'),
      name: 'validityInfo',
      options: {customBodyRender: (validityInfo) => formatDate(validityInfo.validTo && parseIsoFormat(validityInfo.validTo))}
    },
    {
      label: t('actions.delete'),
      name: 'id',
      options: {customBodyRender: deleteIcon}
    },
  ];
  return (<>
    <MUIDataTable
      title={t('Tariff.title')}
      columns={columns}
      data={tariffs}
      options={{
        download: false,
        selectableRows: 'none',
        print: false,
        textLabels: muiDataTablesLabels(t),
        onCellClick: handleCellClick
      }}
    />
  </>);
}
