import { ChartParams } from './ChartParamParser';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { DateTimeFormInput } from '../shared/components/forms/DateTimeFormInput';
import { readingChartMapping } from '../shared/components/router/AppRouter';
import { PlainUnit } from '../units/Unit';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { DropDownFormInput } from '../shared/components/forms/DropDownFormInput';
import { isTimeUnitWithTime, TimeUnit, timeUnits, timeUnitToDateTimeFormat } from '../shared/technical/dateTime/TimeUnit';
import { createChartUri, updateChartPageUri } from './ReadingChartPage';
import { timeUnitToDateTimeViews, timeUnitToDateViews } from '../shared/components/forms/CustomDateTimePicker';
import { DateFormInput } from '../shared/components/forms/DateFormInput';
import { MeteringPoint } from '../meters/MeteringPoint';

function smallTimeUnitSelectedForElectricity(newParams: ChartParams, mainMeteringPoint: MeteringPoint | undefined) {
  const noExplicitUnitSelected = !newParams.unit;
  const meteringPointHasAlternativeUnit = mainMeteringPoint && mainMeteringPoint.alternativeUnit;
  const smallTimeUnitSelected = newParams.resolution && (newParams.resolution === TimeUnit.MINUTE || newParams.resolution === TimeUnit.SECOND);
  return noExplicitUnitSelected && meteringPointHasAlternativeUnit && smallTimeUnitSelected;
}

export function ChartTimeControls(props: {
  params: ChartParams,
  meteringPoint: MeteringPoint
}) {
  const history = useHistory();
  const {t} = useTranslation();

  const chartUri = readingChartMapping(props.meteringPoint.type.category.id.toString(), props.meteringPoint.id) + '?';
  const alternativeUnit = props.meteringPoint.alternativeUnit;
  const resolution = props.params.resolution || TimeUnit.SECOND;

  const format = useMemo<string | undefined>(() => {
    return resolution && timeUnitToDateTimeFormat(resolution);
  }, [resolution]);

  useEffect(() => {
    if (smallTimeUnitSelectedForElectricity(props.params, props.meteringPoint)) {
      history.replace(createChartUri({...props.params, unit: props.meteringPoint.alternativeUnit}, chartUri))
    }
  }, [props.params, props.meteringPoint, history, chartUri])

  return <Paper elevation={0} style={{marginBottom: 10, marginTop: 10, padding: 16}}>
    <Grid container={true} alignItems={'center'} spacing={2}>
      <Grid item={true} xs={alternativeUnit ? 6 : 12} lg={alternativeUnit ? 3 : 6}>
        <DropDownFormInput
          label={t('ReadingChart.resolution')}
          field={'resolution'}
          list={timeUnits}
          getListLabel={value => t('TimeUnit.' + value)}
          getListKey={value => value}
          value={props.params.resolution}
          setValue={(value) => updateChartPageUri(history, {...props.params, resolution: TimeUnit[value]}, chartUri)}
        />
      </Grid>
      <Grid item={true} xs={6} md={6} lg={3}>
        {isTimeUnitWithTime(resolution) &&
        <DateTimeFormInput
          label={t('DateRange.firstDate')}
          field={'firstDate'}
          setValue={(value) => updateChartPageUri(history, {...props.params, from: value}, chartUri)}
          value={props.params.from}
          views={timeUnitToDateTimeViews(resolution)}
          format={format}
        />}
        {!isTimeUnitWithTime(resolution) &&
        <DateFormInput
          label={t('DateRange.firstDate')}
          field={'firstDate'}
          setValue={(value) => updateChartPageUri(history, {...props.params, from: value}, chartUri)}
          value={props.params.from}
          views={timeUnitToDateViews(resolution)}
          format={format}
        />}
      </Grid>
      <Grid item={true} xs={6} md={6} lg={3}>
        {isTimeUnitWithTime(resolution) &&
        <DateTimeFormInput
          label={t('DateRange.lastDate')}
          field={'lastDate'}
          setValue={(value) => updateChartPageUri(history, {...props.params, to: value}, chartUri)}
          value={props.params.to}
          views={timeUnitToDateTimeViews(resolution)}
          format={format}
        />}
        {!isTimeUnitWithTime(resolution) &&
        <DateFormInput
          label={t('DateRange.lastDate')}
          field={'lastDate'}
          setValue={(value) => updateChartPageUri(history, {...props.params, to: value}, chartUri)}
          value={props.params.to}
          views={timeUnitToDateViews(resolution)}
          format={format}
        />}
      </Grid>
      {alternativeUnit &&
      <Grid item={true} xs={6} lg={3}>
        <DropDownFormInput
          label={t('ReadingChart.units')}
          field={'units'}
          list={[props.meteringPoint!!.unit as PlainUnit, alternativeUnit as PlainUnit]}
          getListLabel={value => t('Unit.' + value.code)}
          getListKey={value => value.code}
          value={props.params.unit}
          setValue={(value) => updateChartPageUri(history, {...props.params, unit: value}, chartUri)}
        />
      </Grid>
      }
    </Grid>
  </Paper>;
}
