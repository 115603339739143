import * as React from 'react';
import { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { PvData } from './PvData';
import { Typography } from '@material-ui/core';
import { formatPercentage, formatValue } from '../units/magnitudeUtils';
import { DateTime } from 'luxon';
import { DropDownFormInput } from '../shared/components/forms/DropDownFormInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { amber } from '@material-ui/core/colors';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { getPvData, getYearsWithPv } from './PvDataApi';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

const useStyle = makeStyles(theme => ({
  warning: {
    backgroundColor: amber[700],
  }
}));

export function PvPage(props: {}) {
  const {t} = useTranslation();
  const classes = useStyle();
  const [pvData, setPvData] = useState<PvData>();
  const [year, setYear] = useState(DateTime.local().year);
  const [years, setYears] = useState<number[]>([]);
  const [error, setError] = useState<string>();
  const customAxios = useAxios();
  const {} = useAppBarSetting(t('PvPage.title'));

  useEffect(() => {
    getPvData(customAxios, year).then(setPvData).catch((reason) => {
      setError(t('PvPage.errorOnLoad'));
    });
  }, [year, t, customAxios]);

  useEffect(() => {
    getYearsWithPv(customAxios).then(setYears).catch((reason) => {
      setError(t('PvPage.errorOnLoad'));
    });
  }, [t, customAxios]);

  return (
    <>
      <Paper elevation={0} style={{marginBottom: 10, marginTop: 10, padding: 16}}>
        <div className={classes.warning}>{error}</div>
        <DropDownFormInput
          label={t('PvPage.year')}
          field={'year'}
          list={years}
          getListLabel={year => '' + year}
          getListKey={year => '' + year}
          getValue={() => year}
          setValue={setYear}/>
        <Typography variant={'body1'}>{t('PvPage.totalGenerated')}{pvData && formatValue(pvData.totalGenerated, 'Wh')}</Typography>
        <Typography variant={'body1'}>{t('PvPage.totalOwnUse')}{pvData && formatValue(pvData.totalOwnUse, 'Wh')}</Typography>
        <Typography variant={'body1'}>{t('PvPage.pvOwnUse')}{pvData && formatValue(pvData.pvOwnUse, 'Wh')}</Typography>
        <Typography
          variant={'body1'}>{t('PvPage.pvOwnUsePerTotalUse')}{pvData && formatPercentage(pvData.pvOwnUse / pvData.totalOwnUse)}</Typography>
        <Typography
          variant={'body1'}>{t('PvPage.pvOwnUsePerTotalGenerated')}{pvData && formatPercentage(pvData.pvOwnUse / pvData.totalGenerated)}</Typography>
      </Paper>
    </>
  );
}
