import * as React from 'react';
import { useEffect, useState } from 'react';
import { MeteringPointForm } from './MeteringPointForm';
import { emptyPage, fullPageable, PagedResult as RestPage } from '../shared/technical/rest/pageable';
import { useHistory } from 'react-router';
import { categoryMapping } from '../shared/components/router/AppRouter';
import { ValidationErrorResponse } from '../shared/technical/rest/validation';
import { log } from '../logConfig';
import { MeterType } from '../meterTypes/MeterType';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import { saveMeteringPoint } from './MeteringPointApi';
import { getMeterTypesForCategory } from '../meterTypes/MeterTypeApi';
import { Loading } from '../shared/components/Loading';
import { MeteringPoint, newMeteringPoint } from './MeteringPoint';
import { useTranslation } from 'react-i18next';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

export function NewMeteringPointPage(props: {
  categoryId: string;
}) {
  const history = useHistory();
  const {t} = useTranslation();
  const customAxios = useAxios();
  const {setTitle} = useAppBarSetting(t('MeteringPoint.title'));

  const [meteringPoint,] = useState<MeteringPoint>(newMeteringPoint());
  const [meterTypes, setMeterTypes] = useState<RestPage<MeterType>>(emptyPage());

  function saveButtonPressed(meteringPoint: MeteringPoint) {
    if (meteringPoint.activeTariffSubscription) {
      if (!meteringPoint.activeTariffSubscription.tariff || !meteringPoint.activeTariffSubscription.tariff.id) {
        meteringPoint.activeTariffSubscription = undefined;
      }
    }
    return saveMeteringPoint(customAxios, meteringPoint).then(
      (response) => {
        if ((response as ValidationErrorResponse).errors) {
          return (response as ValidationErrorResponse).errors;
        } else {
          history.push(categoryMapping(props.categoryId.toString()));
          return undefined;
        }
      },
      (reason) => {
        log.error(reason, null);
        return undefined;
      });
  }

  useEffect(() => {
    getMeterTypesForCategory(customAxios, fullPageable(), props.categoryId.toString()).then(setMeterTypes);
  }, [props.categoryId, customAxios]);

  if (meterTypes.totalElements === 0) {
    return (
      <Loading/>
    );
  }

  return (
    <>
      <MeteringPointForm
        meteringPoint={meteringPoint}
        meterTypes={meterTypes}
        onSave={saveButtonPressed}
      />
    </>
  );
}
