import * as React from 'react';
import { useState } from 'react';
import { useAccount } from './AccountProvider';
import { Loading } from '../shared/components/Loading';
import { ButtonBar } from '../shared/components/forms/Forms';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { markAccountForDeletion } from './AccountApi';
import { useAxios } from '../shared/technical/rest/AxiosProvider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { useAppBarSetting } from '../shared/components/appbar/AppBarSettingsProvider';

const useStyles = makeStyles((theme: Theme) => createStyles({
  deleteAccountButton: {
    background: 'red',
    marginRight: 'auto'
  },
  deleteSection: {
    margin: theme.spacing(3)
  }
}));

export function AccountPage() {
  const account = useAccount();
  const axios = useAxios();
  const {t} = useTranslation();
  const classes = useStyles();
  const {} = useAppBarSetting(t('AccountPage.title'));

  const [deletionTriggered, setDeletionTriggered] = useState(false);

  if (!account) {
    return (
      <Loading/>
    );
  } else {
    return (
      <>
        <div>{account.firstName} {account.lastName} ({account.email})</div>
        <div className={classes.deleteSection}>
          <Typography variant={'h5'}>{t('AccountPage.deletionTitle')}</Typography>
          <Typography variant={'body1'}>{t('AccountPage.deletionDescription')}</Typography>
          <ButtonBar>
            <Button
              variant={'contained'}
              startIcon={<DeleteIcon/>}
              disabled={deletionTriggered}
              className={classes.deleteAccountButton} onClick={() => {
              if (!window.confirm(t('actions.reallyDelete', {name: t('Account.title')}))) {
                return;
              }
              markAccountForDeletion(axios).then(() => setDeletionTriggered(true));
            }}>{t('AccountPage.deleteAccount')}</Button>
          </ButtonBar>
        </div>
      </>
    );
  }
}
